import {useEffect} from 'react';
import axios from "axios";

export default function GetCredentials(){
  useEffect(() => {
    // let tokenBCK = `${process.env.NEXT_PUBLIC_DEV_BCK_TOKEN}`;
    let clientID = `${process.env.REACT_APP_CLIENT_ID}`;
    let clientSecret = `${process.env.REACT_APP_CLIENT_SECRET}`;
    let audience = `${process.env.REACT_APP_AUDIENCE}`;
    let grantType = `${process.env.REACT_APP_GRANT_TYPE}`;
    if (localStorage.nekot) {
      //check local storage token value
      let tokenValue = localStorage.getItem('nekot');
      let bearerValue = 'Bearer '+tokenValue;
      let data = JSON.stringify({
        "type": "espresso",
        "tasteNotes": [
          "fruty and citrus",
          "chocolaty and nuts"
        ],
        "preferences": {
          "acidity": 2,
          "sweetness": 3,
          "bitterness": 4,
          "body": 3
        },
        "drinkerType": "Once a day"
      });
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://agro-caffe.vercel.app/api/get-best-match?lang=en',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': bearerValue
        },
        data: data
      };
      axios.request(config)
        .then((response) => {
          // let result = response.data;
          // console.log(result);
        })
        .catch((error) => {
          // console.log(error);
          //set local storage token value
          let data = JSON.stringify({
            "client_id": clientID,
            "client_secret": clientSecret,
            "audience": audience,
            "grant_type": grantType
          });

          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://agrocaffe.us.auth0.com/oauth/token',
            headers: {
              'Content-Type': 'application/json'
            },
            data : data
          };

          axios.request(config)
            .then((response) => {
              // console.log(response);
              if (response.data) {
                if (response.data.access_token) {
                  // console.log(response.data.access_token);
                  localStorage.setItem('nekot', response.data.access_token);
                  window.location.href = window.location.href;
                }
              }
            })
            .catch((error) => {
              // console.log(error);
            });
        });
    } else {
      //set local storage token value
      let data = JSON.stringify({
        "client_id": clientID,
        "client_secret": clientSecret,
        "audience": audience,
        "grant_type": grantType
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://agrocaffe.us.auth0.com/oauth/token',
        headers: {
          'Content-Type': 'application/json'
        },
        data : data
      };

      axios.request(config)
        .then((response) => {
          if (response.data) {
            // console.log(response);
            if (response.data.access_token) {
              localStorage.setItem('nekot', response.data.access_token);
              window.location.href = window.location.href;
            }
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [])
}