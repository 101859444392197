

export const checkAndMakeMixWithTasteNotesIfExist = (profile, blend, allBlends) => {
    let IsContainsTasteNote = blend.tasteNotes.some(element => profile.tasteNotes.includes(element));
    if (!IsContainsTasteNote) {
        let foundMix = allBlends.find(blend => {
            return blend.tasteNotes.some(element => profile.tasteNotes.includes(element))
        });

        if (foundMix) {
            const difference = blend.matchPercentage - foundMix.matchPercentage;
            if (difference < 5) {
                return foundMix;
            } else {
                return blend;
            }
        } else {
            return blend
        }
    } else {
        return blend
    }
}

export const checkAndMakeMixWithoutRobustaIfExist = (blend, allBlends) => {
    if (blend.blendArray.robusta > 0) {
        let foundMix = allBlends.find(mix => {
            return mix.blendArray.robusta === 0;
        });
        //for testing
        console.log(foundMix)
        if (foundMix && blend.matchPercentage - foundMix.matchPercentage > 2) {
            return blend;
        } else if (foundMix) {
            return foundMix
        } else {
            console.log("there is no mix without robusta")
            return blend;
        }
    } else {
        return blend;
    }
}

export const checkAndMakeMixWithAtLeastTwoTypesIfExist = (blend, allBlends) =>{
    let currentMixTypes  = Object.values(blend.blendArray).filter(value => value > 0).length;

    if (currentMixTypes > 1) {
        return blend;
    }

    if (currentMixTypes === 1){
        let foundMix = allBlends.find(mix => {
            let mixTypes = Object.values(mix.blendArray).filter(value => value > 0).length;
            return mixTypes >= 2 && mixTypes <= 3;
        });

        if (foundMix) {
            let diff = blend.matchPercentage - foundMix.matchPercentage;
            if (diff <= 2) {
                return foundMix;
            }
        }
    }

    return blend;
}

export const findBlendWithoutOneRule = (currentBlend, allBlends) => {

    if (!currentBlend.rulesMet || (currentBlend.rulesMet[0] === "Matching percentages over 90%" && !currentBlend.rulesMet[1])) {
        return findNextBlend(currentBlend, allBlends);
    }

    const ruleToExclude = currentBlend.rulesMet[0] === "Matching percentages over 90%" ? currentBlend.rulesMet[1] : currentBlend.rulesMet[0];

    let index = allBlends.indexOf(currentBlend);
    const blendWithoutRule = allBlends.slice(index + 1).find(blend => !blend.rulesMet.includes(ruleToExclude));

    return blendWithoutRule || findNextBlend(currentBlend, allBlends);
};

export const findNextBlend = (currentBlend, allBlends) => {
    const currentIndex = allBlends.indexOf(currentBlend);
    return currentIndex !== -1 && currentIndex + 1 < allBlends.length ? allBlends[currentIndex + 1] : null;
};