import React from 'react';
import CountUp from 'react-countup';
import loaderIcon from '../../images/flower.png';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import AnimatedProgressProvider from './AnimatedProgressProvider';
import { easeLinear } from 'd3-ease';

export default function Loader(props) {
  let loaderText = props.text;
  return(
    <>
      <div className="loader-block">
        <div className="loader-wrapper">
          <div className="loader-icon">
            <div className="loader-icon_wrapper">
              <div className="loader-icon_inner">
                <img src={loaderIcon} alt=""/>
              </div>
              <div className="circle-progress_wrapper">
                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={100}
                  duration={5}
                  easingFunction={easeLinear}
                >
                  {value => {
                    return (
                      <CircularProgressbar
                        value={value}
                        strokeWidth={5}
                        styles={buildStyles({
                          // Colors
                          pathColor: '#00944D',
                          trailColor: 'transparent'
                        })}
                      />
                    );
                  }}
                </AnimatedProgressProvider>
              </div>
            </div>
            <div className="loader-count">
              <CountUp start={0} end={100} duration={8} />%
            </div>
          </div>
          <div className="loader-text">
            {loaderText[0]}<br></br>
            {loaderText[1]}
          </div>
        </div>
      </div>
    </>
  )
}