import React from "react";
// import coffeeImage from "../images/coffee_match.png";
import flavor1 from '../images/taste_notes/flavors/flavor_1.svg';
import flavor2 from '../images/taste_notes/flavors/flavor_2.svg';
import flavor3 from '../images/taste_notes/flavors/flavor_3.svg';

export default function BlendCard(props) {
  // console.log(props.blend);
  let coffeeName = props.blend.cofeeName;
  let coffeeAcidity = props.blend.preferences.acidity;
  let coffeeBitterness = props.blend.preferences.bitterness;
  let coffeeBody = props.blend.preferences.body;
  let coffeeSweetness = props.blend.preferences.sweetness;
  let coffeeImage = props.blend.image;
  let coffeePrice = Object.values(props.blend.weightPrice)[0];
  let coffeePriceFormat = coffeePrice.toFixed(2);
  let cofeeNotes = props.blend.subNotes;
  let translations = props.translations;
  let flavors1 = [
    {icon: flavor1},
    {icon: flavor2},
    {icon: flavor3}
  ];

  function openBuyPopup(){
    document.getElementById('buy-product').classList.add('open')
  }

  return(
    <>
      <div className="blend-item">
        <div className="blend-item_inner">
          <div className="blend-match">95% {translations.blendCard.matchStr}</div>
          <div className="blend-title">{coffeeName}</div>
          <div className="blend-info">
            <div className="blend-image">
              <img src={coffeeImage} alt=""/>
            </div>
            <div className="blend-desc">
              <div className="flavours">
                {
                  cofeeNotes.map((el, index) => {
                    return (
                      <div className="label-flavor_item" key={index}>
                        <div className="icon">
                          <img src={flavors1[0].icon} alt=""/>
                        </div>
                        <div className="title">{el}</div>
                      </div>
                    )
                  })
                }
              </div>
              <div className="flavors-values">
                <div className="flavor-value_item">
                  <div className="value">{coffeeBitterness}/5</div>
                  <div className="label">{translations.secondStep.preferences[2].title}</div>
                </div>
                <div className="flavor-value_item">
                  <div className="value">{coffeeBody}/5</div>
                  <div className="label">{translations.secondStep.preferences[3].title}</div>
                </div>
                <div className="flavor-value_item">
                  <div className="value">{coffeeAcidity}/5</div>
                  <div className="label">{translations.secondStep.preferences[0].title}</div>
                </div>
                <div className="flavor-value_item">
                  <div className="value">{coffeeSweetness}/5</div>
                  <div className="label">{translations.secondStep.preferences[1].title}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="blend-buy">
            <div className="blend-price">
              {translations.blendCard.fromStr} ${coffeePriceFormat}
            </div>
            <div className="buttons">
              <button className="thm-btn small" onClick={openBuyPopup}>{translations.blendCard.buyBtn}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}