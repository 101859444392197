import React, {useEffect, useState} from 'react';
import logo from '../../images/logo_latest.png';
import coffeeType4 from "../../images/coffee_types/coffee_type_4.png";
import coffeeType3 from "../../images/coffee_types/coffee_type_3.png";
import coffeeType2 from "../../images/coffee_types/coffee_type_2.png";
import coffeeType6 from "../../images/coffee_types/coffee_type_6.png";
import coffeeType5 from "../../images/coffee_types/coffee_type_5.png";
import coffeeType1 from "../../images/coffee_types/coffee_type_1.png";
import HE from "../../languages/he";
// import SpiderChart from "../../components/charts/radar";
import leftTopNoteBg from "../../images/taste_notes/left_top.png";
import leftTopNoteBgActive from "../../images/taste_notes/left_top_active.png";
import leftTopNoteIcon from "../../images/taste_notes/left_top_icon.svg";
import leftTopNoteIconActive from "../../images/taste_notes/left_top_icon_active.svg";
import rightTopNoteBg from "../../images/taste_notes/right_top.png";
import rightTopNoteBgActive from "../../images/taste_notes/right_top_active.png";
import rightTopNoteIcon from "../../images/taste_notes/right_top_icon.svg";
import rightTopNoteIconActive from "../../images/taste_notes/right_top_icon_active.svg";
import leftBottomNoteBg from "../../images/taste_notes/left_bottom.png";
import leftBottomNoteBgActive from "../../images/taste_notes/left_bottom_active.png";
import leftBottomNoteIcon from "../../images/taste_notes/left_bottom_icon.svg";
import leftBottomNoteIconActive from "../../images/taste_notes/left_bottom_icon_active.svg";
import rightBottomNoteBg from "../../images/taste_notes/right_bottom.png";
import rightBottomNoteBgActive from "../../images/taste_notes/right_bottom_active.png";
import rightBottomNoteIcon from "../../images/taste_notes/right_bottom_icon.svg";
import rightBottomNoteIconActive from "../../images/taste_notes/right_bottom_icon_active.svg";
import roastedAlmondIcon from "../../images/taste_notes/flavors/agro-icon-brown-16.svg";
import sugarCaneIcon from "../../images/taste_notes/flavors/flavor_2.svg";
import herbsIcon from "../../images/taste_notes/flavors/agro-icon-brown-7.svg";
import cloveIcon from "../../images/taste_notes/flavors/agro-icon-brown-8.svg";
import spicesIcon from "../../images/taste_notes/flavors/flavor_4.svg";
import cinnamonIcon from "../../images/taste_notes/flavors/flavor_5.svg";
import vanillaIcon from "../../images/taste_notes/flavors/agro-icon-brown-25.svg";
import brownSugarIcon from "../../images/taste_notes/flavors/agro-icon-brown-14.svg";
import caramelIcon from "../../images/taste_notes/flavors/agro-icon-brown-14.svg";
import hibiscusIcon from "../../images/taste_notes/flavors/agro-icon-brown-17.svg";
import honeyIcon from "../../images/taste_notes/flavors/agro-icon-brown-1.svg";
import mapleSyrupIcon from "../../images/taste_notes/flavors/agro-icon-brown-3.svg";
import appleIcon from "../../images/taste_notes/flavors/agro-icon-brown-24.svg";
import mangoIcon from "../../images/taste_notes/flavors/agro-icon-brown-26.svg";
import orangeIcon from "../../images/taste_notes/flavors/agro-icon-brown-10.svg";
import brownButterIcon from "../../images/taste_notes/flavors/agro-icon-brown-21.svg";
import peanutsIcon from "../../images/taste_notes/flavors/flavor_6.svg";
import hazelnutIcon from "../../images/taste_notes/flavors/flavor_7.svg";
import chocolateIcon from "../../images/taste_notes/flavors/flavor_9.svg";
import almondIcon from "../../images/taste_notes/flavors/flavor_1.svg";
import milkChocolateIcon from "../../images/taste_notes/flavors/agro-icon-brown-4.svg";
import cocoaIcon from "../../images/taste_notes/flavors/flavor_8.svg";
import whiteChocolateIcon from "../../images/taste_notes/flavors/agro-icon-brown-19.svg";
import jasmineFlowerIcon from '../../images/taste_notes/flavors/agro-icon-brown-17.svg';
import walnutIcon from '../../images/taste_notes/flavors/walnut-icon.svg';
import lemonGrassIcon from '../../images/taste_notes/flavors/lemon-grass-icon-new.svg';
import blueberryIcon from '../../images/taste_notes/flavors/blueberry-icon.svg';
import grapefruitIcon from '../../images/taste_notes/flavors/grapefruit-icon.svg';
import bakedPearIcon from '../../images/taste_notes/flavors/agro-icon-brown-12.svg';
import citrusFruitsIcon from '../../images/taste_notes/flavors/citrus-icon-new.svg';
import hotelIcon from '../../images/taste_notes/flavors/hotel-icon.svg';
import halvahIcon from '../../images/taste_notes/flavors/halvah-icon.svg';
import toffeeIcon from '../../images/taste_notes/flavors/toffee-icon.svg';

import licoriceIcon from '../../images/taste_notes/flavors/licorice-icon.png';
import biscuitIcon from '../../images/taste_notes/flavors/biscuit-icon.png';
import dateHoneyIcon from '../../images/taste_notes/flavors/date_honey_icon.svg';
import tropicalFruitsIcon from '../../images/taste_notes/flavors/tropical-fruits-icon_new.png';

import agroIcon from "../../images/flower.png";
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import axios from "axios";
import SpiderChartPrint from "../../components/charts/radarPrint";
import Preloader from "../../components/preloader";
import getCredentials from "../../scripts/getCredentials";


const StickerComponentPrint = React.forwardRef((props, ref) => {
  getCredentials();

  let coffeeID = props.coffeeID;

  const [currentMatchPercentage, setCurrentMatchPercentage] = useState(85);
  const [percentageClass, setPercentageClass] = useState('percentage-wrapper');
  const [blendName, setBlendName] = useState('השם של הבלנד');

  useEffect(() => {
    if (currentMatchPercentage >= 70 && currentMatchPercentage < 85) {
      setPercentageClass('percentage-wrapper medium');
      // setPercentageColor('#A26128');
    } else if (currentMatchPercentage < 70) {
      setPercentageClass('percentage-wrapper low');
      // setPercentageColor('#f00');
    } else if (currentMatchPercentage >= 85) {
      setPercentageClass('percentage-wrapper');
      // setPercentageColor('#00944d');
    }
  }, [currentMatchPercentage]);

  let coffeeTypesMy = [
    {icon: coffeeType4},
    {icon: coffeeType3},
    {icon: coffeeType2},
    {icon: coffeeType6},
    {icon: coffeeType5},
    {icon: coffeeType1}
  ];

  let flavors0 = [
    {icon: roastedAlmondIcon},
    {icon: sugarCaneIcon},
    {icon: herbsIcon},
    {icon: cloveIcon},
    {icon: spicesIcon},
    {icon: cinnamonIcon},
    {icon: lemonGrassIcon},
    {icon: licoriceIcon},
  ];

  let flavors1 = [
    {icon: herbsIcon},
    {icon: vanillaIcon},
    {icon: brownSugarIcon},
    {icon: hibiscusIcon},
    {icon: caramelIcon},
    {icon: honeyIcon},
    {icon: jasmineFlowerIcon},
    {icon: dateHoneyIcon},
    {icon: mapleSyrupIcon},
    {icon: halvahIcon},
    {icon: toffeeIcon},
    {icon: biscuitIcon},
  ];

  let flavors2 = [
    {icon: appleIcon},
    {icon: mangoIcon},
    {icon: lemonGrassIcon},
    {icon: blueberryIcon},
    {icon: orangeIcon},
    {icon: grapefruitIcon},
    {icon: bakedPearIcon},
    {icon: tropicalFruitsIcon},
    {icon: citrusFruitsIcon},
    {icon: hotelIcon}
  ];

  let flavors3 = [
    {icon: brownButterIcon},
    {icon: peanutsIcon},
    {icon: hazelnutIcon},
    {icon: chocolateIcon},
    {icon: almondIcon},
    {icon: milkChocolateIcon},
    {icon: cocoaIcon},
    {icon: whiteChocolateIcon},
    {icon: walnutIcon},
  ];

  let targetProfileArr = {
    sweetness: 2,
    acidity: 3,
    bitterness: 2,
    body: 3,
    tasteNotes: [2,0],
    drinkingType: 2,
    howOftenDrink: 2
  };
  const [targetProfile, setTargetProfile] = useState(targetProfileArr);

  let blendProfileArr = {
    blendProfile: {
      sweetness: 4,
      acidity: 5,
      bitterness: 1,
      body: 2
    }
  }
  const [blendProfile, setBlendProfile] = useState(blendProfileArr);

  let languageFile = HE;

  const handleTasteNoteChange = () => {
    return false;
  }

  let currentTasteNotesArr = ['sweet and floral'];
  let notesDefaultArr = ['fruity and citrus', 'spices and roasted', 'sweet and floral', 'chocolaty and nuts'];
  const [currentTasteNotes, setCurrentTasteNotes] = useState(currentTasteNotesArr);

  let roastLevelRangeValueDefault = 2;
  const [roastLevelRangeValue, setRoastLevelRangeValue] = useState(roastLevelRangeValueDefault);

  let coffeeBlends = languageFile.editor.coffeeBlends;
  let coffeeBlendsDefault = {
    brazil: 0,
    colombia: 90,
    ethiopia: 0,
    honduras: 0,
    papua: 10,
    robusta: 0
  };
  const [coffeeBlendsValue, setCoffeeBlendsValue] = useState(coffeeBlendsDefault);

  //get blend info
  useEffect(() => {
    document.getElementsByTagName('body')[0].classList.add('preloader');
    let tokenValue = localStorage.getItem('nekot');
    let bearerValue = 'Bearer '+tokenValue;
    let data = '';
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'https://agro-caffe.vercel.app/api/get-blend-data/'+coffeeID,
      headers: {
        'Authorization': bearerValue
      },
      data : data
    };
    axios.request(config)
      .then((response) => {
        let result = response.data;
        // console.log(result);
        if (result.blendName) {
          //name
          setBlendName(result.blendName);
          //percentage
          setCurrentMatchPercentage(result.match);
          //client details
          targetProfileArr = {
            sweetness: result.clientDetails.preferences.sweetness,
            acidity: result.clientDetails.preferences.acidity,
            bitterness: result.clientDetails.preferences.bitterness,
            body: result.clientDetails.preferences.body,
            tasteNotes: result.clientDetails.tasteNotes,
            drinkingType: result.clientDetails.type,
            howOftenDrink: 2
          };
          setTargetProfile(targetProfileArr);
          //blend details
          let blendProfileArr = {
            blendProfile: {
              sweetness: result.blendPreferences.sweetness,
              acidity: result.blendPreferences.acidity,
              bitterness: result.blendPreferences.bitterness,
              body: result.blendPreferences.body
            }
          }
          setBlendProfile(blendProfileArr);
          //blend tastes
          setCurrentTasteNotes(result.blendTasteNotes);
          //roast level
            setRoastLevelRangeValue(result.roastingLevel);
          //blendTypes
          setCoffeeBlendsValue(result.blendTypes);
          setTimeout(() => {
            document.getElementsByTagName('body')[0].classList.remove('preloader');
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [coffeeID]);
  return(
    <>
      <div className="sticker-wrapper sticker-print" ref={ref}>
        <div className="page-content lang-he">
          <div className="page-header">
            <a className="logo" href="/">
              <img src={logo} alt=""/>
            </a>
          </div>
          <div className="steps-wrapper">
            <div className="step-item active">
              <div className="step-item_wrapper editor-wrapper">
                <div className="blend-wrapper">
                  <div className="blend-wrapper_left editor-left">
                    <div className="coffee-type">
                      <div className="coffee-type_icon">
                        <div className="icon">
                          <img src={targetProfile.drinkingType !== "" ? coffeeTypesMy[targetProfile.drinkingType].icon : ''} alt=""/>
                        </div>
                        <div className="name">{targetProfile.drinkingType !== "" ? languageFile.firstStep.coffeeTypes[targetProfile.drinkingType] : ''}</div>
                      </div>
                      <div className="coffee-type_info">
                        <div className="title">My Coffee ID</div>
                        <div className="subtitle">פרופיל הקפה האישי שלך</div>
                      </div>
                    </div>
                    <div className="flavors">
                      <div className="blend-sidebar_title">
                        <div className="title">{languageFile.sixthStep.flavorsTitle}</div>
                        <hr></hr>
                      </div>
                      <div className="flavors-wrapper">
                        <div className="flavors-values block">
                          <div className="flavor-value_item">
                            <div className="value">{targetProfile.acidity}/5</div>
                            <div className="label">{languageFile.secondStep.preferences[0].title}</div>
                          </div>
                          <div className="flavor-value_item">
                            <div className="value">{targetProfile.sweetness}/5</div>
                            <div className="label">{languageFile.secondStep.preferences[1].title}</div>
                          </div>
                          <div className="flavor-value_item">
                            <div className="value">{targetProfile.bitterness}/5</div>
                            <div className="label">{languageFile.secondStep.preferences[2].title}</div>
                          </div>
                          <div className="flavor-value_item">
                            <div className="value">{targetProfile.body}/5</div>
                            <div className="label">{languageFile.secondStep.preferences[3].title}</div>
                          </div>
                        </div>
                        <div className="flavors-chart">
                          <SpiderChartPrint chartData={targetProfile} lang="he"/>
                        </div>
                      </div>
                    </div>
                    <div className="tastes">
                      <div className="blend-sidebar_title">
                        <div className="title">{languageFile.sixthStep.tasteNoteTitle}</div>
                        <hr></hr>
                      </div>
                      <div className="notes-wrapper small">
                        <div className="notes-wrapper_inner">
                          <div className="notes-items">
                            <div className="notes-item">
                              <label className="notes-item_label">
                                <input type="checkbox" name="taste_notes[]" checked={targetProfile.tasteNotes.includes(2)} onChange={() => handleTasteNoteChange(2)} value="spices_roasted"/>
                                <span className="label-value">
                              <span className="label-bg">
                                <img src={leftTopNoteBg} alt=""/>
                                <img src={leftTopNoteBgActive} alt="" className="active"/>
                              </span>
                              <span className="label-icon">
                                <img src={leftTopNoteIcon} alt=""/>
                                <img src={leftTopNoteIconActive} alt="" className="active"/>
                              </span>
                              <span className="label-title">{languageFile.thirdStep.notes[0].title}</span>
                              <span className="label-flavors op-0">
                                {
                                  flavors0.map((el, index) => {
                                    return (
                                      <span className="label-flavor_item" key={index}>
                                        <span className="icon">
                                          <img src={el.icon} alt=""/>
                                        </span>
                                        <span className="title">{languageFile.thirdStep.notes[0].tastes[index]}</span>
                                      </span>
                                    )
                                  })
                                }
                              </span>
                            </span>
                              </label>
                            </div>
                            <div className="notes-item">
                              <label className="notes-item_label">
                                <input type="checkbox" name="taste_notes[]" checked={targetProfile.tasteNotes.includes(3)} onChange={() => handleTasteNoteChange(3)} value="sweet_floral"/>
                                <span className="label-value top-right">
                              <span className="label-bg">
                                <img src={rightTopNoteBg} alt=""/>
                                <img src={rightTopNoteBgActive} alt="" className="active"/>
                              </span>
                              <span className="label-icon">
                                <img src={rightTopNoteIcon} alt=""/>
                                <img src={rightTopNoteIconActive} alt="" className="active"/>
                              </span>
                              <span className="label-title">{languageFile.thirdStep.notes[1].title}</span>
                              <span className="label-flavors op-0">
                                {
                                  flavors1.map((el, index) => {
                                    return (
                                      <span className="label-flavor_item" key={index}>
                                        <span className="icon">
                                          <img src={el.icon} alt=""/>
                                        </span>
                                        <span className="title">{languageFile.thirdStep.notes[1].tastes[index]}</span>
                                      </span>
                                    )
                                  })
                                }
                              </span>
                            </span>
                              </label>
                            </div>
                            <div className="notes-item">
                              <label className="notes-item_label">
                                <input type="checkbox" name="taste_notes[]" checked={targetProfile.tasteNotes.includes(0)} onChange={() => handleTasteNoteChange(0)} value="fruity_citrus"/>
                                <span className="label-value bottom-left">
                              <span className="label-bg">
                                <img src={leftBottomNoteBg} alt=""/>
                                <img src={leftBottomNoteBgActive} alt="" className="active"/>
                              </span>
                              <span className="label-icon">
                                <img src={leftBottomNoteIcon} alt=""/>
                                <img src={leftBottomNoteIconActive} alt="" className="active"/>
                              </span>
                              <span className="label-title">{languageFile.thirdStep.notes[2].title}</span>
                              <span className="label-flavors op-0">
                                {
                                  flavors2.map((el, index) => {
                                    return (
                                      <span className="label-flavor_item" key={index}>
                                        <span className="icon">
                                          <img src={el.icon} alt=""/>
                                        </span>
                                        <span className="title">{languageFile.thirdStep.notes[2].tastes[index]}</span>
                                      </span>
                                    )
                                  })
                                }
                              </span>
                            </span>
                              </label>
                            </div>
                            <div className="notes-item">
                              <label className="notes-item_label">
                                <input type="checkbox" name="taste_notes[]" checked={targetProfile.tasteNotes.includes(1)} onChange={() => handleTasteNoteChange(1)} value="chocolaty_nuts"/>
                                <span className="label-value bottom-right">
                              <span className="label-bg">
                                <img src={rightBottomNoteBg} alt=""/>
                                <img src={rightBottomNoteBgActive} alt="" className="active"/>
                              </span>
                              <span className="label-icon">
                                <img src={rightBottomNoteIcon} alt=""/>
                                <img src={rightBottomNoteIconActive} alt="" className="active"/>
                              </span>
                              <span className="label-title chocolate">{languageFile.thirdStep.notes[3].title}</span>
                              <span className="label-flavors op-0">
                                {
                                  flavors3.map((el, index) => {
                                    return (
                                      <span className="label-flavor_item" key={index}>
                                        <span className="icon">
                                          <img src={el.icon} alt=""/>
                                        </span>
                                        <span className="title">{languageFile.thirdStep.notes[3].tastes[index]}</span>
                                      </span>
                                    )
                                  })
                                }
                              </span>
                            </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="float-flavors">
                          {
                            [0,1,2,3].map((item, index) => {
                              if (targetProfile.tasteNotes.includes(item)) {
                                let iconsArray = flavors2;
                                let iconsTitle = 2;
                                if (1 === item) {
                                  iconsArray = flavors3;
                                  iconsTitle = 3;
                                } else if (2 === item) {
                                  iconsArray = flavors0;
                                  iconsTitle = 0;
                                } else if (3 === item) {
                                  iconsArray = flavors1;
                                  iconsTitle = 1
                                }
                                return(
                                  <div className="label-flavors" key={index}>
                                    <div className="flavours-title">{languageFile.thirdStep.notes[iconsTitle].title}</div>
                                    {
                                      iconsArray.slice(0,5).map((el, index) => {
                                        return (
                                          <span className="label-flavor_item" key={index}>
                                      <span className="icon">
                                        <img src={el.icon} alt=""/>
                                      </span>
                                      <span className="title">{languageFile.thirdStep.notes[iconsTitle].tastes[index]}</span>
                                    </span>
                                        )
                                      })
                                    }
                                  </div>
                                )
                              } else {
                                return null;
                              }
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="blend-wrapper_right">
                    <div className="sticker-title">{blendName}</div>
                    <div className="editor-top">
                      <div className="percentage">
                        <div className="title">התאמה לפרופיל שלך:</div>

                        <div className={percentageClass}>
                          <div className="percentage-progress" style={{width: currentMatchPercentage+'%'}}></div>
                          <strong>{currentMatchPercentage}%</strong>
                        </div>
                      </div>
                    </div>
                    <div className="editor-blocks_wrapper">
                      <div className="editor-blocks">
                        <div className="editor-block_item select-item">
                          <div className="editor-item_inner editable">
                            <div className="editor-title">{languageFile.editor.selectCoffeeBlend}</div>
                            <div className="coffee-items">
                              {
                                Object.keys(coffeeBlendsValue).map((item, index) => {
                                  let percentValue = coffeeBlendsValue[item];
                                  if (percentValue !== 0) {
                                    return(
                                      <div key={index} className="coffee-item">
                                        <div className="label">{coffeeBlends[item]}</div>
                                        <div className="value">{percentValue}%</div>
                                      </div>
                                    )
                                  } else {
                                    return false;
                                  }
                                })
                              }
                            </div>
                          </div>
                        </div>
                        <div className="editor-block_item chart-item">
                          <div className="editor-item_inner editable">
                            <div className="editor-title">{languageFile.editor.tasteProfile}</div>
                            <div className="taste-profile">
                              <SpiderChartPrint chartData={targetProfile} multiple={blendProfile} lang="he"/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="editor-blocks">
                        <div className="editor-block_item roast-item">
                          <div className="editor-item_inner editable">
                            <div className="editor-title">{languageFile.editor.selectRoastLevel}</div>
                            <div className="roasting-level">
                              <div className="slider-wrapper vertical">
                                <RangeSlider
                                  className={"roasting_level level-"+roastLevelRangeValue}
                                  min={1}
                                  max={3}
                                  step={0.1}
                                  thumbsDisabled={[true, false]}
                                  rangeSlideDisabled={false}
                                  orientation="vertical"
                                  value={[1, 1 === roastLevelRangeValue ? 1.2 : roastLevelRangeValue]}
                                  // defaultValue={[1, roastLevelRangeValue]}
                                  onInput={handleTasteNoteChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="editor-block_item notes-item">
                          <div className="editor-item_inner editable">
                            <div className="editor-title">{languageFile.editor.tastesNotes}</div>
                            <div className="notes-wrapper small active">
                              <div className="notes-wrapper_inner">
                                <div className="notes-items">
                                  <div className="notes-item">
                                    <label className="notes-item_label">
                                      <input type="checkbox" name="taste_notes[]" checked={currentTasteNotes ? (currentTasteNotes['spices and roasted'] ? true : false) : false} onChange={() => handleTasteNoteChange(2)} value="spices_roasted"/>
                                      <span className="label-value">
                                      <span className="label-bg">
                                        <img src={leftTopNoteBg} alt=""/>
                                        <img src={leftTopNoteBgActive} alt="" className="active"/>
                                      </span>
                                      <span className="label-icon">
                                        <img src={leftTopNoteIcon} alt=""/>
                                        <img src={leftTopNoteIconActive} alt="" className="active"/>
                                      </span>
                                      <span className="label-title">{languageFile.thirdStep.notes[0].title}</span>
                                      <span className="label-flavors op-0">
                                        {
                                          flavors0.map((el, index) => {
                                            return (
                                              <span className="label-flavor_item" key={index}>
                                                <span className="icon">
                                                  <img src={el.icon} alt=""/>
                                                </span>
                                                <span className="title">{languageFile.thirdStep.notes[0].tastes[index]}</span>
                                              </span>
                                            )
                                          })
                                        }
                                      </span>
                                    </span>
                                    </label>
                                  </div>
                                  <div className="notes-item">
                                    <label className="notes-item_label">
                                      <input type="checkbox" name="taste_notes[]" checked={currentTasteNotes ? (currentTasteNotes['sweet and floral'] ? true : false) : false} onChange={() => handleTasteNoteChange(3)} value="sweet_floral"/>
                                      <span className="label-value top-right">
                                      <span className="label-bg">
                                        <img src={rightTopNoteBg} alt=""/>
                                        <img src={rightTopNoteBgActive} alt="" className="active"/>
                                      </span>
                                      <span className="label-icon">
                                        <img src={rightTopNoteIcon} alt=""/>
                                        <img src={rightTopNoteIconActive} alt="" className="active"/>
                                      </span>
                                      <span className="label-title">{languageFile.thirdStep.notes[1].title}</span>
                                      <span className="label-flavors op-0">
                                        {
                                          flavors1.map((el, index) => {
                                            return (
                                              <span className="label-flavor_item" key={index}>
                                                <span className="icon">
                                                  <img src={el.icon} alt=""/>
                                                </span>
                                                <span className="title">{languageFile.thirdStep.notes[1].tastes[index]}</span>
                                              </span>
                                            )
                                          })
                                        }
                                      </span>
                                    </span>
                                    </label>
                                  </div>
                                  <div className="notes-item">
                                    <label className="notes-item_label">
                                      <input type="checkbox" name="taste_notes[]" checked={currentTasteNotes ? (currentTasteNotes['fruity and citrus'] ? true : false) : false} onChange={() => handleTasteNoteChange(0)} value="fruity_citrus"/>
                                      <span className="label-value bottom-left">
                                      <span className="label-bg">
                                        <img src={leftBottomNoteBg} alt=""/>
                                        <img src={leftBottomNoteBgActive} alt="" className="active"/>
                                      </span>
                                      <span className="label-icon">
                                        <img src={leftBottomNoteIcon} alt=""/>
                                        <img src={leftBottomNoteIconActive} alt="" className="active"/>
                                      </span>
                                      <span className="label-title">{languageFile.thirdStep.notes[2].title}</span>
                                      <span className="label-flavors op-0">
                                        {
                                          flavors2.map((el, index) => {
                                            return (
                                              <span className="label-flavor_item" key={index}>
                                                <span className="icon">
                                                  <img src={el.icon} alt=""/>
                                                </span>
                                                <span className="title">{languageFile.thirdStep.notes[2].tastes[index]}</span>
                                              </span>
                                            )
                                          })
                                        }
                                      </span>
                                    </span>
                                    </label>
                                  </div>
                                  <div className="notes-item">
                                    <label className="notes-item_label">
                                      <input type="checkbox" name="taste_notes[]" checked={currentTasteNotes ? (currentTasteNotes['chocolaty and nuts'] ? true : false) : false} onChange={() => handleTasteNoteChange(1)} value="chocolaty_nuts"/>
                                      <span className="label-value bottom-right">
                                      <span className="label-bg">
                                        <img src={rightBottomNoteBg} alt=""/>
                                        <img src={rightBottomNoteBgActive} alt="" className="active"/>
                                      </span>
                                      <span className="label-icon">
                                        <img src={rightBottomNoteIcon} alt=""/>
                                        <img src={rightBottomNoteIconActive} alt="" className="active"/>
                                      </span>
                                      <span className="label-title chocolate">{languageFile.thirdStep.notes[3].title}</span>
                                      <span className="label-flavors op-0">
                                        {
                                          flavors3.map((el, index) => {
                                            return (
                                              <span className="label-flavor_item" key={index}>
                                                <span className="icon">
                                                  <img src={el.icon} alt=""/>
                                                </span>
                                                <span className="title">{languageFile.thirdStep.notes[3].tastes[index]}</span>
                                              </span>
                                            )
                                          })
                                        }
                                      </span>
                                    </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="float-flavors dynamic">
                                {
                                  notesDefaultArr.map((item, index) => {
                                    let itemIndex = 0;
                                    let familyIcons = flavors0;
                                    let labelClass = "label-flavors "+item.replaceAll(' ', '_');
                                    if (!Object.keys(currentTasteNotes ? currentTasteNotes : {}).includes(item)) {
                                      labelClass += ' empty';
                                    }
                                    if ('sweet and floral' === item) {
                                      itemIndex = 1;
                                      familyIcons = flavors1;
                                    } else if ('fruity and citrus' === item) {
                                      itemIndex = 2;
                                      familyIcons = flavors2;
                                    } else if ('chocolaty and nuts' === item) {
                                      itemIndex = 3;
                                      familyIcons = flavors3;
                                    }
                                    let familyTitle = languageFile.thirdStep.notes[itemIndex].title;
                                    let familyNotes = languageFile.thirdStep.notes[itemIndex].tastes;
                                    if (Object.keys(currentTasteNotes ? currentTasteNotes : {}).includes(item)) {
                                      return(
                                        <div className={labelClass} key={index}>
                                          <div className="flavours-title">{familyTitle}</div>
                                          {
                                            currentTasteNotes[item].map((elI, indexI) => {
                                              let iconUrl = familyIcons[0];
                                              let iconIndexOf = familyNotes.indexOf(elI);
                                              if (iconIndexOf !== -1) {
                                                iconUrl = familyIcons[iconIndexOf];
                                              }
                                              return (
                                                <span className="label-flavor_item" key={indexI}>
                                              <span className="icon">
                                                <img src={iconUrl.icon} alt=""/>
                                              </span>
                                              <span className="title">{elI}</span>
                                            </span>
                                              )
                                            })
                                          }
                                        </div>
                                      )
                                    } else {
                                      return(
                                        <div className={labelClass} key={index}>
                                          <img src={agroIcon} alt=""/>
                                        </div>
                                      )
                                    }
                                  })
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Preloader/>
      </div>
    </>
  )
});

export default StickerComponentPrint;