import React, {useEffect, useState} from 'react';
import Card from "../UI/Card";
import BlendCard from "../UI/BlendCard";
import {InputGroup} from "../UI/InputGroup";
import {
  calculateBlendsByProfile,
  coffeeTypes,
} from "../utils/functionsUtils";
import {
  checkAndMakeMixWithAtLeastTwoTypesIfExist,
  checkAndMakeMixWithoutRobustaIfExist,
  checkAndMakeMixWithTasteNotesIfExist, findBlendWithoutOneRule
} from "../utils/RulesChecking";
import {useNavigate} from "react-router-dom";

const CoffeeBlender = () => {
  const [targetProfile, setTargetProfile] = useState({
    sweetness: 0,
    acidity: 0,
    bitterness: 0,
    body: 0,
    tasteNotes: [],
    drinkingType: ""
  });

  let navigate = useNavigate();
  const [bestBlends, setBestBlends] = useState([]);
  const availableTasteNotes = ["fruty and citrus", "chocolaty and nuts", "spices and roasted", "sweet and floral"];
  const availableDrinkingTypes = ["ESPPRESSO", "BLACK COFFEE", "FRENCH", "MAKINETA", "FILTER", "EUROPRESS"];

  useEffect(() => {
    console.log("Updated bestBlends:", bestBlends);

  }, [bestBlends]);


  const handleTasteNoteChange = (tasteNote) => {
    setTargetProfile(prevState => {
      // Check if the taste note is already selected
      if (prevState.tasteNotes.includes(tasteNote)) {
        // Remove it if it is
        return {
          ...prevState,
          tasteNotes: prevState.tasteNotes.filter(note => note !== tasteNote)
        };
      } else {
        // Add it if it's not, ensuring we don't exceed two selections
        if (prevState.tasteNotes.length < 2) {
          return {
            ...prevState,
            tasteNotes: [...prevState.tasteNotes, tasteNote]
          };
        } else {
          // Optionally, alert the user or handle max selection limit
          console.log("Maximum of 2 taste notes can be selected.");
          return prevState; // Return previous state if limit is reached
        }
      }
    });
  };

  const handleDrinkingTypesChange = (type) => {
    setTargetProfile(prevState => ({
      ...prevState,
      drinkingType: type
    }));
  };

  const handleInputChange = (e) => {
    setTargetProfile({...targetProfile, [e.target.name]: parseInt(e.target.value)});
  };


  const calculateBestBlend = () => {
    let allBlends = [];
    console.log(targetProfile.tasteNotes)

    coffeeTypes.forEach(coffeeType => {
      allBlends.push(...calculateBlendsByProfile(targetProfile, coffeeType));
    });
    console.log(allBlends)

    //allBlends = removeRobustaByCoffeeType(allBlends, targetProfile)


    allBlends.sort((a, b) => {
      if (a.matchPercentage > b.matchPercentage) return -1
      if (a.matchPercentage < b.matchPercentage) return 1;

      return b.score - a.score
    })

    let topBlend;

    console.log(allBlends);

    topBlend = checkAndMakeMixWithTasteNotesIfExist(targetProfile, allBlends[0], allBlends);

    topBlend = checkAndMakeMixWithoutRobustaIfExist(topBlend, allBlends);

    topBlend = checkAndMakeMixWithAtLeastTwoTypesIfExist(topBlend, allBlends)


    if (allBlends) {
      let secondBlend;
      let thirdBlend;

      if (topBlend) {
        secondBlend = findBlendWithoutOneRule(topBlend, allBlends);
      }
      if (secondBlend) {
        thirdBlend = findBlendWithoutOneRule(secondBlend, allBlends);
      }

      setBestBlends([topBlend, secondBlend, thirdBlend].filter(Boolean));
    } else {
      setBestBlends([]);
    }
  };


  return (
    <>
      <div className="flex flex-row justify-center items-start space-x-4 max-w-full mx-auto mt-4">
        <Card className="flex-grow">
          <div
            className="flex flex-col items-center justify-center p-6 bg-white shadow-md rounded-lg max-w-4xl mx-auto">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Coffee Blend
              Preset</h2>

            <div
              className="w-full mt-6"> {/* Adjust this div to take the full width */}
              <h2 className="text-lg font-semibold text-gray-800 mb-4">Select Your
                Drinking Type</h2>
              <div
                className="flex flex-col items-start"> {/* Align items to the start */}
                {availableDrinkingTypes.map(type => (
                  <div key={type} className="flex items-center">
                    <input
                      type="radio"
                      id={type}
                      name="drinkingType"
                      value={type}
                      checked={targetProfile.drinkingType === type}
                      onChange={() => handleDrinkingTypesChange(type)}
                      className="form-radio h-4 w-4 text-blue-600"
                    />
                    <label htmlFor={type}
                           className="ml-2 text-sm font-medium text-gray-700">{type}</label>
                  </div>
                ))}
              </div>
            </div>

            <div className="w-full mt-10">
              <h2 className="text-lg font-semibold text-gray-800">Choose Up To 2
                Taste Notes</h2>
              <div className="grid grid-cols-4 gap-4 w-full mb-6 mt-4">
                {availableTasteNotes.map(note => (
                  <label key={note}
                         className="flex items-center space-x-2 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={targetProfile.tasteNotes.includes(note)}
                      onChange={() => handleTasteNoteChange(note)}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <span className="text-gray-700">{note}</span>
                  </label>
                ))}
              </div>
            </div>

            <div className="space-y-4 mb-6 w-full">
              <InputGroup name="sweetness" label="Sweetness"
                          value={targetProfile.sweetness}
                          onChange={handleInputChange}/>
              <InputGroup name="acidity" label="Acidity"
                          value={targetProfile.acidity}
                          onChange={handleInputChange}/>
              <InputGroup name="bitterness" label="Bitterness"
                          value={targetProfile.bitterness}
                          onChange={handleInputChange}/>
              <InputGroup name="body" label="Body" value={targetProfile.body}
                          onChange={handleInputChange}/>
              <button
                onClick={calculateBestBlend}
                className="w-full text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Calculate Best Blend
              </button>
              <button
                onClick={() => {
                  navigate('/editor', {state: {bestBlends, targetProfile, coffeeTypes}});
                }}
                className="w-full text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Customize Your Coffee
              </button>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-4">
            {bestBlends.map((blend, index) => (
              <BlendCard key={index} blend={blend} index={index}/>
            ))}
          </div>
        </Card>
        {/* <div className="w-1/3 mt-40">  Adjust mt-20 to control how far down the right card starts

                        <Editor preset={bestBlends[0]}
                                clientProfile={targetProfile}
                                blendsSuggestions={bestBlends.slice(1)}
                                coffeeTypes = {coffeeTypes}
                        />

                </div>*/}
      </div>
    </>
  );
};

export default CoffeeBlender;
