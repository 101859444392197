import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import StickerComponentPrint from "./stickerComponentPrint";
import { useReactToPrint } from 'react-to-print';
import printIcon from '../images/printer_icon.svg';


const CoffeeSticker = () => {
  const { coffeeID } = useParams();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return(
    <>
      <div className="sticker-page">
        <button onClick={handlePrint} className="print-btn">
          הדפס
          <img src={printIcon} alt=""/>
        </button>
        <StickerComponentPrint coffeeID={coffeeID} ref={componentRef}/>
      </div>
    </>
  )
}

export default CoffeeSticker;