const Card = (props) => {
  return (
    <section
      className="w-1/3"
    >
      {props.children}
    </section>
  );
};

export default Card;
