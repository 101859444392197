import React from "react";

export const generateOptions = (key, totalPercentage, currentSelections, excludedCoffees) => {

    // let isExcluded = (excludedCoffees, key) =>{
    //    return excludedCoffees.includes(key)
    // }
    const options = [];
    const maxOptionValue = 100 - totalPercentage + Number(currentSelections.blendArray[key]);
    for (let i = 0; i <= 100; i += 10) {
        options.push(<option disabled={(i > maxOptionValue)} key={i} value={i}>{i}</option>);
    }
    return options;
};