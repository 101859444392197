import React, {useEffect, useRef, useState} from 'react';
import {
  calculateBlendProfile,
  // calculateBlendsByProfile,
  calculateMatchPercentage,
  coffeeTypes,
} from "../utils/functionsUtils";
import {
  calculateBlendsSortAndExclude,
  calculateBlendsByRoastingLevel,
  chooseSingleCoffee,
  Choose4Coffees,
  ChooseNewCoffee,
  isRoastLevelChanged,
  isClientChooseSingleCoffee,
  isChoose4Coffees,
  isChooseNewCoffee,
  isChanged
} from "../utils/hananFunctions";

import {
  calculateTasteNotes,
} from "../utils/tasteNotesFunctions";
// import BlendCard from "../UI/BlendCard";
import {generateOptions} from "../utils/editorUtils";
import {useLocation} from "react-router-dom";
// import ProfileCard from "../UI/ProfileCard";
import EN from "../languages/en";
import pageBG from "../images/page_bg-min.png";
import logo from '../images/logo_latest.png';
import HE from "../languages/he";
import SpiderChart from "../components/charts/radar";
import leftTopNoteBg from "../images/taste_notes/left_top.png";
import leftTopNoteBgActive from "../images/taste_notes/left_top_active.png";
import leftTopNoteIcon from "../images/taste_notes/left_top_icon.svg";
import leftTopNoteIconActive from "../images/taste_notes/left_top_icon_active.svg";
import rightTopNoteBg from "../images/taste_notes/right_top.png";
import rightTopNoteBgActive from "../images/taste_notes/right_top_active.png";
import rightTopNoteIcon from "../images/taste_notes/right_top_icon.svg";
import rightTopNoteIconActive from "../images/taste_notes/right_top_icon_active.svg";
import leftBottomNoteBg from "../images/taste_notes/left_bottom.png";
import leftBottomNoteBgActive from "../images/taste_notes/left_bottom_active.png";
import leftBottomNoteIcon from "../images/taste_notes/left_bottom_icon.svg";
import leftBottomNoteIconActive from "../images/taste_notes/left_bottom_icon_active.svg";
import rightBottomNoteBg from "../images/taste_notes/right_bottom.png";
import rightBottomNoteBgActive from "../images/taste_notes/right_bottom_active.png";
import rightBottomNoteIcon from "../images/taste_notes/right_bottom_icon.svg";
import rightBottomNoteIconActive from "../images/taste_notes/right_bottom_icon_active.svg";
import coffeeType1 from "../images/coffee_types/coffee_type_1.png";
import coffeeType2 from "../images/coffee_types/coffee_type_2.png";
import coffeeType3 from "../images/coffee_types/coffee_type_3.png";
import coffeeType4 from "../images/coffee_types/coffee_type_4.png";
import coffeeType5 from "../images/coffee_types/coffee_type_5.png";
import coffeeType6 from "../images/coffee_types/coffee_type_6.png";
//flavors
import almondIcon from '../images/taste_notes/flavors/flavor_1.svg';
import roastedAlmondIcon from '../images/taste_notes/flavors/agro-icon-brown-16.svg';
import sugarCaneIcon from '../images/taste_notes/flavors/flavor_2.svg';
import spicesIcon from '../images/taste_notes/flavors/flavor_4.svg';
import cinnamonIcon from '../images/taste_notes/flavors/flavor_5.svg';
import peanutsIcon from '../images/taste_notes/flavors/flavor_6.svg';
import hazelnutIcon from '../images/taste_notes/flavors/flavor_7.svg';
import cocoaIcon from '../images/taste_notes/flavors/flavor_8.svg';
import chocolateIcon from '../images/taste_notes/flavors/flavor_9.svg';
import herbsIcon from '../images/taste_notes/flavors/agro-icon-brown-7.svg';
import cloveIcon from '../images/taste_notes/flavors/agro-icon-brown-8.svg';
// import cloveIcon from '../images/taste_notes/flavors/agro-icon-citrus-fruits.svg';
import vanillaIcon from '../images/taste_notes/flavors/agro-icon-brown-25.svg';
import brownSugarIcon from '../images/taste_notes/flavors/agro-icon-brown-14.svg';
import caramelIcon from '../images/taste_notes/flavors/agro-icon-brown-14.svg';
import hibiscusIcon from '../images/taste_notes/flavors/agro-icon-brown-17.svg';
import honeyIcon from '../images/taste_notes/flavors/agro-icon-brown-1.svg';
import jasmineFlowerIcon from '../images/taste_notes/flavors/agro-icon-brown-17.svg';
import mapleSyrupIcon from '../images/taste_notes/flavors/agro-icon-brown-3.svg';
import appleIcon from '../images/taste_notes/flavors/agro-icon-brown-24.svg';
import mangoIcon from '../images/taste_notes/flavors/agro-icon-brown-26.svg';
import orangeIcon from '../images/taste_notes/flavors/agro-icon-brown-10.svg';
// import orangeIcon from '../images/taste_notes/flavors/agro-icon-date-honey.svg';
import brownButterIcon from '../images/taste_notes/flavors/agro-icon-brown-21.svg';
import milkChocolateIcon from '../images/taste_notes/flavors/agro-icon-brown-4.svg';
import whiteChocolateIcon from '../images/taste_notes/flavors/agro-icon-brown-19.svg';
import walnutIcon from '../images/taste_notes/flavors/walnut-icon.svg';
import lemonGrassIcon from '../images/taste_notes/flavors/lemon-grass-icon-new.svg';
import blueberryIcon from '../images/taste_notes/flavors/blueberry-icon.svg';
import grapefruitIcon from '../images/taste_notes/flavors/grapefruit-icon.svg';
import bakedPearIcon from '../images/taste_notes/flavors/agro-icon-brown-12.svg';
import citrusFruitsIcon from '../images/taste_notes/flavors/citrus-icon-new.svg';
import hotelIcon from '../images/taste_notes/flavors/hotel-icon.svg';
import halvahIcon from '../images/taste_notes/flavors/halvah-icon.svg';
import toffeeIcon from '../images/taste_notes/flavors/toffee-icon.svg';

import licoriceIcon from '../images/taste_notes/flavors/licorice-icon.png';
import biscuitIcon from '../images/taste_notes/flavors/biscuit-icon.png';
import dateHoneyIcon from '../images/taste_notes/flavors/date_honey_icon.svg';
import tropicalFruitsIcon from '../images/taste_notes/flavors/tropical-fruits-icon_new.png';

import {useNavigate} from "react-router-dom";
// import Assistant from "../components/assistant";
import tooltipIcon from "../images/tooltip_icon.svg";
import reloadIcon from "../images/reload_icon.svg";
import surpriseIcon from "../images/surprise_icon_new.svg";
import profileIcon from "../images/profile_icon.svg";
import tooltipIconGrey from "../images/tooltip_icon_grey.svg";
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
// import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import RoastMeModal from "../components/modals/roastMe";
import closeIcon from "../images/assistant/close_icon.svg";
import closeIconBlack from "../images/assistant/close_icon_black.svg";
import photo from "../images/assistant/photo.png";
import quoteIcon from "../images/assistant/quote_icon.svg";
import muteIcon from "../images/assistant/mute_icon.svg";
import removedIcon from "../images/remove_icon.svg";
import agroIcon from "../images/flower.png";

const EditorNew = () => {
  const divRef = useRef();

  let location = useLocation();
  let {bestBlends = [], targetProfile, bestBlendsArr} = location.state || {};
  // console.log("bestblend0",bestBlends[0])
  const [isChangedByClient, setIsChangedByClient] = useState(true);
  // targetProfile = {
  //   sweetness: 2,
  //   acidity: 3,
  //   bitterness: 2,
  //   body: 3,
  //   tasteNotes: [2,0],
  //   drinkingType: 2,
  //   howOftenDrink: 2
  // };
  // console.log(targetProfile);
  // console.log(bestBlends[0]);
  // console.log(location.state);

  let blendProfileDefault = {blendProfile: {
      acidity: targetProfile.acidity,
      bitterness: targetProfile.bitterness,
      body: targetProfile.body,
      sweetness: targetProfile.sweetness
    }};

  // let bestBlendsSetup = bestBlends;

  // console.log("From step 6", bestBlendsArr);


  const [totalPercentage, setTotalPercentage] = useState(0);
  const [currentMatchPercentage, setCurrentMatchPercentage] = useState(57);
  const [currentProfilePreferences, setCurrentProfilePreferences] = useState(blendProfileDefault);
  const [currentTasteNotes, setCurrentTasteNotes] = useState();
  const [surpriseStep, setSurpriseStep] = useState(1);
  const [percentageClass, setPercentageClass] = useState('percentage-wrapper');
  // const [percentageColor, setPercentageColor] = useState('#00944d');
  const [assistantText, setAssistantText] = useState('\n' +
    'יצרתי עבורך קפה שמתאים בדיוק לפרופיל האישי שלך. לחיצה על כפתור ״תפתיע אותי״ תיצור אפשרויות נוספות ולחיצה על כפתור ״אפס״ תחזיר אותך לפרופיל הראשון. ניתן לשנות את סוגי הקפה ואת רמת הקלייה ואני נשאר כאן כדי לסייע במידת הצורך. בהצלחה!');
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [showAssistantPopup, setShowAssistantPopup] = useState(1);
  const [mutedAssistant, setMutedAssistant] = useState(0);
  const [muteLabel, setMuteLabel] = useState('השתק');

  if (1 === showAssistantPopup) {
    setTimeout(() => {
      document.querySelector('.step-item.active').classList.add('assistant-open');
      const {current} = divRef;
      if (current !== null){
        current.scrollIntoView({behavior: "smooth"})
      }
      setShowAssistantPopup(0);
    }, 1000);
  }

  useEffect(() => {
    if (parseInt(currentMatchPercentage) >= 70 && parseInt(currentMatchPercentage) < 85) {
      setPercentageClass('percentage-wrapper medium');
      // setPercentageColor('#A26128');
    } else if (parseInt(currentMatchPercentage) < 70) {
      setPercentageClass('percentage-wrapper low');
      // setPercentageColor('#f00');
    } else if (parseInt(currentMatchPercentage) >= 85) {
      setPercentageClass('percentage-wrapper');
      // setPercentageColor('#00944d');
    }
  }, [currentMatchPercentage]);

  const initialSelections = bestBlends[0] || {
    blendArray: {
      ethiopia: 60,
      brazil: 20,
      colombia: 20,
      honduras: 0,
      papua: 0,
      robusta: 0
    },
    roastLevel: 3,
    tasteNotes: ["chocolaty and nuts", "fruty and citrus"],
    matchPercentage: 0,
    rulesMet: []
  };

  const [currentSelections, setCurrentSelections] = useState(
    {...initialSelections,
    isChangedByClient: true}
  );

  const [roastLevelRangeValue, setRoastLevelRangeValue] = useState(() =>{
    if(currentSelections.roastLevel === 1){
      return 1.2
    }else{
      return currentSelections.roastLevel
    }
  });

  const [currentSuggestions, setCurrentSuggestions] = useState([])
  const prevSelectionsRef = useRef({
    blendArray: currentSelections.blendArray,
    roastLevel: currentSelections.roastLevel,
  });
  const [excludedCoffees, setExcludedCoffees] = useState([]);
  const prevExcludedCoffeesRef = useRef(excludedCoffees);
  const [percentageLeft, setPercentageLeft] = useState(0);
  const [roastBtnStatusDisabled, setRoastBtnStatusDisabled] = useState(false);



  useEffect(() => {
    // console.log(currentSelections);
    //set percentage
    // setCurrentMatchPercentage(currentSelections.matchPercentage);
    const total = Object.values(currentSelections.blendArray).reduce((acc, value) => acc + Number(value), 0);
    setTotalPercentage(total);
    checkChangesAndOfferSuggestions();
    setPercentageLeft(100 - total);
    if (100 === total) {
      setRoastBtnStatusDisabled(false);
    } else {
      setRoastBtnStatusDisabled(true);
    }
  }, [currentSelections]);
  


  const handleSelectionChange = (e, key) => {
    const value = Number(e.target.value);
    setCurrentSelections(prev => ({
      ...prev,
      blendArray: {
        ...prev.blendArray,
        [key]: value
      }
    }));
  };

  const handleSelectionChangeNew = (e, key) => {
    e.preventDefault();
    setIsChangedByClient(true);
    const value = parseInt(e.target.innerText);
    setCurrentSelections(prev => ({
      ...prev,
      blendArray: {
        ...prev.blendArray,
        [key]: value
      },
      isChangedByClient: true
    }));
    e.target.closest('.custom-dropdown_wrapper').classList.remove('open', 'removed');
    // e.target.closest('.custom-dropdown_wrapper').querySelector('.exclude-btn').click();
    setShowSuggestions(true);
  };

  const handleRoastChange = (e) => {
    const value = Number(e.target.value);
    setCurrentSelections(prevState => ({...prevState, roastLevel: value}));
  };

  const handleRoastChangeNew = (e) => {
    
    setIsChangedByClient(true);
    setRoastLevelRangeValue(Number(e[1]))
    const value = Math.round(Number(e[1]));
    const difference = Math.abs(value - currentSelections.roastLevel);
    if(difference === 1 || difference === 2){
      setCurrentSelections((prevState) => 
        ({ ...prevState, 
          roastLevel: value,
          isChangedByClient: true
        }));
    }
    // setCurrentSelections(prevState => ({...prevState, roastLevel: value}));
    setShowSuggestions(true);
  };

  const handleExclusionChange = (e) => {
    setIsChangedByClient(true);
    const {value, checked} = e.target;
    setExcludedCoffees(current => {
      if (checked) {
        return [...current, value];
      } else {
        return current.filter(type => type !== value);
      }
    });

    setCurrentSelections(prev => ({
      ...prev,
      blendArray: {
        ...prev.blendArray,
        [value]: 0
      },
      isChangedByClient: true
    }));
    setShowSuggestions(true);
    e.target.closest('.custom-dropdown_wrapper').classList.remove('open');
    e.target.closest('.custom-dropdown_wrapper').classList.toggle('removed');
  };

  useEffect(() => {
    checkChangesAndOfferSuggestions();
  }, [excludedCoffees]);


  const checkChangesAndOfferSuggestions = () => {
    if (isChanged(
      currentSelections,
      prevSelectionsRef,
      excludedCoffees,
      prevExcludedCoffeesRef,
      currentSelections.isChangedByClient)) {
      let newSuggestions = [];

      //calculate all possible blends
      let allBlends = calculateBlendsSortAndExclude(
        targetProfile,
        coffeeTypes,
        excludedCoffees,
        bestBlends[0]
      );
      //find the coffee table by the roasting level
      let currentCoffeeTypeByRoastLevel = coffeeTypes.find(
        (type) => type.roastLevel === currentSelections.roastLevel
      );

      // The current blend preferences
      let blendProfile = calculateBlendProfile(
        currentSelections.blendArray,
        currentCoffeeTypeByRoastLevel
      );
      setCurrentProfilePreferences((prev) => ({
        ...prev,
        blendProfile,
      }));

      //The current blend match percentage
      let currentBlendMatchPercentage = calculateMatchPercentage(
        blendProfile,
        targetProfile
      );
      setCurrentMatchPercentage(currentBlendMatchPercentage);

      //The current blend Taste notes by the rules
      let currentTasteNotes = calculateTasteNotes(
        currentCoffeeTypeByRoastLevel,
        currentSelections,
        targetProfile.tasteNotes
      );
      setCurrentTasteNotes(currentTasteNotes)

      // The client changed the roasting level
      if (isRoastLevelChanged(currentSelections.roastLevel,
        prevSelectionsRef.current.roastLevel) &&
        calculateBlendsByRoastingLevel(
        allBlends,
        currentBlendMatchPercentage,
        currentSelections.roastLevel
      ) !== null
      ) {
        newSuggestions.push({
          rule: "RoastLevelChanged",
          blend: calculateBlendsByRoastingLevel(
            allBlends,
            currentBlendMatchPercentage,
            currentSelections.roastLevel
          ),
        });
      }

      // The client choose single coffee type
      if (isClientChooseSingleCoffee(currentSelections.blendArray,
        prevSelectionsRef.current.blendArray
      )) {
        let foundBlend = chooseSingleCoffee(
          allBlends,
          targetProfile,
          currentBlendMatchPercentage,
          currentSelections.blendArray
        );
        if(foundBlend[0] !== null){
        newSuggestions.push({
          rule: "singleCoffee",
          secondRule: foundBlend[1],
          blend: foundBlend[0],
        });
      }
      }

      // The client choose at least 4 kinds of coffee types
      if (isChoose4Coffees(currentSelections.blendArray, prevSelectionsRef.current.blendArray)
      && Choose4Coffees(allBlends, currentSelections.blendArray) !== null
        ) {
        newSuggestions.push({
          rule: "choose4Coffees",
          blend: Choose4Coffees(allBlends, currentSelections.blendArray),
        });
      }

      // The client choose new type of coffee
      let diffCoffeeKey = isChooseNewCoffee(
        currentSelections.blendArray,
        prevSelectionsRef.current.blendArray
      );
      if (diffCoffeeKey &&
        ChooseNewCoffee(
          allBlends,
          targetProfile,
          currentBlendMatchPercentage,
          coffeeTypes,
          excludedCoffees,
          diffCoffeeKey,
          currentSelections.blendArray,
          prevSelectionsRef.current.blendArray
        ) !== null
      ) {
        newSuggestions.push({
          rule: "chooseNewCoffee",
          blend: ChooseNewCoffee(
            allBlends,
            targetProfile,
            currentBlendMatchPercentage,
            coffeeTypes,
            excludedCoffees,
            diffCoffeeKey,
            currentSelections.blendArray,
            prevSelectionsRef.current.blendArray
          ),
        });
      }
      updateStatesAndSuggestions(newSuggestions);
    }
  };

  function updateStatesAndSuggestions(newSuggestions) {
    const ruleOrder = [
      "singleCoffee",
      "choose4Coffees",
      "RoastLevelChanged",
      "chooseNewCoffee",
    ];

    if (newSuggestions.length > 1) {
      newSuggestions.sort((a, b) => {
        const indexA = ruleOrder.indexOf(a.rule);
        const indexB = ruleOrder.indexOf(b.rule);

        // Check if one of the rules is Rule 3 and the other is Rule 5
        if (
          (a.rule === "RoastLevelChanged" && b.rule === "chooseNewCoffee") ||
          (a.rule === "chooseNewCoffee" && b.rule === "RoastLevelChanged")
        ) {
          if (a.blend != null && b.blend != null) {
            // Access matchPercentage inside blend attribute
            const isARule5 = a.rule === "chooseNewCoffee";
            const diff = isARule5
              ? a.blend.matchPercentage - b.blend.matchPercentage
              : b.blend.matchPercentage - a.blend.matchPercentage;

            // If Rule 5's matchPercentage is more than 5% greater, prioritize Rule 5
            if (diff > 5) {
              return isARule5 ? -1 : 1;
            }
          }
        }

        return indexA - indexB;
      });
      //console.log("new suggestions updated:", newSuggestions)
    }
    // For a single suggestion or sorted suggestions, update state
    // This will update with all unique blends if multiple, or just the one if a single
    setCurrentSuggestions(() => {
      // console.log("allSuggestions,", Array.from(new Set(newSuggestions)));
      let finalSuggestions = Array.from(
        newSuggestions.filter((suggestion) => suggestion.blend != null)
      );
      // console.log("finalSuggestionsAfterFilter:", finalSuggestions);
      //const uniqueBlends = Array.from(new Set(newSuggestions.map(suggestion => suggestion.blend)));
      //finalSuggestions = Array.from(new Set(newSuggestions));
      return finalSuggestions.length > 1
        ? [finalSuggestions[0]]
        : finalSuggestions;
    });

    // Update the previous selections ref with the current selections for the next comparison
    prevSelectionsRef.current = {
      blendArray: currentSelections.blendArray,
      roastLevel: currentSelections.roastLevel,
    };

    prevExcludedCoffeesRef.current = excludedCoffees;
  }

  useEffect(() => {
    // console.log(currentSuggestions);
    // console.log(showSuggestions);
    if (currentSuggestions[0] && showSuggestions) {
      // console.log(currentSuggestions[0]);
      let rule = currentSuggestions[0].rule;
      let ruleBlend = currentSuggestions[0].blend;
      if (ruleBlend) {
        // console.log(rule);
        // console.log(ruleBlend);
        // let ruleStr = '<strong>X</strong>';
        // let ruleStr = '<strong>'+ruleBlend.matchPercentage+'%</strong>';
        // let ruleStr = '<strong>'+ruleBlend.roastLevel+'</strong>';
        let ruleStr = '';
        if ('RoastLevelChanged' === rule) {
          // ruleStr = 'הי שמתי לב  ששינת את רמת הקלייה ל '+ruleBlend.roastLevel+' (בהתאם לרמה ששונתה), אני מציע לשמר את רמת הקלייה הזו ולשנות את תמהיל הקפה כך שנגיע ל '+ruleBlend.matchPercentage+'% אחוזי התאמה.';
          // ruleStr = 'הי שמתי לב  ששינת את רמת הקלייה ל <strong>'+ruleBlend.roastLevel+'</strong> (בהתאם לרמה ששונתה), אני מציע לשמר את רמת הקלייה הזו ולשנות את תמהיל הקפה כך שנגיע ל <strong>'+ruleBlend.matchPercentage+'%</strong> אחוזי התאמה.'
          let roastLevelStr = 'בהירה';
          if (2 === ruleBlend.roastLevel) {
            roastLevelStr = 'בינונית';
          } else if (3 === ruleBlend.roastLevel) {
            roastLevelStr = 'כהה';
          }
          // ruleStr = 'שמתי לב  ששינת את רמת הקלייה ל <strong class="brown">'+roastLevelStr+'</strong>, אני מציע לשמר את רמת הקלייה הזו ולשנות את תמהיל הקפה כך שנגיע ל <strong>'+ruleBlend.matchPercentage+'%</strong> אחוזי התאמה.';
          ruleStr = 'שמתי לב ששינת את רמת הקלייה ל<strong class="brown">'+roastLevelStr+'</strong>. <strong>אני מציע לשמר את רמת הקלייה הזו ולשנות את תמהיל הקפה</strong> כך שנגיע ל <strong class="green">'+ruleBlend.matchPercentage+'%</strong> אחוזי התאמה.';
        } else if ('chooseNewCoffee' === rule) {
          // ruleStr = 'הי בהתאם להעדפות הקפה שלך אני יכול להציע לך בלנד בהרכב זהה לזה שבחרת בשינוי היחס בין הזנים כך שנגיע ל '+ruleBlend.matchPercentage+'% התאמה';
          // ruleStr = 'הי בהתאם להעדפות הקפה שלך אני יכול להציע לך בלנד בהרכב זהה לזה שבחרת בשינוי היחס בין הזנים כך שנגיע ל <strong>'+ruleBlend.matchPercentage+'%</strong> התאמה';
          // ruleStr = 'בהתאם להעדפות הקפה שלך אני יכול להציע לך בלנד בהרכב זהה לזה שבחרת בשינוי היחס בין הזנים כך שנגיע ל <strong class="green">'+ruleBlend.matchPercentage+'%</strong> התאמה';
          ruleStr = 'בהתאם להעדפות הקפה שלך <strong>אני יכול להציע לך בלנד בהרכב זהה לזה שבחרת בשינוי היחס בין הזנים</strong> כך שנגיע ל <strong class="green">'+ruleBlend.matchPercentage+'%</strong> התאמה';
        } else if ('choose4Coffees' === rule) {
          let coffeeCount = 0;
          Object.values(currentSelections.blendArray).forEach((el, index) => {
            if (el > 0) {
              coffeeCount++;
            }
          });
          // let sjdgshdhg = '<strong>יש לי בלנד דומה להציע לך</strong>';
          // ruleStr = 'הי שמתי לב שבחרת '+coffeeCount+' סוגי קפה (בהתאם למספר הסוגים שנבחרו), יש לי בלנד דומה להציע לך כך שנגיע ל '+ruleBlend.matchPercentage+'% התאמה.';
          // ruleStr = 'הי שמתי לב שבחרת <strong>'+coffeeCount+'</strong> סוגי קפה (בהתאם למספר הסוגים שנבחרו), יש לי בלנד דומה להציע לך כך שנגיע ל <strong>'+ruleBlend.matchPercentage+'%</strong> התאמה.';
          // ruleStr = 'שמתי לב שבחרת <strong class="brown">'+coffeeCount+'</strong> סוגי קפה (בהתאם למספר הסוגים שנבחרו), יש לי בלנד דומה להציע לך כך שנגיע ל <strong class="green">'+ruleBlend.matchPercentage+'%</strong> התאמה.';
          ruleStr = 'שמתי לב שבחרת <strong class="brown">'+coffeeCount+'</strong> סוגי קפה , <strong>יש לי בלנד דומה להציע לך</strong> כך שנגיע ל <strong class="green">'+ruleBlend.matchPercentage+'%</strong> התאמה.';
        } else if ('singleCoffee' === rule) {
          let secondRule = currentSuggestions[0].secondRule;
          // let secondRuleStr = 'את אותו הקפה בקלייה שונה כך שנגיע ל '+ruleBlend.matchPercentage+'% התאמה';
          let secondRuleStr = '<strong>אותו הקפה בקלייה שונה</strong> כך שנגיע ל <strong class="green">'+ruleBlend.matchPercentage+'%</strong> התאמה';
          // let secondRuleStr = 'את אותו הקפה בקלייה שונה כך שנגיע ל '+ruleBlend.matchPercentage+'% התאמה';
          if (2 === secondRule) {
            // secondRuleStr = 'קפה חד זני אחר כך שנגיע ל '+ruleBlend.matchPercentage+'% התאמה';
            secondRuleStr = '<strong>קפה חד זני אחר</strong> כך שנגיע ל <strong class="green">'+ruleBlend.matchPercentage+'%</strong> התאמה';
            // secondRuleStr = 'קפה חד זני אחר כך שנגיע ל '+ruleBlend.matchPercentage+'% התאמה';
          } else if (3 === secondRule) {
            // secondRuleStr = 'להוסיף מעט מזן קפה נוסף כך שנגיע ל '+ruleBlend.matchPercentage+'% התאמה';
            secondRuleStr = '<strong>להוסיף מעט מזן קפה נוסף</strong> כך שנגיע ל <strong class="green">'+ruleBlend.matchPercentage+'%</strong> התאמה';
            // secondRuleStr = 'להוסיף מעט מזן קפה נוסף כך שנגיע ל '+ruleBlend.matchPercentage+'% התאמה';
          }
          ruleStr = 'שמתי לב שבחרת <strong>בקפה חד זני</strong>. אני יכול להציע לך '+secondRuleStr;
        }
        if ('' === ruleStr) {
          document.querySelector('.assistant-quote .buttons').classList.add('hidden');
        } else {
          document.querySelector('.assistant-quote .buttons').classList.remove('hidden');
        }
        setAssistantText(ruleStr);
        document.querySelector('.step-item.active').classList.add('assistant-has-suggestions');
        if (0 === mutedAssistant) {
          document.querySelector('.step-item.active').classList.add('assistant-open');
        }
      }
    }
  }, [currentSuggestions])


  let navigate = useNavigate();

  const [language, setLanguage] = useState('he');
  const [languageFile, setLanguageFile] = useState(HE);

  let progressWidth = '100%';

  let languages = ['en', 'he'];

  function changeLanguage(e){
    e.preventDefault();
    let selectedLang = e.target.getAttribute('data-lang');
    setLanguage(selectedLang);
    if ('en' === selectedLang) {
      setLanguageFile(EN);
    } else {
      setLanguageFile(HE);
    }
  }

  let coffeeTypesMy = [
    {icon: coffeeType4},
    {icon: coffeeType3},
    {icon: coffeeType2},
    {icon: coffeeType6},
    {icon: coffeeType5},
    {icon: coffeeType1}
  ];

  let flavors0 = [
    {icon: roastedAlmondIcon},
    {icon: sugarCaneIcon},
    {icon: herbsIcon},
    {icon: cloveIcon},
    {icon: spicesIcon},
    {icon: cinnamonIcon},
    {icon: lemonGrassIcon},
    {icon: licoriceIcon},
  ];

  let flavors1 = [
    {icon: herbsIcon},
    {icon: vanillaIcon},
    {icon: brownSugarIcon},
    {icon: hibiscusIcon},
    {icon: caramelIcon},
    {icon: honeyIcon},
    {icon: jasmineFlowerIcon},
    {icon: dateHoneyIcon},
    {icon: mapleSyrupIcon},
    {icon: halvahIcon},
    {icon: toffeeIcon},
    {icon: biscuitIcon},
  ];

  let flavors2 = [
    {icon: appleIcon},
    {icon: mangoIcon},
    {icon: lemonGrassIcon},
    {icon: blueberryIcon},
    {icon: orangeIcon},
    {icon: grapefruitIcon},
    {icon: bakedPearIcon},
    {icon: tropicalFruitsIcon},
    {icon: citrusFruitsIcon},
    {icon: hotelIcon}
  ];

  let flavors3 = [
    {icon: brownButterIcon},
    {icon: peanutsIcon},
    {icon: hazelnutIcon},
    {icon: chocolateIcon},
    {icon: almondIcon},
    {icon: milkChocolateIcon},
    {icon: cocoaIcon},
    {icon: whiteChocolateIcon},
    {icon: walnutIcon},
  ];

  const handleTasteNoteChange = (tasteNote) => {
    setCurrentSelections(prevState => {
      // Check if the taste note is already selected
      if (prevState.tasteNotes.includes(tasteNote)) {
        // Remove it if it is
        return {
          ...prevState,
          tasteNotes: prevState.tasteNotes.filter(note => note !== tasteNote)
        };
      } else {
        // Add it if it's not, ensuring we don't exceed two selections
        if (prevState.tasteNotes.length < 2) {
          return {
            ...prevState,
            tasteNotes: [...prevState.tasteNotes, tasteNote]
          };
        } else {
          // Optionally, alert the user or handle max selection limit
          // console.log("Maximum of 2 taste notes can be selected.");
          return prevState; // Return previous state if limit is reached
        }
      }
    });
  };

  function openCustomDropdown(e){
    e.preventDefault();
    if (e.target.closest('.custom-dropdown_wrapper').classList.contains('open')) {
      e.target.closest('.custom-dropdown_wrapper').classList.remove('open');
    } else {
      let customDropdowns = document.querySelectorAll('.custom-dropdown_wrapper');
      customDropdowns.forEach((dropdown) => {
        dropdown.classList.remove('open');
      });
      e.target.closest('.custom-dropdown_wrapper').classList.add('open');
    }
  }

  let roatingLevels = languageFile.product.roastingLevels;

  // console.log(currentSelections);

  function openRoastPopup(){
    document.getElementById('roast-me-modal').classList.add('open')
  }

  let coffeeBlends = languageFile.editor.coffeeBlends;

  function openCoffeeID(e){
    e.preventDefault();
    e.target.closest('.step-item_wrapper').classList.add('open-coffee-id');
  }
  function closeCoffeeID(e){
    e.preventDefault();
    e.target.closest('.step-item_wrapper').classList.remove('open-coffee-id');
  }

  function resetSelection(e){
    e.preventDefault();
    removingExcludedCoffees();
    setCurrentSelections((prevState) => 
      ({ ...prevState, 
        roastLevel: bestBlends[0].roastLevel,
        blendArray: bestBlends[0].blendArray,
        isChangedByClient: false
      }));
      if(bestBlends[0].roastLevel === 1){
        setRoastLevelRangeValue(1.2)
      }else{
       setRoastLevelRangeValue(bestBlends[0].roastLevel) 
      }
      setCurrentMatchPercentage(bestBlends[0].matchPercentage);

      let currentCoffeeTypeByRoastLevel = coffeeTypes.find(
        (type) => type.roastLevel === bestBlends[0].roastLevel
      );
  
      let currentTasteNotes = calculateTasteNotes(
        currentCoffeeTypeByRoastLevel,
        {
          blendArray: bestBlends[0].blendArray,
          roastLevel: bestBlends[0].roastLevel,
          isChangedByClient: false
        },
        targetProfile.tasteNotes
      );
  
      setCurrentTasteNotes(currentTasteNotes)

           // The current blend preferences
           let blendProfile = calculateBlendProfile(
            bestBlends[0].blendArray,
            currentCoffeeTypeByRoastLevel
          );
          setCurrentProfilePreferences((prev) => ({
            ...prev,
            blendProfile,
          }));

    //setCurrentSelections(bestBlends[0]);
    setSurpriseStep(1);
    setIsChangedByClient(false);

    prevSelectionsRef.current = {
      blendArray: bestBlends[0].blendArray,
      roastLevel: bestBlends[0].roastLevel,
    };
  }

  function showNextBlend(e){
    e.preventDefault();
    removingExcludedCoffees();

    let nextBlend = surpriseStep;
    if (bestBlends[nextBlend]) {
      setCurrentSelections((prevState) => 
        ({ ...prevState, 
          roastLevel: bestBlends[nextBlend].roastLevel,
          blendArray: bestBlends[nextBlend].blendArray,
          isChangedByClient: false
        }));
        if(bestBlends[nextBlend].roastLevel === 1){
          setRoastLevelRangeValue(1.2)
        }else{
         setRoastLevelRangeValue(bestBlends[nextBlend].roastLevel) 
        }
        setCurrentMatchPercentage(bestBlends[nextBlend].matchPercentage);

        let currentCoffeeTypeByRoastLevel = coffeeTypes.find(
          (type) => type.roastLevel === bestBlends[nextBlend].roastLevel
        );
    
        let currentTasteNotes = calculateTasteNotes(
          currentCoffeeTypeByRoastLevel,
          {
            blendArray: bestBlends[nextBlend].blendArray,
            roastLevel: bestBlends[nextBlend].roastLevel,
            isChangedByClient: false
          },
          targetProfile.tasteNotes
        );
    
        setCurrentTasteNotes(currentTasteNotes)

          // The current blend preferences
           let blendProfile = calculateBlendProfile(
            bestBlends[nextBlend].blendArray,
            currentCoffeeTypeByRoastLevel
          );
          setCurrentProfilePreferences((prev) => ({
            ...prev,
            blendProfile,
          }));
      //setCurrentSelections(bestBlends[nextBlend]);
      setSurpriseStep(nextBlend+1);
      setIsChangedByClient(false);

      prevSelectionsRef.current = {
        blendArray: bestBlends[nextBlend].blendArray,
        roastLevel: bestBlends[nextBlend].roastLevel,
      };
    }
  }

  function removingExcludedCoffees() {
    setExcludedCoffees([]);
    let removedDropdowns = document.querySelectorAll('.blend-dropdown_item .custom-dropdown_wrapper');
    removedDropdowns.forEach((item) => {
      item.classList.remove('removed');
    });
  }

  function showSuggestion(e){
    e.preventDefault();
    setShowSuggestions(false);
    setIsChangedByClient(false);
    
    // console.log("current suggestion", currentSuggestions)
    if (currentSuggestions[0] && currentSuggestions[0].blend) {
      setCurrentSelections(prevState => ({
      ...prevState, 
      blendArray: currentSuggestions[0].blend.blendArray,
      roastLevel: currentSuggestions[0].blend.roastLevel,
      isChangedByClient: false
    }));
    //update current roast level range 
    if(currentSuggestions[0].blend.roastLevel === 1){
      setRoastLevelRangeValue(1.2)
    }else{
     setRoastLevelRangeValue(currentSuggestions[0].blend.roastLevel) 
    }
    //update current match percentage
    setCurrentMatchPercentage(currentSuggestions[0].blend.matchPercentage);


    let currentCoffeeTypeByRoastLevel = coffeeTypes.find(
      (type) => type.roastLevel === currentSuggestions[0].blend.roastLevel
    );

    let currentTasteNotes = calculateTasteNotes(
      currentCoffeeTypeByRoastLevel,
      {
        blendArray: currentSuggestions[0].blend.blendArray,
        roastLevel: currentSuggestions[0].blend.roastLevel,
        isChangedByClient: false
      },
      targetProfile.tasteNotes
    );
    //update current taste notes
    setCurrentTasteNotes(currentTasteNotes)

         // The current blend preferences
         let blendProfile = calculateBlendProfile(
          currentSuggestions[0].blend.blendArray,
          currentCoffeeTypeByRoastLevel
        );
        setCurrentProfilePreferences((prev) => ({
          ...prev,
          blendProfile,
        }));


      prevSelectionsRef.current = {
        blendArray: currentSuggestions[0].blend.blendArray,
        roastLevel: currentSuggestions[0].blend.roastLevel,
      };
    }
    document.querySelector('.step-item').classList.remove('assistant-open');
    document.querySelector('.step-item').classList.remove('assistant-has-suggestions');
    setIsChangedByClient(false);
   
  }

  // console.log(currentProfilePreferences);

  // console.log(currentSelections);

  function closeAssistant(e){
    e.preventDefault();
    // document.querySelector('.assistant-quote').classList.remove('open');
    setShowSuggestions(true);
    e.target.closest('.step-item').classList.remove('assistant-open');
    e.target.closest('.step-item').classList.remove('assistant-has-suggestions');
  }
  function showAssistant(e){
    e.preventDefault();
    // document.querySelector('.assistant-quote').classList.add('open');
    e.target.closest('.step-item').classList.add('assistant-open');
  }

  useEffect(() => {
    document.querySelector('.editor-wrapper .before').addEventListener('click', (e) => {
      if (document.querySelector('.step-item.active').classList.contains('assistant-open')) {
        let clickedObject = e.target;
        if (clickedObject.closest('.blend-assistant') === null) {
          setShowSuggestions(true);
          document.querySelector('.step-item.active').classList.remove('assistant-open');
        }
      }
    })
  }, []);

  // console.log(currentTasteNotes);

  let notesDefaultArr = ['fruity and citrus', 'spices and roasted', 'sweet and floral', 'chocolaty and nuts'];

  function muteAssistant(e){
    e.preventDefault();
    if (0 === mutedAssistant) {
      setMutedAssistant(1);
      setMuteLabel('הפעל');
      e.target.closest('.step-item').classList.add('assistant-muted');
    } else {
      setMutedAssistant(0);
      setMuteLabel('השתק');
      e.target.closest('.step-item').classList.remove('assistant-muted');
    }
  }

  // console.log(targetProfile);

  function showTooltipMobile(e){
    e.preventDefault();
    e.currentTarget.closest('.editor-block_item').classList.add('show-tooltip-mobile');
  }

  function hideTooltipMobile(e){
    e.preventDefault();
    e.currentTarget.closest('.editor-block_item').classList.remove('show-tooltip-mobile');
  }

  return (
    <>
      <div className={'page-content lang-'+language} style={{backgroundImage: `url(${pageBG})`}}>
        <div className="lang-switcher">
          {
            languages.map((el, index) => {
              return(
                <button className={el === language ? 'lang-btn active' : 'lang-btn'} key={index} onClick={changeLanguage} data-lang={el}>{el}</button>
              )
            })
          }
        </div>
        <div className="page-header">
          <a className="logo" href="/">
            <img src={logo} alt=""/>
          </a>
        </div>
        <div className="steps-progress">
          <div className="steps-progress_body" style={{width: progressWidth}}>Step progress</div>
        </div>
        <div className="steps-wrapper" ref={divRef}>
          <div className="step-item active">
            <div className="step-item_wrapper editor-wrapper">
              <div className="before"></div>
              <div className="blend-wrapper">
                <div className="blend-wrapper_left editor-left">
                  <div className="coffee-type">
                    <div className="coffee-type_icon">
                      <div className="icon">
                        <img src={targetProfile.drinkingType !== "" ? coffeeTypesMy[targetProfile.drinkingType].icon : ''} alt=""/>
                      </div>
                      <div className="name">{targetProfile.drinkingType !== "" ? languageFile.firstStep.coffeeTypes[targetProfile.drinkingType] : ''}</div>
                    </div>
                    <div className="coffee-type_info">
                      <div className="title">{languageFile.sixthStep.title}</div>
                      <div className="subtitle">{languageFile.sixthStep.subtitle}</div>
                      <div className="change-preferences">
                        <button className="change-btn" onClick={() => {navigate('/')}}>
                          <img src={reloadIcon} alt=""/>
                          {languageFile.sixthStep.changePreferenceTitle}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flavors">
                    <div className="blend-sidebar_title">
                      <div className="title">{languageFile.sixthStep.flavorsTitle}</div>
                      <hr></hr>
                    </div>
                    <div className="flavors-wrapper">
                      <div className="flavors-values block">
                        <div className="flavor-value_item">
                          <div className="value">{targetProfile.acidity}/5</div>
                          <div className="label">{languageFile.secondStep.preferences[0].title}</div>
                        </div>
                        <div className="flavor-value_item">
                          <div className="value">{targetProfile.sweetness}/5</div>
                          <div className="label">{languageFile.secondStep.preferences[1].title}</div>
                        </div>
                        <div className="flavor-value_item">
                          <div className="value">{targetProfile.bitterness}/5</div>
                          <div className="label">{languageFile.secondStep.preferences[2].title}</div>
                        </div>
                        <div className="flavor-value_item">
                          <div className="value">{targetProfile.body}/5</div>
                          <div className="label">{languageFile.secondStep.preferences[3].title}</div>
                        </div>
                      </div>
                      <div className="flavors-chart">
                        <SpiderChart chartData={targetProfile} lang={language}/>
                      </div>
                    </div>
                  </div>
                  <div className="tastes">
                    <div className="blend-sidebar_title">
                      <div className="title">{languageFile.sixthStep.tasteNoteTitle}</div>
                      <hr></hr>
                    </div>
                    <div className="notes-wrapper small">
                      <div className="notes-wrapper_inner">
                        <div className="notes-items">
                          <div className="notes-item">
                            <label className="notes-item_label">
                              <input type="checkbox" name="taste_notes[]" checked={targetProfile.tasteNotes.includes(2)} onChange={() => handleTasteNoteChange(2)} value="spices_roasted"/>
                              <span className="label-value">
                                <span className="label-bg">
                                  <img src={leftTopNoteBg} alt=""/>
                                  <img src={leftTopNoteBgActive} alt="" className="active"/>
                                </span>
                                <span className="label-icon">
                                  <img src={leftTopNoteIcon} alt=""/>
                                  <img src={leftTopNoteIconActive} alt="" className="active"/>
                                </span>
                                <span className="label-title">{languageFile.thirdStep.notes[0].title}</span>
                                <span className="label-flavors op-0">
                                  {
                                    flavors0.map((el, index) => {
                                      return (
                                        <span className="label-flavor_item" key={index}>
                                          <span className="icon">
                                            <img src={el.icon} alt=""/>
                                          </span>
                                          <span className="title">{languageFile.thirdStep.notes[0].tastes[index]}</span>
                                        </span>
                                      )
                                    })
                                  }
                                </span>
                              </span>
                            </label>
                          </div>
                          <div className="notes-item">
                            <label className="notes-item_label">
                              <input type="checkbox" name="taste_notes[]" checked={targetProfile.tasteNotes.includes(3)} onChange={() => handleTasteNoteChange(3)} value="sweet_floral"/>
                              <span className="label-value top-right">
                                <span className="label-bg">
                                  <img src={rightTopNoteBg} alt=""/>
                                  <img src={rightTopNoteBgActive} alt="" className="active"/>
                                </span>
                                <span className="label-icon">
                                  <img src={rightTopNoteIcon} alt=""/>
                                  <img src={rightTopNoteIconActive} alt="" className="active"/>
                                </span>
                                <span className="label-title">{languageFile.thirdStep.notes[1].title}</span>
                                <span className="label-flavors op-0">
                                  {
                                    flavors1.map((el, index) => {
                                      return (
                                        <span className="label-flavor_item" key={index}>
                                          <span className="icon">
                                            <img src={el.icon} alt=""/>
                                          </span>
                                          <span className="title">{languageFile.thirdStep.notes[1].tastes[index]}</span>
                                        </span>
                                      )
                                    })
                                  }
                                </span>
                              </span>
                            </label>
                          </div>
                          <div className="notes-item">
                            <label className="notes-item_label">
                              <input type="checkbox" name="taste_notes[]" checked={targetProfile.tasteNotes.includes(0)} onChange={() => handleTasteNoteChange(0)} value="fruity_citrus"/>
                              <span className="label-value bottom-left">
                                <span className="label-bg">
                                  <img src={leftBottomNoteBg} alt=""/>
                                  <img src={leftBottomNoteBgActive} alt="" className="active"/>
                                </span>
                                <span className="label-icon">
                                  <img src={leftBottomNoteIcon} alt=""/>
                                  <img src={leftBottomNoteIconActive} alt="" className="active"/>
                                </span>
                                <span className="label-title">{languageFile.thirdStep.notes[2].title}</span>
                                <span className="label-flavors op-0">
                                  {
                                    flavors2.map((el, index) => {
                                      return (
                                        <span className="label-flavor_item" key={index}>
                                          <span className="icon">
                                            <img src={el.icon} alt=""/>
                                          </span>
                                          <span className="title">{languageFile.thirdStep.notes[2].tastes[index]}</span>
                                        </span>
                                      )
                                    })
                                  }
                                </span>
                              </span>
                            </label>
                          </div>
                          <div className="notes-item">
                            <label className="notes-item_label">
                              <input type="checkbox" name="taste_notes[]" checked={targetProfile.tasteNotes.includes(1)} onChange={() => handleTasteNoteChange(1)} value="chocolaty_nuts"/>
                              <span className="label-value bottom-right">
                                <span className="label-bg">
                                  <img src={rightBottomNoteBg} alt=""/>
                                  <img src={rightBottomNoteBgActive} alt="" className="active"/>
                                </span>
                                <span className="label-icon">
                                  <img src={rightBottomNoteIcon} alt=""/>
                                  <img src={rightBottomNoteIconActive} alt="" className="active"/>
                                </span>
                                <span className="label-title chocolate">{languageFile.thirdStep.notes[3].title}</span>
                                <span className="label-flavors op-0">
                                  {
                                    flavors3.map((el, index) => {
                                      return (
                                        <span className="label-flavor_item" key={index}>
                                          <span className="icon">
                                            <img src={el.icon} alt=""/>
                                          </span>
                                          <span className="title">{languageFile.thirdStep.notes[3].tastes[index]}</span>
                                        </span>
                                      )
                                    })
                                  }
                                </span>
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="float-flavors">
                        {
                          [0,1,2,3].map((item, index) => {
                            if (targetProfile.tasteNotes.includes(item)) {
                              let iconsArray = flavors2;
                              let iconsTitle = 2;
                              if (1 === item) {
                                iconsArray = flavors3;
                                iconsTitle = 3;
                              } else if (2 === item) {
                                iconsArray = flavors0;
                                iconsTitle = 0;
                              } else if (3 === item) {
                                iconsArray = flavors1;
                                iconsTitle = 1
                              }
                              return(
                                <div className="label-flavors" key={index}>
                                  <div className="flavours-title">{languageFile.thirdStep.notes[iconsTitle].title}</div>
                                  {
                                    iconsArray.slice(0,5).map((el, index) => {
                                      return (
                                        <span className="label-flavor_item" key={index}>
                                        <span className="icon">
                                          <img src={el.icon} alt=""/>
                                        </span>
                                        <span className="title">{languageFile.thirdStep.notes[iconsTitle].tastes[index]}</span>
                                      </span>
                                      )
                                    })
                                  }
                                </div>
                              )
                            } else {
                              return null;
                            }
                          })
                        }
                      </div>
                    </div>
                  </div>
                  <div className="close-coffee-id">
                    <button className="close-coffee-id-btn" onClick={closeCoffeeID}>
                      <img src={closeIconBlack} alt=""/>
                      סגור
                    </button>
                  </div>
                </div>
                <div className="blend-wrapper_right">
                  <div className="editor-wrapper">
                    <div className="editor-top">
                      <div className="buttons multiple">
                        <button className="thm-btn icon" onClick={resetSelection}>
                          <img src={reloadIcon} alt=""/>
                          {languageFile.resetBtn}
                        </button>
                        <button className="thm-btn icon surprise" onClick={showNextBlend} data-next={surpriseStep}>
                          <img src={surpriseIcon} alt=""/>
                          {languageFile.surpriseBtn}
                        </button>
                        <button className="thm-btn icon profile" onClick={openCoffeeID}>
                          <img src={profileIcon} alt=""/>
                          {languageFile.profileBtn}
                        </button>
                      </div>
                      <div className="percentage">
                        <div className="title">{languageFile.editor.matchPercentageTitle}:</div>

                        <div className={percentageClass}>
                          <div className="percentage-progress" style={{width: currentMatchPercentage+'%'}}></div>
                          <strong>{currentMatchPercentage}%</strong>
                        </div>
                      </div>
                    </div>
                    <div className="editor-blocks_wrapper">
                      <div className="editor-blocks">
                        <div className="editor-block_item select-item">
                          <div className="tooltip-cover mobile">
                            <div className="tooltip-cover_wrapper">
                              <div className="tooltip-icon">
                                <img src={tooltipIcon} alt=""/>
                              </div>
                              <div className="tooltip-text">
                                <p dangerouslySetInnerHTML={{__html: languageFile.editor.selectCoffeeBlendTooltip.text}}></p>
                              </div>
                              <button className="close-tooltip" onClick={hideTooltipMobile}>
                                <img src={closeIcon} alt=""/>
                              </button>
                            </div>
                          </div>
                          <div className="editor-item_inner editable">
                            <div className="tooltip" onClick={showTooltipMobile}>
                              <div className="icon">
                                <img src={tooltipIconGrey} alt=""/>
                              </div>
                            </div>
                            <div className="tooltip-cover">
                              <div className="tooltip-cover_wrapper">
                                <div className="tooltip-icon">
                                  <img src={tooltipIcon} alt=""/>
                                </div>
                                <div className="tooltip-text">
                                  <p dangerouslySetInnerHTML={{__html: languageFile.editor.selectCoffeeBlendTooltip.text}}></p>
                                </div>
                                <button className="close-tooltip">
                                  <img src={closeIcon} alt=""/>
                                </button>
                              </div>
                            </div>
                            <div className="editor-title">{languageFile.editor.selectCoffeeBlend}</div>
                            <div className="blend-dropdowns">
                              {Object.keys(currentSelections.blendArray).map((key, index) => (
                                <div key={index} className="blend-dropdown_item">
                                  <div className="default-dropdowns">
                                    <label htmlFor={key} className="">{key}:</label>
                                    <select
                                      id={key}
                                      className=""
                                      value={currentSelections.blendArray[key]}
                                      onChange={(e) => handleSelectionChange(e, key)}
                                    >
                                      {generateOptions(key, totalPercentage, currentSelections, excludedCoffees)}
                                    </select>
                                    <label className="">Exclude</label>
                                  </div>
                                  <div className="custom-dropdowns">
                                    <div className="label">{coffeeBlends[key]}</div>
                                    <div className="custom-dropdown">
                                      <div className="custom-dropdown_wrapper">
                                        <button className="current-item" onClick={openCustomDropdown}>
                                          <img src={removedIcon} alt=""/>
                                          {currentSelections.blendArray[key]}%
                                        </button>
                                        <div className="dropdown-list">
                                          <div className="dropdown-item">
                                            <label className="dropdown-item_btn exclude-btn">
                                              <input type="checkbox" className=""
                                                 id={key + "Exclude"} // Updated ID for accessibility
                                                 value={key}
                                                 onChange={(e) => handleExclusionChange(e)}
                                                 checked={excludedCoffees.includes(key)}
                                              />
                                              <span className="name">לא מעוניין</span>
                                            </label>
                                          </div>
                                          {
                                            [0,10,20,30,40,50,60,70,80,90,100].map((el, index) => {
                                              // let isExcluded = (excludedCoffees, key) =>{
                                              //   return excludedCoffees.includes(key)
                                              // }
                                              let maxOptionValue = 100 - totalPercentage + Number(currentSelections.blendArray[key]);
                                              return(
                                                <div key={index} className="dropdown-item">
                                                  <button className={el === currentSelections.blendArray[key] ? "dropdown-item_btn selected" : "dropdown-item_btn"} disabled={(el > maxOptionValue)} onClick={(e) => handleSelectionChangeNew(e, key)}>{el}%</button>
                                                </div>
                                              )
                                            })
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className={0 === percentageLeft ? 'percentage-left op-0' : 'percentage-left'}>
                              <div className="value">חסר עוד <span>{percentageLeft}%</span></div>
                            </div>
                          </div>
                        </div>
                        <div className="editor-block_item chart-item">
                          <div className="tooltip-cover mobile">
                            <div className="tooltip-cover_wrapper">
                              <div className="tooltip-icon">
                                <img src={tooltipIcon} alt=""/>
                              </div>
                              <div className="tooltip-title">{languageFile.editor.tasteProfileTooltip.title}</div>
                              <div className="tooltip-text">
                                <p>{languageFile.editor.tasteProfileTooltip.text}</p>
                              </div>
                              <button className="close-tooltip" onClick={hideTooltipMobile}>
                                <img src={closeIcon} alt=""/>
                              </button>
                            </div>
                          </div>
                          <div className="editor-item_inner">
                            <div className="tooltip" onClick={showTooltipMobile}>
                              <div className="icon">
                                <img src={tooltipIconGrey} alt=""/>
                              </div>
                            </div>
                            <div className="tooltip-cover">
                              <div className="tooltip-cover_wrapper">
                                <div className="tooltip-icon">
                                  <img src={tooltipIcon} alt=""/>
                                </div>
                                <div className="tooltip-title">{languageFile.editor.tasteProfileTooltip.title}</div>
                                <div className="tooltip-text">
                                  <p>{languageFile.editor.tasteProfileTooltip.text}</p>
                                </div>
                                <button className="close-tooltip">
                                  <img src={closeIcon} alt=""/>
                                </button>
                              </div>
                            </div>
                            <div className="editor-title">{languageFile.editor.tasteProfile}</div>
                            <div className="taste-profile">
                              <SpiderChart chartData={targetProfile} multiple={currentProfilePreferences} lang={language}/>
                            </div>
                          </div>
                        </div>
                        <div className="editor-block_item roast-item">
                          <div className="tooltip-cover mobile">
                            <div className="tooltip-cover_wrapper">
                              <div className="tooltip-icon">
                                <img src={tooltipIcon} alt=""/>
                              </div>
                              <div className="tooltip-title">{languageFile.editor.selectRoastLevelTooltip.title}</div>
                              <div className="tooltip-text">
                                <p>{languageFile.editor.selectRoastLevelTooltip.text}</p>
                              </div>
                              <button className="close-tooltip" onClick={hideTooltipMobile}>
                                <img src={closeIcon} alt=""/>
                              </button>
                            </div>
                          </div>
                          <div className="editor-item_inner editable">
                            <div className="tooltip" onClick={showTooltipMobile}>
                              <div className="icon">
                                <img src={tooltipIconGrey} alt=""/>
                              </div>
                            </div>
                            <div className="tooltip-cover">
                              <div className="tooltip-cover_wrapper">
                                <div className="tooltip-icon">
                                  <img src={tooltipIcon} alt=""/>
                                </div>
                                <div className="tooltip-title">{languageFile.editor.selectRoastLevelTooltip.title}</div>
                                <div className="tooltip-text">
                                  <p>{languageFile.editor.selectRoastLevelTooltip.text}</p>
                                </div>
                                <button className="close-tooltip">
                                  <img src={closeIcon} alt=""/>
                                </button>
                              </div>
                            </div>
                            <div className="editor-title">{languageFile.editor.selectRoastLevel}</div>
                            <div className="roasting-level">
                              <select value={currentSelections.roastLevel} onChange={handleRoastChange} className="">
                                {[1, 2, 3].map(level => (
                                  <option key={level} value={level}>{level}</option>
                                ))}
                              </select>
                              <div className="slider-wrapper">
                                <div className="labels">
                                  <div className="label-item">כהה</div>
                                  <div className="label-item">בינונית</div>
                                  <div className="label-item">בהירה</div>
                                </div>
                                <RangeSlider
                                  className="roasting_level"
                                  min={1}
                                  max={3}
                                  step={0.01}
                                  thumbsDisabled={[true, false]}
                                  rangeSlideDisabled={false}
                                  value={[1, roastLevelRangeValue]}
                                  //defaultValue={[1, currentSelections.roastLevel]}
                                  onInput={handleRoastChangeNew}
                                />
                              </div>
                              <div className="roasting-level_label">
                                {roatingLevels[(currentSelections.roastLevel)-1]} <span>(Agtron # 33)</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="editor-block_item notes-item">
                          <div className="tooltip-cover mobile">
                            <div className="tooltip-cover_wrapper">
                              <div className="tooltip-icon">
                                <img src={tooltipIcon} alt=""/>
                              </div>
                              <div className="tooltip-title">{languageFile.editor.tastesNotesTooltip.title}</div>
                              <div className="tooltip-text">
                                <p>{languageFile.editor.tastesNotesTooltip.text}</p>
                              </div>
                              <button className="close-tooltip" onClick={hideTooltipMobile}>
                                <img src={closeIcon} alt=""/>
                              </button>
                            </div>
                          </div>
                          <div className="editor-item_inner">
                            <div className="tooltip" onClick={showTooltipMobile}>
                              <div className="icon">
                                <img src={tooltipIconGrey} alt=""/>
                              </div>
                            </div>
                            <div className="tooltip-cover">
                              <div className="tooltip-cover_wrapper">
                                <div className="tooltip-icon">
                                  <img src={tooltipIcon} alt=""/>
                                </div>
                                <div className="tooltip-title">{languageFile.editor.tastesNotesTooltip.title}</div>
                                <div className="tooltip-text">
                                  <p>{languageFile.editor.tastesNotesTooltip.text}</p>
                                </div>
                                <button className="close-tooltip">
                                  <img src={closeIcon} alt=""/>
                                </button>
                              </div>
                            </div>
                            <div className="editor-title">{languageFile.editor.tastesNotes}</div>
                            <div className="notes-wrapper small active">
                              <div className="notes-wrapper_inner">
                                <div className="notes-items">
                                  <div className="notes-item">
                                    <label className="notes-item_label">
                                      <input type="checkbox" name="taste_notes[]" checked={currentTasteNotes ? (currentTasteNotes['spices and roasted'] ? true : false) : false} onChange={() => handleTasteNoteChange(2)} value="spices_roasted"/>
                                      <span className="label-value">
                                        <span className="label-bg">
                                          <img src={leftTopNoteBg} alt=""/>
                                          <img src={leftTopNoteBgActive} alt="" className="active"/>
                                        </span>
                                        <span className="label-icon">
                                          <img src={leftTopNoteIcon} alt=""/>
                                          <img src={leftTopNoteIconActive} alt="" className="active"/>
                                        </span>
                                        <span className="label-title">{languageFile.thirdStep.notes[0].title}</span>
                                        <span className="label-flavors op-0">
                                          {
                                            flavors0.map((el, index) => {
                                              return (
                                                <span className="label-flavor_item" key={index}>
                                                  <span className="icon">
                                                    <img src={el.icon} alt=""/>
                                                  </span>
                                                  <span className="title">{languageFile.thirdStep.notes[0].tastes[index]}</span>
                                                </span>
                                              )
                                            })
                                          }
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                  <div className="notes-item">
                                    <label className="notes-item_label">
                                      <input type="checkbox" name="taste_notes[]" checked={currentTasteNotes ? (currentTasteNotes['sweet and floral'] ? true : false) : false} onChange={() => handleTasteNoteChange(3)} value="sweet_floral"/>
                                      <span className="label-value top-right">
                                        <span className="label-bg">
                                          <img src={rightTopNoteBg} alt=""/>
                                          <img src={rightTopNoteBgActive} alt="" className="active"/>
                                        </span>
                                        <span className="label-icon">
                                          <img src={rightTopNoteIcon} alt=""/>
                                          <img src={rightTopNoteIconActive} alt="" className="active"/>
                                        </span>
                                        <span className="label-title">{languageFile.thirdStep.notes[1].title}</span>
                                        <span className="label-flavors op-0">
                                          {
                                            flavors1.map((el, index) => {
                                              return (
                                                <span className="label-flavor_item" key={index}>
                                                  <span className="icon">
                                                    <img src={el.icon} alt=""/>
                                                  </span>
                                                  <span className="title">{languageFile.thirdStep.notes[1].tastes[index]}</span>
                                                </span>
                                              )
                                            })
                                          }
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                  <div className="notes-item">
                                    <label className="notes-item_label">
                                      <input type="checkbox" name="taste_notes[]" checked={currentTasteNotes ? (currentTasteNotes['fruity and citrus'] ? true : false) : false} onChange={() => handleTasteNoteChange(0)} value="fruity_citrus"/>
                                      <span className="label-value bottom-left">
                                        <span className="label-bg">
                                          <img src={leftBottomNoteBg} alt=""/>
                                          <img src={leftBottomNoteBgActive} alt="" className="active"/>
                                        </span>
                                        <span className="label-icon">
                                          <img src={leftBottomNoteIcon} alt=""/>
                                          <img src={leftBottomNoteIconActive} alt="" className="active"/>
                                        </span>
                                        <span className="label-title">{languageFile.thirdStep.notes[2].title}</span>
                                        <span className="label-flavors op-0">
                                          {
                                            flavors2.map((el, index) => {
                                              return (
                                                <span className="label-flavor_item" key={index}>
                                                  <span className="icon">
                                                    <img src={el.icon} alt=""/>
                                                  </span>
                                                  <span className="title">{languageFile.thirdStep.notes[2].tastes[index]}</span>
                                                </span>
                                              )
                                            })
                                          }
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                  <div className="notes-item">
                                    <label className="notes-item_label">
                                      <input type="checkbox" name="taste_notes[]" checked={currentTasteNotes ? (currentTasteNotes['chocolaty and nuts'] ? true : false) : false} onChange={() => handleTasteNoteChange(1)} value="chocolaty_nuts"/>
                                      <span className="label-value bottom-right">
                                        <span className="label-bg">
                                          <img src={rightBottomNoteBg} alt=""/>
                                          <img src={rightBottomNoteBgActive} alt="" className="active"/>
                                        </span>
                                        <span className="label-icon">
                                          <img src={rightBottomNoteIcon} alt=""/>
                                          <img src={rightBottomNoteIconActive} alt="" className="active"/>
                                        </span>
                                        <span className="label-title chocolate">{languageFile.thirdStep.notes[3].title}</span>
                                        <span className="label-flavors op-0">
                                          {
                                            flavors3.map((el, index) => {
                                              return (
                                                <span className="label-flavor_item" key={index}>
                                                  <span className="icon">
                                                    <img src={el.icon} alt=""/>
                                                  </span>
                                                  <span className="title">{languageFile.thirdStep.notes[3].tastes[index]}</span>
                                                </span>
                                              )
                                            })
                                          }
                                        </span>
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="float-flavors dynamic">
                                {
                                  notesDefaultArr.map((item, index) => {
                                    let itemIndex = 0;
                                    let familyIcons = flavors0;
                                    let labelClass = "label-flavors "+item.replaceAll(' ', '_');
                                    if (!Object.keys(currentTasteNotes ? currentTasteNotes : {}).includes(item)) {
                                      labelClass += ' empty';
                                    }
                                    if ('sweet and floral' === item) {
                                      itemIndex = 1;
                                      familyIcons = flavors1;
                                    } else if ('fruity and citrus' === item) {
                                      itemIndex = 2;
                                      familyIcons = flavors2;
                                    } else if ('chocolaty and nuts' === item) {
                                      itemIndex = 3;
                                      familyIcons = flavors3;
                                    }
                                    let familyTitle = languageFile.thirdStep.notes[itemIndex].title;
                                    let familyNotes = languageFile.thirdStep.notes[itemIndex].tastes;
                                    if (Object.keys(currentTasteNotes ? currentTasteNotes : {}).includes(item)) {
                                      return(
                                        <div className={labelClass} key={index}>
                                          <div className="flavours-title">{familyTitle}</div>
                                          {
                                            currentTasteNotes[item].map((elI, indexI) => {
                                              let iconUrl = familyIcons[0];
                                              let iconIndexOf = familyNotes.indexOf(elI);
                                              if (iconIndexOf !== -1) {
                                                iconUrl = familyIcons[iconIndexOf];
                                              }
                                              return (
                                                <span className="label-flavor_item" key={indexI}>
                                                <span className="icon">
                                                  <img src={iconUrl.icon} alt=""/>
                                                </span>
                                                <span className="title">{elI}</span>
                                              </span>
                                              )
                                            })
                                          }
                                        </div>
                                      )
                                    } else {
                                      return(
                                        <div className={labelClass} key={index}>
                                          <img src={agroIcon} alt=""/>
                                        </div>
                                      )
                                    }
                                  })
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="step-footer">
                <div className="buttons multiple">
                  <button className="thm-btn bordered"
                          onClick={() => {
                            navigate('/', {state: {targetProfile, bestBlendsArr, bestBlends}});
                          }}
                  >{languageFile.backBtn}</button>
                  <button className={roastBtnStatusDisabled ? 'disabled thm-btn' : 'thm-btn'} onClick={openRoastPopup}>{languageFile.roastMeBtn}</button>
                </div>
              </div>
              <div className="blend-assistant">
                <button className="assistant-photo" onClick={showAssistant}>
                  <span className="image">
                    <img src={photo} alt=""/>
                  </span>
                  <span className="name">{languageFile.assistant.name}</span>
                </button>
                <div className="assistant-quote">
                  <div className="quote-text">
                    <button className="close-quote" onClick={closeAssistant}>
                      <img src={closeIcon} alt=""/>
                    </button>
                    <p>
                      <span dangerouslySetInnerHTML={{__html: assistantText}}></span>
                    </p>
                    <div className="buttons multiple hidden">
                      <button className="thm-btn" onClick={showSuggestion}>כן, מתאים לי</button>
                      <button className="thm-btn simple" onClick={closeAssistant}>לא, תודה</button>
                    </div>
                  </div>
                  <button className="mute-assistant" onClick={muteAssistant}>
                    <img src={muteIcon} alt=""/>
                    <span className="name">{muteLabel}</span>
                  </button>
                  <div className="icon">
                    <img src={quoteIcon} alt=""/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RoastMeModal translations={languageFile} targetProfile={targetProfile} currentSelections={currentSelections} language={language} currentProfilePreferences={currentProfilePreferences} currentTasteNotes={currentTasteNotes} currentMatchPercentage={currentMatchPercentage} roastLevelRangeValue={roastLevelRangeValue}/>
      </div>
    </>
  );

};

export default EditorNew