import React, {useEffect, useState} from 'react';
// import Card from "../UI/Card";
// import BlendCard from "../UI/BlendCard";
// import {InputGroup} from "../UI/InputGroup";
import {
  calculateBlendsByProfile,
  coffeeTypes,
} from "../utils/functionsUtils";
import {
  checkAndMakeMixWithAtLeastTwoTypesIfExist,
  checkAndMakeMixWithoutRobustaIfExist,
  checkAndMakeMixWithTasteNotesIfExist, findBlendWithoutOneRule
} from "../utils/RulesChecking";
import {useNavigate, useLocation} from "react-router-dom";

/******/
import pageBG from '../images/page_bg-min.png';
import logo from '../images/logo_latest.png';
import coffeeType1 from '../images/coffee_types/coffee_type_1.png';
import coffeeType2 from '../images/coffee_types/coffee_type_2.png';
import coffeeType3 from '../images/coffee_types/coffee_type_3.png';
import coffeeType4 from '../images/coffee_types/coffee_type_4.png';
import coffeeType5 from '../images/coffee_types/coffee_type_5.png';
import coffeeType6 from '../images/coffee_types/coffee_type_6.png';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import tooltipIcon from '../images/tooltip_icon.svg';
import tooltipIconGrey from '../images/tooltip_icon_grey.svg';
import tooltipIconWhite from '../images/tooltip_icon_white.svg';
import leftTopNoteBg from '../images/taste_notes/left_top.png';
import leftTopNoteBgActive from '../images/taste_notes/left_top_active.png';
import leftTopNoteIcon from '../images/taste_notes/left_top_icon.svg';
import leftTopNoteIconActive from '../images/taste_notes/left_top_icon_active.svg';
import rightTopNoteBg from '../images/taste_notes/right_top.png';
import rightTopNoteBgActive from '../images/taste_notes/right_top_active.png';
import rightTopNoteIcon from '../images/taste_notes/right_top_icon.svg';
import rightTopNoteIconActive from '../images/taste_notes/right_top_icon_active.svg';
import leftBottomNoteBg from '../images/taste_notes/left_bottom.png';
import leftBottomNoteBgActive from '../images/taste_notes/left_bottom_active.png';
import leftBottomNoteIcon from '../images/taste_notes/left_bottom_icon.svg';
import leftBottomNoteIconActive from '../images/taste_notes/left_bottom_icon_active.svg';
import rightBottomNoteBg from '../images/taste_notes/right_bottom.png';
import rightBottomNoteBgActive from '../images/taste_notes/right_bottom_active.png';
import rightBottomNoteIcon from '../images/taste_notes/right_bottom_icon.svg';
import rightBottomNoteIconActive from '../images/taste_notes/right_bottom_icon_active.svg';
import navIcon1 from "../images/nav_icons/mobile_nav_1.png";
import navIcon2 from "../images/nav_icons/mobile_nav_2.png";
import navIcon3 from "../images/nav_icons/mobile_nav_3.png";
import Loader from "../components/loader";
//flavors
import almondIcon from '../images/taste_notes/flavors/flavor_1.svg';
import almondIconWhite from '../images/taste_notes/flavors_white/flavor_1.svg';
import roastedAlmondIcon from '../images/taste_notes/flavors/agro-icon-brown-16.svg';
import roastedAlmondIconWhite from '../images/taste_notes/flavors_white/agro-icon-brown-16.svg';
import sugarCaneIcon from '../images/taste_notes/flavors/flavor_2.svg';
import sugarCaneIconWhite from '../images/taste_notes/flavors_white/flavor_2.svg';
import spicesIcon from '../images/taste_notes/flavors/flavor_4.svg';
import spicesIconWhite from '../images/taste_notes/flavors_white/flavor_4.svg';
// import cinnamonIcon from '../images/taste_notes/flavors/flavor_5.svg';
import cinnamonIconWhite from '../images/taste_notes/flavors_white/flavor_5.svg';
import peanutsIcon from '../images/taste_notes/flavors/flavor_6.svg';
import peanutsIconWhite from '../images/taste_notes/flavors_white/flavor_6.svg';
import hazelnutIcon from '../images/taste_notes/flavors/flavor_7.svg';
import hazelnutIconWhite from '../images/taste_notes/flavors_white/flavor_7.svg';
// import cocoaIcon from '../images/taste_notes/flavors/flavor_8.svg';
import cocoaIconWhite from '../images/taste_notes/flavors_white/flavor_8.svg';
import chocolateIcon from '../images/taste_notes/flavors/flavor_9.svg';
import chocolateIconWhite from '../images/taste_notes/flavors_white/flavor_9.svg';
import herbsIcon from '../images/taste_notes/flavors/agro-icon-brown-7.svg';
import herbsIconWhite from '../images/taste_notes/flavors_white/agro-icon-brown-7.svg';
import cloveIcon from '../images/taste_notes/flavors/agro-icon-brown-8.svg';
import cloveIconWhite from '../images/taste_notes/flavors_white/agro-icon-brown-8.svg';
import lemonGrassIcon from '../images/taste_notes/flavors/agro-icon-brown-9.svg';
import lemonGrassIconWhite from '../images/taste_notes/flavors_white/agro-icon-brown-9.svg';
import vanillaIcon from '../images/taste_notes/flavors/agro-icon-brown-25.svg';
import vanillaIconWhite from '../images/taste_notes/flavors_white/agro-icon-brown-25.svg';
import brownSugarIcon from '../images/taste_notes/flavors/agro-icon-brown-14.svg';
import brownSugarIconWhite from '../images/taste_notes/flavors_white/agro-icon-brown-14.svg';
import hibiscusIcon from '../images/taste_notes/flavors/agro-icon-brown-17.svg';
import hibiscusIconWhite from '../images/taste_notes/flavors_white/agro-icon-brown-17.svg';
// import honeyIcon from '../images/taste_notes/flavors/agro-icon-brown-1.svg';
import honeyIconWhite from '../images/taste_notes/flavors_white/agro-icon-brown-1.svg';
import appleIcon from '../images/taste_notes/flavors/agro-icon-brown-24.svg';
import appleIconWhite from '../images/taste_notes/flavors_white/agro-icon-brown-24.svg';
import mangoIcon from '../images/taste_notes/flavors/agro-icon-brown-26.svg';
import mangoIconWhite from '../images/taste_notes/flavors_white/agro-icon-brown-26.svg';
import orangeIcon from '../images/taste_notes/flavors/agro-icon-brown-10.svg';
import orangeIconWhite from '../images/taste_notes/flavors_white/agro-icon-brown-10.svg';
import brownButterIcon from '../images/taste_notes/flavors/agro-icon-brown-21.svg';
import brownButterIconWhite from '../images/taste_notes/flavors_white/agro-icon-brown-21.svg';
// import milkChocolateIcon from '../images/taste_notes/flavors/agro-icon-brown-4.svg';
import milkChocolateIconWhite from '../images/taste_notes/flavors_white/agro-icon-brown-4.svg';
// import whiteChocolateIcon from '../images/taste_notes/flavors/agro-icon-brown-19.svg';
import whiteChocolateIconWhite from '../images/taste_notes/flavors_white/agro-icon-brown-19.svg';
import mapleIconWhite from '../images/taste_notes/flavors_white/maple_icon_white.svg';
import licoriceIconWhite from '../images/taste_notes/flavors_white/licorice_icon_white.svg';
import melonIconWhite from '../images/taste_notes/flavors_white/melon_icon_white.svg';
import blueberriesIcon from '../images/taste_notes/flavors/blueberry-icon.svg';
import caramelIcon from '../images/taste_notes/flavors/agro-icon-brown-14.svg';
//other
import reloadIcon from '../images/reload_icon.svg';
// import Assistant from "../components/assistant";
import photo from '../images/assistant/photo.png';
import quoteIcon from '../images/assistant/quote_icon.svg';
import muteIcon from "../images/assistant/mute_icon.svg";
import SpiderChart from "../components/charts/radar";
// import createBlendImage from '../images/create_your_own_blend.png';
import createBlendImage from '../images/create_blend_bg-min.jpg';
import createBlendImageMobile from '../images/create_blend_bg_mobile-min.jpg';
import warningIconReverse from '../images/warning_icon_reverse.png';
// import createBlendImageMiddle from '../images/create_blend_bg_mobile-min.jpg';
//languages
import EN from '../languages/en';
import HE from '../languages/he';
import BuyProductModal from "../components/modals/buyProduct";
import warningIcon from "../images/warning_icon.svg";
import closeIcon from "../images/assistant/close_icon.svg";
import getCredentials from "../scripts/getCredentials";
import axios from "axios";

const UserPreferences = () => {
  // const [targetProfile, setTargetProfile] = useState({
  //   sweetness: 2,
  //   acidity: 3,
  //   bitterness: 2,
  //   body: 3,
  //   tasteNotes: [0, 1],
  //   drinkingType: 2,
  //   howOftenDrink: 2
  // });

  getCredentials();

  // let bestBlendsSetup = [];
  let bestBlendsDefault = [];
  let bestBlendsArrDefault = [];
  let stepDefault = 1;
  let matchPercentDefault = 52;
  let targetProfileArr = {
    sweetness: 1,
    acidity: 1,
    bitterness: 1,
    body: 1,
    tasteNotes: [],
    drinkingType: "",
    howOftenDrink: ""
  };
  // targetProfileArr = {
  //   sweetness: 2,
  //   acidity: 3,
  //   bitterness: 2,
  //   body: 3,
  //   tasteNotes: [0, 1],
  //   drinkingType: 2,
  //   howOftenDrink: 2
  // };

  let location = useLocation();
  if (location.state) {
    targetProfileArr = location.state.targetProfile;
    bestBlendsArrDefault = location.state.bestBlendsArr;
    // console.log("From editor page:", bestBlendsArrDefault);
    stepDefault = 6;
    if (location.state.bestBlends) {
      bestBlendsDefault = location.state.bestBlends;
      if (bestBlendsDefault[0]) {
        matchPercentDefault = bestBlendsDefault[0].matchPercentage;
        // console.log(bestBlendsDefault);
      }
    }
  }

  let calculateObjectDefault = {
    "type": "makineta",
    "tasteNotes": [
      "fruty and citrus",
      "chocolaty and nuts"
    ],
    "preferences": {
      "acidity": 2,
      "sweetness": 3,
      "bitterness": 3,
      "body": 4
    },
    "drinkerType": "Once a day"
  }



  // let bestBlendsArr = [0, 1];
  // setBestBlends(bestBlendsArr);

  const [bestBlendsArr, setBestBlendsArr] = useState(bestBlendsArrDefault);
  const [targetProfile, setTargetProfile] = useState(targetProfileArr);
  const [matchPercent, setMatchPercent] = useState(matchPercentDefault);
  const [calculateObject, setCalculateObject] = useState(calculateObjectDefault);
  const [modalCoffeeInfo, setModalCoffeeInfo] = useState({});

  let navigate = useNavigate();
  const [bestBlends, setBestBlends] = useState(bestBlendsDefault);

  useEffect(() => {
    // console.log("Updated bestBlends:", bestBlends);

  }, [bestBlends]);
  // useEffect(() => {
  //   setBestBlends[bestBlendsSetup];
  //
  // }, [bestBlends]);

  const handleTasteNoteChange = (tasteNote) => {
    setTargetProfile(prevState => {
      // Check if the taste note is already selected
      if (prevState.tasteNotes.includes(tasteNote)) {
        // Remove it if it is
        return {
          ...prevState,
          tasteNotes: prevState.tasteNotes.filter(note => note !== tasteNote)
        };
      } else {
        // Add it if it's not, ensuring we don't exceed two selections
        if (prevState.tasteNotes.length < 2) {
          return {
            ...prevState,
            tasteNotes: [...prevState.tasteNotes, tasteNote]
          };
        } else {
          // Optionally, alert the user or handle max selection limit
          console.log("Maximum of 2 taste notes can be selected.");
          return prevState; // Return previous state if limit is reached
        }
      }
    });

    //calculate selected checkboxes
    let notesCheckboxes = document.querySelectorAll('.active .notes-item_label input[type=checkbox]');
    let notesCount = 0;
    notesCheckboxes.forEach((item) => {
      if (item.checked) {
        notesCount++;
      }
    });
    if (notesCount > 2) {
      notesCount = 2;
    }
    setNotesSelected(notesCount);
  };

  const handleDrinkingTypesChange = (type) => {
    setTargetProfile(prevState => ({
      ...prevState,
      drinkingType: type
    }));
    setCalculateObject(prevState => ({
      ...prevState,
      type: calculateCoffeeTypes[type]
    }));
  };

  const handleHowOftenDrinkChange = (type) => {
    setTargetProfile(prevState => ({
      ...prevState,
      howOftenDrink: type
    }));
    // setCalculateObject(prevState => ({
    //   ...prevState,
    //   drinkerType: calculateCoffeeHowOften[type]
    // }));
    //change tasteNotes
    let localTastes = [];
    targetProfile.tasteNotes.forEach((item) => {
      localTastes.push(calculateCoffeeTastes[item]);
    });
    setCalculateObject(prevState => ({
      ...prevState,
      drinkerType: calculateCoffeeHowOften[type],
      tasteNotes: localTastes,
      preferences: {
        acidity: targetProfile.acidity,
        sweetness: targetProfile.sweetness,
        bitterness: targetProfile.bitterness,
        body: targetProfile.body
      }
    }));
  };

  // let calculateCoffeeTypes = ["french", "black_coffee", "espresso", "europress", "pour_over", "moka_pot"];
  // let calculateCoffeeTypes = ["europress", "espresso", "black_coffee", "makineta", "pour_over", "french"];
  let calculateCoffeeTypes = ["קפה הפוך", "אספרסו", "קפה שחור", "מקינטה", "פילטר", "פרנץ פרס"];
  // let calculateCoffeeTastes = ["fruty and citrus", "chocolaty and nuts", "spices and roasted", "sweet and floral"];
  let calculateCoffeeTastes = ["פירותי ופרי הדר", "שוקולד ואגוזים", "תבלינים וקלוי", "מתוק ופרחי"];
  let calculateCoffeeHowOften = ["Few times a day", "Once a day", "Few times a week"];

  const calculateBestBlend = () => {
    setCurrentStep(5);
    let allBlends = [];

    // console.log(targetProfile);

    //change tasteNotes
    let localTastes = [];
    targetProfile.tasteNotes.forEach((item) => {
      localTastes.push(calculateCoffeeTastes[item]);
    });
    setCalculateObject({
      type: calculateCoffeeTypes[targetProfile.drinkingType],
      tasteNotes: localTastes,
      preferences: {
        acidity: targetProfile.acidity,
        sweetness: targetProfile.sweetness,
        bitterness: targetProfile.bitterness,
        body: targetProfile.body
      }
    });

    coffeeTypes.forEach(coffeeType => {
      allBlends.push(...calculateBlendsByProfile(targetProfile, coffeeType));
    });

    allBlends.sort((a, b) => {
      if (a.matchPercentage > b.matchPercentage) return -1
      if (a.matchPercentage < b.matchPercentage) return 1;

      return b.score - a.score
    })

    let topBlend;

    topBlend = checkAndMakeMixWithTasteNotesIfExist(targetProfile, allBlends[0], allBlends);

    topBlend = checkAndMakeMixWithoutRobustaIfExist(topBlend, allBlends);

    topBlend = checkAndMakeMixWithAtLeastTwoTypesIfExist(topBlend, allBlends)

    if (allBlends) {
      let secondBlend;
      let thirdBlend;

      if (topBlend) {
        secondBlend = findBlendWithoutOneRule(topBlend, allBlends);
      }
      if (secondBlend) {
        thirdBlend = findBlendWithoutOneRule(secondBlend, allBlends);
      }

      setBestBlends([topBlend, secondBlend, thirdBlend].filter(Boolean));
      setMatchPercent(allBlends[0].matchPercentage);
    } else {
      setBestBlends([]);
    }
    setTimeout(function () {
      setCurrentStep(6);
    }, 6000);
  };

  //get best matches
  useEffect(() => {
    if (5 === currentStep) {
      // console.log(calculateObject);
      let tokenValue = localStorage.getItem('nekot');
      let bearerValue = 'Bearer '+tokenValue;
      let data = JSON.stringify(calculateObject);
      // console.log(data);
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://agro-caffe.vercel.app/api/get-best-match?lang=il',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': bearerValue
        },
        data : data
      };
      // console.log(config);
      axios.request(config)
        .then((response) => {
          // console.log(response.data);
          if (response.data.bestMatch) {
            setBestBlendsArr(response.data.bestMatch);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [calculateObject]);

  /*******/
  const [currentStep, setCurrentStep] = useState(stepDefault);
  const [notesSelected, setNotesSelected] = useState(0);
  const [language, setLanguage] = useState('he');
  const [languageFile, setLanguageFile] = useState(HE);
  const [showAssistant, setShowAssistant] = useState(1);
  const [mutedAssistant, setMutedAssistant] = useState(0);
  const [muteLabel, setMuteLabel] = useState('השתק');

  let progressWidth = (currentStep * 14.28) + '%';

  function stepNavigation(e) {
    e.preventDefault();
    let dataStep = parseInt(e.target.getAttribute('data-step'));
    // //step 1 validation
    // if (2 === dataStep) {
    //   let types = document.querySelectorAll('input[name=drinkingType]');
    //   let typesValue = 0;
    //   types.forEach((item) => {
    //     if (item.checked) {
    //       typesValue++;
    //     }
    //   });
    //   if (1 === typesValue) {
    //     setCurrentStep(dataStep)
    //   }
    // } else {
    //   setCurrentStep(dataStep);
    // }
    setCurrentStep(dataStep);
  }

  let coffeeTypesMy = [
    {icon: coffeeType4},
    {icon: coffeeType3},
    {icon: coffeeType2},
    {icon: coffeeType6},
    {icon: coffeeType5},
    {icon: coffeeType1}
  ];

  function changeAcidityInput(e) {
    document.querySelector('.acidity .range-slider__thumb[data-upper=true]').innerHTML = Math.round(e[1]).toString();
    setTargetProfile({...targetProfile, acidity: Math.round(e[1])});
  }

  function changeSweetnessInput(e) {
    document.querySelector('.sweetness .range-slider__thumb[data-upper=true]').innerHTML = Math.round(e[1]).toString();
    setTargetProfile({...targetProfile, sweetness: Math.round(e[1])});
  }

  function changeBitternessInput(e) {
    document.querySelector('.bitterness .range-slider__thumb[data-upper=true]').innerHTML = Math.round(e[1]).toString();
    setTargetProfile({...targetProfile, bitterness: Math.round(e[1])});
  }

  function changeBodyInput(e) {
    document.querySelector('.body .range-slider__thumb[data-upper=true]').innerHTML = Math.round(e[1]).toString();
    setTargetProfile({...targetProfile, body: Math.round(e[1])});
  }

  let flavors0 = [
    {icon: roastedAlmondIcon},
    {icon: sugarCaneIcon},
    {icon: herbsIcon},
    {icon: cloveIcon},
    {icon: spicesIcon},
    // {icon: cinnamonIcon},
    // {icon: lemonGrassIcon}
  ];

  let flavors1 = [
    {icon: herbsIcon},
    {icon: vanillaIcon},
    {icon: brownSugarIcon},
    {icon: hibiscusIcon},
    {icon: caramelIcon},
    // {icon: honeyIcon}
  ];

  let flavors2 = [
    {icon: appleIcon},
    {icon: mangoIcon},
    {icon: lemonGrassIcon},
    {icon: blueberriesIcon},
    {icon: orangeIcon},
    // {icon: orangeIcon},
    // {icon: mangoIcon}
  ];

  let flavors3 = [
    {icon: brownButterIcon},
    {icon: peanutsIcon},
    {icon: hazelnutIcon},
    {icon: chocolateIcon},
    {icon: almondIcon},
    // {icon: milkChocolateIcon},
    // {icon: cocoaIcon},
    // {icon: whiteChocolateIcon}
  ];

  function changePreferences(e){
    e.preventDefault();
    setCurrentStep(1);
  }

  let languages = ['en', 'he'];

  function changeLanguage(e){
    e.preventDefault();
    let selectedLang = e.target.getAttribute('data-lang');
    setLanguage(selectedLang);
    if ('en' === selectedLang) {
      setLanguageFile(EN);
    } else {
      setLanguageFile(HE);
    }
  }

  let step2Validation = targetProfile.acidity + targetProfile.sweetness + targetProfile.bitterness + targetProfile.body;

  if (2 === currentStep) {
    document.querySelector('.acidity .range-slider__thumb[data-upper=true]').innerHTML = targetProfile.acidity;
    document.querySelector('.sweetness .range-slider__thumb[data-upper=true]').innerHTML = targetProfile.sweetness;
    document.querySelector('.bitterness .range-slider__thumb[data-upper=true]').innerHTML = targetProfile.bitterness;
    document.querySelector('.body .range-slider__thumb[data-upper=true]').innerHTML = targetProfile.body;
  } else if (6 === currentStep) {
    setTimeout(() => {
      if (1 === showAssistant) {
        document.querySelector('.step-item.active').classList.add('assistant-open');
        setShowAssistant(0);
      }
    }, 1000)
  }

  useEffect(() => {
    document.querySelector('.step-item_wrapper .before').addEventListener('click', (e) => {
      if (document.querySelector('.step-item.active').classList.contains('assistant-open')) {
        let clickedObject = e.target;
        if (clickedObject.closest('.blend-assistant') === null) {
          document.querySelector('.step-item.active').classList.remove('assistant-open');
        }
      }
    })
  }, []);

  function showTooltip(e) {
    e.target.closest('.range-item').querySelector('.tooltip').classList.add('hover');
  }
  function hideTooltip(e) {
    e.target.closest('.range-item').querySelector('.tooltip').classList.remove('hover');
  }

  // console.log(bestBlendsArr);

  function openBuyPopup(e){
    let modalTarget = parseInt(e.target.getAttribute('data-target'));
    setModalCoffeeInfo(bestBlendsArr[modalTarget]);
    document.getElementById('buy-product').classList.add('open')
  }

  function mobileNav(e){
    e.preventDefault();
    // console.log(e.currentTarget);
    let currentBlock = e.currentTarget.getAttribute('data-target');
    document.querySelector('.blend-wrapper .blend-wrapper').classList.remove('identify', 'matches', 'editor');
    document.querySelector('.blend-wrapper .blend-wrapper').classList.add(currentBlock);
    document.querySelectorAll('.nav-button').forEach((btn) => {
      btn.classList.remove('active');
    });
    e.currentTarget.classList.add('active');
    document.querySelector('.float-buttons').classList.remove('matches');
    if ('matches' === currentBlock) {
      document.querySelector('.nav-button.matches').classList.add('active');
      document.querySelector('.float-buttons').classList.add('matches');
    }
  }

  // let tastesIconGlossary = [
  //   {en: "brown butter", he: "חמאה חומה", icon: brownButterIcon},
  //   {en: "peanuts", he: "בוטנים", icon: peanutsIcon},
  //   {en: "hazelnut", he: "אגוזי לוז", icon: hazelnutIcon},
  //   {en: "dark choclate", he: "שוקולד מריר", icon: chocolateIcon},
  //   {en: "almond", he: "שקד", icon: almondIcon},
  //   {en: "milk choclate", he: "שוקולד חלב", icon: milkChocolateIcon},
  //   {en: "cocoa", he: "קקאו", icon: cocoaIcon},
  //   {en: "white chocolate", he: "שוקולד לבן", icon: whiteChocolateIcon},
  //   {en: "apple", he: "תפוח", icon: appleIcon},
  //   {en: "mango", he: "מנגו", icon: mangoIcon},
  //   {en: "lemon", he: "לימון", icon: lemonGrassIcon},
  //   {en: "blueberry", he: "אוכמניות", icon: brownSugarIcon},
  //   {en: "orange", he: "תפוז", icon: orangeIcon},
  //   {en: "grapefruit", he: "אשכולית", icon: orangeIcon},
  //   {en: "baked pear", he: "אגס אפוי", icon: mangoIcon},
  //   {en: "roasted almond", he: "שקדים קלויים", icon: roastedAlmondIcon},
  //   {en: "sugar cane", he: "קנה סוכר", icon: sugarCaneIcon},
  //   {en: "herbs", he: "עשבי תיבול", icon: herbsIcon},
  //   {en: "clove", he: "צפורן", icon: cloveIcon},
  //   {en: "spices", he: "תבלינים", icon: spicesIcon},
  //   {en: "cinnamon", he: "קינמון", icon: cinnamonIcon},
  //   {en: "lemon grass", he: "למון גראס", icon: lemonGrassIcon},
  //   {en: "tea", he: "תה", icon: herbsIcon},
  //   {en: "vanilla", he: "וניל", icon: vanillaIcon},
  //   {en: "brown sugar", he: "סוכר חום", icon: brownSugarIcon},
  //   {en: "hibiscus", he: "הִיבִּיסקוּס", icon: hibiscusIcon},
  //   {en: "carmel", he: "קרמל", icon: brownSugarIcon},
  //   {en: "honey", he: "דבש", icon: honeyIcon}
  // ];
  let tastesIconGlossaryWhite = [
    {en: "brown butter", he: "חמאה חומה", icon: brownButterIconWhite},
    {en: "peanuts", he: "בוטנים", icon: peanutsIconWhite},
    {en: "hazelnut", he: "אגוזי לוז", icon: hazelnutIconWhite},
    {en: "dark choclate", he: "שוקולד מריר", icon: chocolateIconWhite},
    {en: "almond", he: "שקד", icon: almondIconWhite},
    {en: "milk choclate", he: "שוקולד חלב", icon: milkChocolateIconWhite},
    {en: "cocoa", he: "קקאו", icon: cocoaIconWhite},
    {en: "white chocolate", he: "שוקולד לבן", icon: whiteChocolateIconWhite},
    {en: "apple", he: "תפוח", icon: appleIconWhite},
    {en: "mango", he: "מנגו", icon: mangoIconWhite},
    {en: "lemon", he: "לימון", icon: lemonGrassIconWhite},
    {en: "blueberry", he: "אוכמניות", icon: brownSugarIconWhite},
    {en: "orange", he: "תפוז", icon: orangeIconWhite},
    {en: "grapefruit", he: "אשכולית", icon: orangeIconWhite},
    {en: "baked pear", he: "אגס אפוי", icon: mangoIconWhite},
    {en: "roasted almond", he: "שקדים קלויים", icon: roastedAlmondIconWhite},
    {en: "sugar cane", he: "קנה סוכר", icon: sugarCaneIconWhite},
    {en: "herbs", he: "עשבי תיבול", icon: herbsIconWhite},
    {en: "clove", he: "צפורן", icon: cloveIconWhite},
    {en: "spices", he: "תבלינים", icon: spicesIconWhite},
    {en: "cinnamon", he: "קינמון", icon: cinnamonIconWhite},
    {en: "lemon grass", he: "למון גראס", icon: lemonGrassIconWhite},
    {en: "tea", he: "תה", icon: herbsIconWhite},
    {en: "vanilla", he: "וניל", icon: vanillaIconWhite},
    {en: "brown sugar", he: "סוכר חום", icon: brownSugarIconWhite},
    {en: "hibiscus", he: "הִיבִּיסקוּס", icon: hibiscusIconWhite},
    {en: "carmel", he: "כרמל", icon: brownSugarIconWhite},
    {en: "honey", he: "דבש", icon: honeyIconWhite},
    {en: "maple", he: "מייפל", icon: mapleIconWhite},
    {en: "licorice", he: "ליקריץ", icon: licoriceIconWhite},
    {en: "melon", he: "מלון", icon: melonIconWhite},
  ];

  function closeAssistantPopup(e){
    e.preventDefault();
    // document.querySelector('.assistant-quote').classList.remove('open');
    e.target.closest('.step-item').classList.remove('assistant-open');
  }
  function showAssistantPopup(e){
    e.preventDefault();
    // document.querySelector('.assistant-quote').classList.add('open');
    e.target.closest('.step-item').classList.add('assistant-open');
  }

  function muteAssistant(e){
    e.preventDefault();
    if (0 === mutedAssistant) {
      setMutedAssistant(1);
      setMuteLabel('הפעל');
      e.target.closest('.step-item').classList.add('assistant-muted');
    } else {
      setMutedAssistant(0);
      setMuteLabel('השתק');
      e.target.closest('.step-item').classList.remove('assistant-muted');
    }
  }

  // console.log(targetProfile.tasteNotes);

  function closeModal(e){
    e.target.closest('.modal').classList.remove('open');
  }

  function showNotesPopup(e) {
    e.preventDefault();
    document.getElementById('show-note-modal').classList.add('open')
  }

  return (
    <>
      <div className={'page-content lang-'+language} style={{backgroundImage: `url(${pageBG})`}}>
        <div className="lang-switcher">
          {
            languages.map((el, index) => {
              return(
                <button className={el === language ? 'lang-btn active' : 'lang-btn'} key={index} onClick={changeLanguage} data-lang={el}>{el}</button>
              )
            })
          }
        </div>
        <div className="page-header">
          <a className="logo" href="/">
            <img src={logo} alt=""/>
          </a>
        </div>
        <div className="steps-progress">
          <div className="steps-progress_body" style={{width: progressWidth}}>Step progress</div>
        </div>
        <div className="steps-wrapper">
          <div className={1 === currentStep ? 'step-item active' : 'step-item'}>
            <div className="step-item_wrapper">
              <div className="step-title">{languageFile.firstStep.title}</div>
              <div className="step-subtitle">{languageFile.firstStep.subtitle}</div>
              <div className="coffee-types_wrapper">
                <div className="coffee-types">
                  {
                    coffeeTypesMy.map((el, index) => {
                      return (
                        <div className="coffee-type_item" key={index}>
                          <label className="coffee-type_inner">
                            <input type="radio" id={index} name="drinkingType" value={index}
                                   checked={targetProfile.drinkingType === index}
                                   onChange={() => handleDrinkingTypesChange(index)}/>
                            <span className="value">
                              <span className="icon">
                                <img src={el.icon} alt=""/>
                              </span>
                              <span className="title">{languageFile.firstStep.coffeeTypes[index]}</span>
                            </span>
                          </label>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div className="step-footer">
                <div className="buttons">
                  <button className={targetProfile.drinkingType !== "" ? "thm-btn" : "thm-btn disabled"} data-step="2" onClick={stepNavigation}>{languageFile.continueBtn}</button>
                </div>
              </div>
            </div>
          </div>
          <div className={2 === currentStep ? 'step-item active' : 'step-item'}>
            <div className="step-item_wrapper">
              <div className="step-title">{languageFile.secondStep.title}</div>
              <div className="step-subtitle">{languageFile.secondStep.subtitle}</div>
              <div className="range-wrapper">
                <input type="hidden" name="acidity" value={targetProfile.acidity} readOnly={true}/>
                <input type="hidden" name="sweetness" value={targetProfile.sweetness} readOnly={true}/>
                <input type="hidden" name="bitterness" value={targetProfile.bitterness} readOnly={true}/>
                <input type="hidden" name="body" value={targetProfile.body} readOnly={true}/>
                <div className="range-item">
                  <div className="tooltip">
                    <div className="tooltip-wrapper">
                      <div className="icon">
                        <img src={tooltipIcon} alt=""/>
                      </div>
                      <div className="title">{languageFile.secondStep.preferences[0].title}</div>
                      <div className="desc">
                        <p>{languageFile.secondStep.preferences[0].description}</p>
                      </div>
                      <button className="close-tooltip" onClick={hideTooltip}>
                        <img src={closeIcon} alt=""/>
                      </button>
                    </div>
                  </div>
                  <div className="range-label">
                    <div className="tooltip-icon" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
                      <img src={tooltipIconGrey} alt=""/>
                    </div>
                    <div className="tooltip-icon white" onClick={showTooltip}>
                      <img src={tooltipIconWhite} alt=""/>
                    </div>
                    {languageFile.secondStep.preferences[0].title}
                  </div>
                  <div className="range-slider_wrapper thumb-labels">
                    <RangeSlider
                      className={"acidity acidity-"+targetProfile.acidity}
                      min={1}
                      max={5}
                      step={0.01}
                      defaultValue={[1, targetProfile.acidity]}
                      thumbsDisabled={[true, false]}
                      rangeSlideDisabled={true}
                      onInput={changeAcidityInput}
                    />
                  </div>
                </div>
                <div className="range-item">
                  <div className="tooltip">
                    <div className="tooltip-wrapper sweetness">
                      <div className="icon">
                        <img src={tooltipIcon} alt=""/>
                      </div>
                      <div className="title">{languageFile.secondStep.preferences[1].title}</div>
                      <div className="desc">
                        <p>{languageFile.secondStep.preferences[1].description}</p>
                      </div>
                      <button className="close-tooltip" onClick={hideTooltip}>
                        <img src={closeIcon} alt=""/>
                      </button>
                    </div>
                  </div>
                  <div className="range-label">
                    <div className="tooltip-icon" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
                      <img src={tooltipIconGrey} alt=""/>
                    </div>
                    <div className="tooltip-icon white" onClick={showTooltip}>
                      <img src={tooltipIconWhite} alt=""/>
                    </div>
                    {languageFile.secondStep.preferences[1].title}
                  </div>
                  <div className="range-slider_wrapper thumb-labels">
                    <RangeSlider
                      className={"sweetness sweetness-"+targetProfile.sweetness}
                      min={1}
                      max={5}
                      step={0.01}
                      defaultValue={[1, targetProfile.sweetness]}
                      thumbsDisabled={[true, false]}
                      rangeSlideDisabled={true}
                      onInput={changeSweetnessInput}
                    />
                  </div>
                </div>
                <div className="range-item">
                  <div className="tooltip">
                    <div className="tooltip-wrapper bitterness">
                      <div className="icon">
                        <img src={tooltipIcon} alt=""/>
                      </div>
                      <div className="title">{languageFile.secondStep.preferences[2].title}</div>
                      <div className="desc">
                        <p>{languageFile.secondStep.preferences[2].description}</p>
                      </div>
                      <button className="close-tooltip" onClick={hideTooltip}>
                        <img src={closeIcon} alt=""/>
                      </button>
                    </div>
                  </div>
                  <div className="range-label">
                    <div className="tooltip-icon" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
                      <img src={tooltipIconGrey} alt=""/>
                    </div>
                    <div className="tooltip-icon white" onClick={showTooltip}>
                      <img src={tooltipIconWhite} alt=""/>
                    </div>
                    {languageFile.secondStep.preferences[2].title}
                  </div>
                  <div className="range-slider_wrapper thumb-labels">
                    <RangeSlider
                      className={"bitterness bitterness-"+targetProfile.bitterness}
                      min={1}
                      max={5}
                      step={0.01}
                      defaultValue={[1, targetProfile.bitterness]}
                      thumbsDisabled={[true, false]}
                      rangeSlideDisabled={true}
                      onInput={changeBitternessInput}
                    />
                  </div>
                </div>
                <div className="range-item">
                  <div className="tooltip">
                    <div className="tooltip-wrapper body">
                      <div className="icon">
                        <img src={tooltipIcon} alt=""/>
                      </div>
                      <div className="title">{languageFile.secondStep.preferences[3].title}</div>
                      <div className="desc">
                        <p>{languageFile.secondStep.preferences[3].description}</p>
                      </div>
                      <button className="close-tooltip" onClick={hideTooltip}>
                        <img src={closeIcon} alt=""/>
                      </button>
                    </div>
                  </div>
                  <div className="range-label">
                    <div className="tooltip-icon" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
                      <img src={tooltipIconGrey} alt=""/>
                    </div>
                    <div className="tooltip-icon white" onClick={showTooltip}>
                      <img src={tooltipIconWhite} alt=""/>
                    </div>
                    {languageFile.secondStep.preferences[3].title}
                  </div>
                  <div className="range-slider_wrapper thumb-labels">
                    <RangeSlider
                      className={"body body-"+targetProfile.body}
                      min={1}
                      max={5}
                      step={0.01}
                      defaultValue={[1, targetProfile.body]}
                      thumbsDisabled={[true, false]}
                      rangeSlideDisabled={true}
                      onInput={changeBodyInput}
                    />
                  </div>
                </div>
              </div>
              <div className="step-footer">
                <div className="buttons multiple">
                  <button className="thm-btn bordered" data-step="1" onClick={stepNavigation}>{languageFile.backBtn}</button>
                  <button className={0 === step2Validation ? "thm-btn disabled" : "thm-btn"} data-step="3" onClick={stepNavigation}>{languageFile.continueBtn}</button>
                </div>
              </div>
            </div>
          </div>
          <div className={3 === currentStep ? 'step-item active' : 'step-item'}>
            <div className="step-item_wrapper">
              <div className="step-title">{languageFile.thirdStep.title}</div>
              <div className="step-subtitle">{languageFile.thirdStep.subtitle}</div>
              <div className="notes-wrapper">
                <div className="notes-wrapper_inner">
                  <div className="notes-amount">{notesSelected}/2</div>
                  <div className="notes-items">
                    <div className="notes-item">
                      <label className="notes-item_label">
                        <input type="checkbox" name="taste_notes[]" checked={targetProfile.tasteNotes.includes(2)}
                               onChange={() => handleTasteNoteChange(2)} value="spices_roasted"/>
                        <span className="label-value">
                          <span className="label-bg">
                            <img src={leftTopNoteBg} alt=""/>
                            <img src={leftTopNoteBgActive} alt="" className="active"/>
                          </span>
                          <span className="label-icon">
                            <img src={leftTopNoteIcon} alt=""/>
                            <img src={leftTopNoteIconActive} alt="" className="active"/>
                          </span>
                          <span className="label-title">{languageFile.thirdStep.notes[0].title}</span>
                          <span className="label-flavors">
                            {
                              flavors0.map((el, index) => {
                                return (
                                  <span className="label-flavor_item" key={index}>
                                    <span className="icon">
                                      <img src={el.icon} alt=""/>
                                    </span>
                                    <span className="title">{languageFile.thirdStep.notes[0].tastes[index]}</span>
                                  </span>
                                )
                              })
                            }
                          </span>
                        </span>
                      </label>
                    </div>
                    <div className="notes-item">
                      <label className="notes-item_label">
                        <input type="checkbox" name="taste_notes[]" checked={targetProfile.tasteNotes.includes(3)}
                               onChange={() => handleTasteNoteChange(3)} value="sweet_floral"/>
                        <span className="label-value top-right">
                          <span className="label-bg">
                            <img src={rightTopNoteBg} alt=""/>
                            <img src={rightTopNoteBgActive} alt="" className="active"/>
                          </span>
                          <span className="label-icon">
                            <img src={rightTopNoteIcon} alt=""/>
                            <img src={rightTopNoteIconActive} alt="" className="active"/>
                          </span>
                          <span className="label-title">{languageFile.thirdStep.notes[1].title}</span>
                          <span className="label-flavors">
                            {
                              flavors1.map((el, index) => {
                                return (
                                  <span className="label-flavor_item" key={index}>
                                    <span className="icon">
                                      <img src={el.icon} alt=""/>
                                    </span>
                                    <span className="title">{languageFile.thirdStep.notes[1].tastes[index]}</span>
                                  </span>
                                )
                              })
                            }
                          </span>
                        </span>
                      </label>
                    </div>
                    <div className="notes-item">
                      <label className="notes-item_label">
                        <input type="checkbox" name="taste_notes[]" checked={targetProfile.tasteNotes.includes(0)}
                               onChange={() => handleTasteNoteChange(0)} value="fruity_citrus"/>
                        <span className="label-value bottom-left">
                          <span className="label-bg">
                            <img src={leftBottomNoteBg} alt=""/>
                            <img src={leftBottomNoteBgActive} alt="" className="active"/>
                          </span>
                          <span className="label-icon">
                            <img src={leftBottomNoteIcon} alt=""/>
                            <img src={leftBottomNoteIconActive} alt="" className="active"/>
                          </span>
                          <span className="label-title">{languageFile.thirdStep.notes[2].title}</span>
                          <span className="label-flavors">
                            {
                              flavors2.map((el, index) => {
                                return (
                                  <span className="label-flavor_item" key={index}>
                                    <span className="icon">
                                      <img src={el.icon} alt=""/>
                                    </span>
                                    <span className="title">{languageFile.thirdStep.notes[2].tastes[index]}</span>
                                  </span>
                                )
                              })
                            }
                          </span>
                        </span>
                      </label>
                    </div>
                    <div className="notes-item">
                      <label className="notes-item_label">
                        <input type="checkbox" name="taste_notes[]" checked={targetProfile.tasteNotes.includes(1)}
                               onChange={() => handleTasteNoteChange(1)} value="chocolaty_nuts"/>
                        <span className="label-value bottom-right">
                          <span className="label-bg">
                            <img src={rightBottomNoteBg} alt=""/>
                            <img src={rightBottomNoteBgActive} alt="" className="active"/>
                          </span>
                          <span className="label-icon">
                            <img src={rightBottomNoteIcon} alt=""/>
                            <img src={rightBottomNoteIconActive} alt="" className="active"/>
                          </span>
                          <span className="label-title chocolate">{languageFile.thirdStep.notes[3].title}</span>
                          <span className="label-flavors">
                            {
                              flavors3.map((el, index) => {
                                return (
                                  <span className="label-flavor_item" key={index}>
                                    <span className="icon">
                                      <img src={el.icon} alt=""/>
                                    </span>
                                    <span className="title">{languageFile.thirdStep.notes[3].tastes[index]}</span>
                                  </span>
                                )
                              })
                            }
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className={targetProfile.tasteNotes.length > 0 ? "error-note" : "error-note show"}>
                <span className="icon">
                  <img src={warningIcon} alt=""/>
                </span>
                {languageFile.thirdStep.errorNote}
              </div>
              <div className="show-notes">
                <button className="show-notes_btn" onClick={showNotesPopup}>
                  <span className="icon">
                    <img src={warningIconReverse} alt=""/>
                  </span>
                  למידע נוסף על משפחות הטעמים
                </button>
              </div>
              <div className="step-footer">
                <div className="buttons multiple">
                  <button className="thm-btn bordered" data-step="2" onClick={stepNavigation}>{languageFile.backBtn}</button>
                  <button className={targetProfile.tasteNotes.length > 0 ? "thm-btn" : "thm-btn disabled"} data-step="4" onClick={stepNavigation}>{languageFile.continueBtn}</button>
                </div>
              </div>
            </div>
          </div>
          <div className={4 === currentStep ? 'step-item active' : 'step-item'}>
            <div className="step-item_wrapper">
              <div className="step-title">{languageFile.fourthStep.title}</div>
              <div className="step-subtitle">{languageFile.fourthStep.subtitle}</div>
              <div className="consuming-wrapper">
                {
                  [0,1,2].map((el, index) => {
                    return(
                      <div className="consuming-item" key={index}>
                        <label className="consuming-label">
                          <input type="radio" name="consuming_amount" value={index}
                                 checked={targetProfile.howOftenDrink === index}
                                 onChange={() => handleHowOftenDrinkChange(index)}/>
                          <span className="name">{languageFile.fourthStep.howOften[index]}</span>
                        </label>
                      </div>
                    )
                  })
                }
              </div>
              <div className="step-footer">
                <div className="buttons multiple">
                  <button className="thm-btn bordered" data-step="3" onClick={stepNavigation}>{languageFile.backBtn}</button>
                  <button className={targetProfile.howOftenDrink !== "" ? "thm-btn" : "thm-btn disabled"} onClick={calculateBestBlend}>{languageFile.calculateBtn}</button>
                </div>
              </div>
            </div>
          </div>
          <div className={5 === currentStep ? 'step-item active' : 'step-item'}>
            <div className="step-item_wrapper pb-100">
              {
                5 === currentStep ? <Loader text={languageFile.fifthStep}/> : ''
              }
            </div>
          </div>
          <div className={6 === currentStep ? 'step-item active' : 'step-item'}>
            <div className="step-item_wrapper blend-wrapper">
              <div className="before"></div>
              <div className="blend-wrapper">
                <div className="blend-wrapper_left">
                  <div className="coffee-type">
                    <div className="coffee-type_icon">
                      <div className="icon">
                        <img src={targetProfile.drinkingType !== "" ? coffeeTypesMy[targetProfile.drinkingType].icon : ''} alt=""/>
                      </div>
                      <div className="name">{targetProfile.drinkingType !== "" ? languageFile.firstStep.coffeeTypes[targetProfile.drinkingType] : ''}</div>
                    </div>
                    <div className="coffee-type_info">
                      <div className="title">{languageFile.sixthStep.title}</div>
                      <div className="subtitle">{languageFile.sixthStep.subtitle}</div>
                      <div className="change-preferences">
                        <button className="change-btn" onClick={changePreferences}>
                          <img src={reloadIcon} alt=""/>
                          {languageFile.sixthStep.changePreferenceTitle}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flavors">
                    <div className="blend-sidebar_title">
                      <div className="title">{languageFile.sixthStep.flavorsTitle}</div>
                      <hr></hr>
                    </div>
                    <div className="flavors-wrapper">
                      <div className="flavors-values block">
                        <div className="flavor-value_item">
                          <div className="value">{targetProfile.acidity}/5</div>
                          <div className="label">{languageFile.secondStep.preferences[0].title}</div>
                        </div>
                        <div className="flavor-value_item">
                          <div className="value">{targetProfile.sweetness}/5</div>
                          <div className="label">{languageFile.secondStep.preferences[1].title}</div>
                        </div>
                        <div className="flavor-value_item">
                          <div className="value">{targetProfile.bitterness}/5</div>
                          <div className="label">{languageFile.secondStep.preferences[2].title}</div>
                        </div>
                        <div className="flavor-value_item">
                          <div className="value">{targetProfile.body}/5</div>
                          <div className="label">{languageFile.secondStep.preferences[3].title}</div>
                        </div>
                      </div>
                      <div className="flavors-chart">
                        <SpiderChart chartData={targetProfile} lang={language}/>
                      </div>
                    </div>
                  </div>
                  <div className="tastes">
                    <div className="blend-sidebar_title">
                      <div className="title">{languageFile.sixthStep.tasteNoteTitle}</div>
                      <hr></hr>
                    </div>
                    <div className="notes-wrapper small">
                      <div className="notes-wrapper_inner">
                        <div className="notes-items">
                          <div className="notes-item">
                            <label className="notes-item_label">
                              <input type="checkbox" name="taste_notes[]" checked={targetProfile.tasteNotes.includes(2)} onChange={() => handleTasteNoteChange(2)} value="spices_roasted" disabled={true}/>
                              <span className="label-value">
                                <span className="label-bg">
                                  <img src={leftTopNoteBg} alt=""/>
                                  <img src={leftTopNoteBgActive} alt="" className="active"/>
                                </span>
                                <span className="label-icon">
                                  <img src={leftTopNoteIcon} alt=""/>
                                  <img src={leftTopNoteIconActive} alt="" className="active"/>
                                </span>
                                <span className="label-title">{languageFile.thirdStep.notes[0].title}</span>
                                <span className="label-flavors op-0">
                                  {
                                    flavors0.map((el, index) => {
                                      return (
                                        <span className="label-flavor_item" key={index}>
                                          <span className="icon">
                                            <img src={el.icon} alt=""/>
                                          </span>
                                          <span className="title">{languageFile.thirdStep.notes[0].tastes[index]}</span>
                                        </span>
                                      )
                                    })
                                  }
                                </span>
                              </span>
                            </label>
                          </div>
                          <div className="notes-item">
                            <label className="notes-item_label">
                              <input type="checkbox" name="taste_notes[]" checked={targetProfile.tasteNotes.includes(3)} onChange={() => handleTasteNoteChange(3)} value="sweet_floral" disabled={true}/>
                              <span className="label-value top-right">
                                <span className="label-bg">
                                  <img src={rightTopNoteBg} alt=""/>
                                  <img src={rightTopNoteBgActive} alt="" className="active"/>
                                </span>
                                <span className="label-icon">
                                  <img src={rightTopNoteIcon} alt=""/>
                                  <img src={rightTopNoteIconActive} alt="" className="active"/>
                                </span>
                                <span className="label-title">{languageFile.thirdStep.notes[1].title}</span>
                                <span className="label-flavors op-0">
                                  {
                                    flavors1.map((el, index) => {
                                      return (
                                        <span className="label-flavor_item" key={index}>
                                          <span className="icon">
                                            <img src={el.icon} alt=""/>
                                          </span>
                                          <span className="title">{languageFile.thirdStep.notes[1].tastes[index]}</span>
                                        </span>
                                      )
                                    })
                                  }
                                </span>
                              </span>
                            </label>
                          </div>
                          <div className="notes-item">
                            <label className="notes-item_label">
                              <input type="checkbox" name="taste_notes[]" checked={targetProfile.tasteNotes.includes(0)} onChange={() => handleTasteNoteChange(0)} value="fruity_citrus" disabled={true}/>
                              <span className="label-value bottom-left">
                                <span className="label-bg">
                                  <img src={leftBottomNoteBg} alt=""/>
                                  <img src={leftBottomNoteBgActive} alt="" className="active"/>
                                </span>
                                <span className="label-icon">
                                  <img src={leftBottomNoteIcon} alt=""/>
                                  <img src={leftBottomNoteIconActive} alt="" className="active"/>
                                </span>
                                <span className="label-title">{languageFile.thirdStep.notes[2].title}</span>
                                <span className="label-flavors op-0">
                                  {
                                    flavors2.map((el, index) => {
                                      return (
                                        <span className="label-flavor_item" key={index}>
                                          <span className="icon">
                                            <img src={el.icon} alt=""/>
                                          </span>
                                          <span className="title">{languageFile.thirdStep.notes[2].tastes[index]}</span>
                                        </span>
                                      )
                                    })
                                  }
                                </span>
                              </span>
                            </label>
                          </div>
                          <div className="notes-item">
                            <label className="notes-item_label">
                              <input type="checkbox" name="taste_notes[]" checked={targetProfile.tasteNotes.includes(1)} onChange={() => handleTasteNoteChange(1)} value="chocolaty_nuts" disabled={true}/>
                              <span className="label-value bottom-right">
                                <span className="label-bg">
                                  <img src={rightBottomNoteBg} alt=""/>
                                  <img src={rightBottomNoteBgActive} alt="" className="active"/>
                                </span>
                                <span className="label-icon">
                                  <img src={rightBottomNoteIcon} alt=""/>
                                  <img src={rightBottomNoteIconActive} alt="" className="active"/>
                                </span>
                                <span className="label-title chocolate">{languageFile.thirdStep.notes[3].title}</span>
                                <span className="label-flavors op-0">
                                  {
                                    flavors3.map((el, index) => {
                                      return (
                                        <span className="label-flavor_item" key={index}>
                                          <span className="icon">
                                            <img src={el.icon} alt=""/>
                                          </span>
                                          <span className="title">{languageFile.thirdStep.notes[3].tastes[index]}</span>
                                        </span>
                                      )
                                    })
                                  }
                                </span>
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="float-flavors">
                        {
                          [0,1,2,3].map((item, index) => {
                            if (targetProfile.tasteNotes.includes(item)) {
                              let iconsArray = flavors2;
                              let iconsTitle = 2;
                              if (1 === item) {
                                iconsArray = flavors3;
                                iconsTitle = 3;
                              } else if (2 === item) {
                                iconsArray = flavors0;
                                iconsTitle = 0;
                              } else if (3 === item) {
                                iconsArray = flavors1;
                                iconsTitle = 1
                              }
                              return(
                                <div className="label-flavors" key={index}>
                                  <div className="flavours-title">{languageFile.thirdStep.notes[iconsTitle].title}</div>
                                  {
                                    iconsArray.map((el, index) => {
                                      return (
                                        <span className="label-flavor_item" key={index}>
                                        <span className="icon">
                                          <img src={el.icon} alt=""/>
                                        </span>
                                        <span className="title">{languageFile.thirdStep.notes[iconsTitle].tastes[index]}</span>
                                      </span>
                                      )
                                    })
                                  }
                                </div>
                              )
                            } else {
                              return null;
                            }
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="blend-wrapper_right">
                  <div className="best-blends">
                    {bestBlendsArr.slice(-2).map((blend, index) => {
                      // console.log(blend);
                      var match = blend.match;
                      var coffeeName = blend.cofeeName;
                      var coffeeAcidity = blend.preferences.חומציות;
                      var coffeeBitterness = blend.preferences.מרירות;
                      var coffeeBody = blend.preferences.גוף;
                      var coffeeSweetness = blend.preferences.מתיקות;
                      var coffeeImage = blend.image;
                      var coffeePrice = Object.values(blend.weightPrice)[0];
                      var coffeePriceFormat = coffeePrice.toFixed(2);
                      var coffeeNotes = blend.subNotes;
                      // console.log(coffeeNotes);
                      var translations = languageFile;
                      var matchFake = match;
                      if (match >= matchPercent) {
                        matchFake = matchPercent - 0.5;
                      }
                      return(
                        <div className="blend-item" key={index}>
                          <div className="blend-item_inner">
                            <div className="blend-item_top">
                              <div className="blend-match">{matchFake}% {translations.blendCard.matchStr}</div>
                              <div className="blend-title">{coffeeName}</div>
                              <div className="blend-info">
                                <div className="blend-image">
                                  <img src={coffeeImage} alt=""/>
                                </div>
                                <div className="blend-desc">
                                  <div className="flavours">
                                    {
                                      coffeeNotes.map((el, index) => {
                                        let tasteDefaultIcon = tastesIconGlossaryWhite[0].icon;
                                        var newArray = tastesIconGlossaryWhite.filter(function (item) {
                                          let arrayLangLabel = item.he;
                                          return arrayLangLabel === el.toLowerCase();
                                        });
                                        if (newArray.length > 0) {
                                          tasteDefaultIcon = newArray[0].icon;
                                        }
                                        return (
                                          <div className="label-flavor_item" key={index}>
                                            <div className="icon">
                                              <img src={tasteDefaultIcon} alt=""/>
                                            </div>
                                            <div className="title">{el}</div>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                  <div className="flavors-values">
                                    <div className="flavor-value_item">
                                      <div className="value">{coffeeAcidity}/5</div>
                                      <div className="label">{translations.secondStep.preferences[0].title}</div>
                                    </div>
                                    <div className="flavor-value_item">
                                      <div className="value">{coffeeSweetness}/5</div>
                                      <div className="label">{translations.secondStep.preferences[1].title}</div>
                                    </div>
                                    <div className="flavor-value_item">
                                      <div className="value">{coffeeBitterness}/5</div>
                                      <div className="label">{translations.secondStep.preferences[2].title}</div>
                                    </div>
                                    <div className="flavor-value_item">
                                      <div className="value">{coffeeBody}/5</div>
                                      <div className="label">{translations.secondStep.preferences[3].title}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="blend-buy">
                              <div className="blend-price">
                                {translations.blendCard.fromStr} &#8362;{coffeePriceFormat}
                              </div>
                              <div className="buttons">
                                <button className="thm-btn small" onClick={openBuyPopup} data-target={index}>{translations.blendCard.buyBtn}</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className="create-blend_mobile">
                    <div className="bg">
                      <img src={createBlendImageMobile} alt=""/>
                    </div>
                    <div className="info">
                      <div className="title">{matchPercent}% התאמה </div>
                      <div className="subtitle">יצירת קפה מותאם אישית עבורך!</div>
                      <div className="text">במסך הבא ניתן לבחור את זני הקפה ודרגת הקלייה בעצמך ואני אלווה אותך ביצירת קפה שמתאים בדיוק לפרופיל הטעמים שלך.</div>
                      <div className="buttons">
                        <button className="thm-btn"
                                onClick={() => {
                                  navigate('/editor', {state: {bestBlends, targetProfile, coffeeTypes, bestBlendsArr, matchPercent}});
                                }}
                        >קפה בהתאמה אישית</button>
                      </div>
                    </div>
                  </div>
                  <div className="create-blend">
                    <div className="create-blend_inner">
                      <div className="match">{matchPercent}% {languageFile.blendCard.matchStr}</div>
                      <div className="title">{languageFile.createBlend.title}</div>
                      <div className="subtitle">{languageFile.createBlend.subtitle[0]} <strong>{matchPercent}%</strong></div>
                      <div className="buttons">
                        <button className="thm-btn small"
                                onClick={() => {
                                  navigate('/editor', {state: {bestBlends, targetProfile, coffeeTypes, bestBlendsArr, matchPercent}});
                                }}
                        >{languageFile.createBlend.tryBtn}</button>
                      </div>
                    </div>
                    <div className="create-blend_image">
                      <img src={createBlendImage} alt=""/>
                      <img src={createBlendImageMobile} alt="" className="mobile"/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mobile-buttons_wrapper">
                <button className="nav-button active" onClick={mobileNav} data-target="identify">
                  <img src={navIcon1} alt=""/>
                  <span className="label">{languageFile.sixthStep.mobileButtons[0]}</span>
                </button>
                <button className="nav-button matches" onClick={mobileNav} data-target="matches">
                  <img src={navIcon2} alt=""/>
                  <span className="label">{languageFile.sixthStep.mobileButtons[1]}</span>
                </button>
                <button className="nav-button" onClick={mobileNav} data-target="editor">
                  <img src={navIcon3} alt=""/>
                  <span className="label">{languageFile.sixthStep.mobileButtons[2]}</span>
                </button>
              </div>
              <div className="blend-assistant b-80">
                <button className="assistant-photo" onClick={showAssistantPopup}>
                  <span className="image">
                    <img src={photo} alt=""/>
                  </span>
                  <span className="name">{languageFile.assistant.name}</span>
                </button>
                <div className="assistant-quote">
                  <div className="quote-text">
                    <button className="close-quote" onClick={closeAssistantPopup}>
                      <img src={closeIcon} alt=""/>
                    </button>
                    <p>
                      <strong>{languageFile.assistant.textStrong}</strong>
                      זהו פרופיל הקפה האישי שלך! אשמח להציע לך קפה שמתאים לפרופיל, או ללוות אותך ביצירת קפה המדויק עבורך ב-<strong className="green">{matchPercent}%</strong> בלחיצה על ״קפה בהתאמה אישית״
                    </p>
                  </div>
                  <button className="mute-assistant" onClick={muteAssistant}>
                    <img src={muteIcon} alt=""/>
                    <span className="name">{muteLabel}</span>
                  </button>
                  <div className="icon">
                    <img src={quoteIcon} alt=""/>
                  </div>
                </div>
              </div>
              <div className="float-buttons">
                <div className="buttons multiple">
                  <button className="thm-btn simple" onClick={changePreferences}>התחל מחדש</button>
                  <button className="thm-btn editor"
                          onClick={() => {
                            navigate('/editor', {state: {bestBlends, targetProfile, coffeeTypes, bestBlendsArr, matchPercent}});
                          }}
                  >ליצירת קפה</button>
                  <button className="thm-btn matches" onClick={mobileNav} data-target="matches">המשך</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="show-note-modal">
          <div className="modal-dialog">
            <div className="modal-content">
              <button className="close-modal" onClick={closeModal}>
                <img src={closeIcon} alt=""/>
              </button>
              <div className="modal-title">תתי משפחות</div>
              <div className="float-flavors_wrapper">
                <div className="float-flavors">
                  {
                    [0,1,2,3].map((item, index) => {
                      let iconsArray = flavors2;
                      let iconsTitle = 2;
                      if (1 === item) {
                        iconsArray = flavors3;
                        iconsTitle = 3;
                      } else if (2 === item) {
                        iconsArray = flavors0;
                        iconsTitle = 0;
                      } else if (3 === item) {
                        iconsArray = flavors1;
                        iconsTitle = 1
                      }
                      return(
                        <div className="label-flavors" key={index}>
                          <div className="label-flavors_inner">
                            <div className="flavours-title">{languageFile.thirdStep.notes[iconsTitle].title}</div>
                            {
                              iconsArray.slice(0,6).map((el, index) => {
                                return (
                                  <span className="label-flavor_item" key={index}>
                                    <span className="icon">
                                      <img src={el.icon} alt=""/>
                                    </span>
                                    <span className="title">{languageFile.thirdStep.notes[iconsTitle].tastes[index]}</span>
                                </span>
                                )
                              })
                            }
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <BuyProductModal translations={languageFile} coffeeSelection={modalCoffeeInfo}/>
      </div>
    </>
  );
};

export default UserPreferences;
