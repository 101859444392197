import React, {useState, useEffect} from "react";
import closeIcon from "../../../images/assistant/close_icon.svg";
// import productImage from "../../../images/product_popup.png";
import videoPlaceholder from "../../../images/IMG_0170-min.JPG";
//flavors
import almondIcon from '../../../images/taste_notes/flavors_white/flavor_1.svg';
import roastedAlmondIcon from '../../../images/taste_notes/flavors_white/agro-icon-brown-16.svg';
import sugarCaneIcon from '../../../images/taste_notes/flavors_white/flavor_2.svg';
import spicesIcon from '../../../images/taste_notes/flavors_white/flavor_4.svg';
import cinnamonIcon from '../../../images/taste_notes/flavors_white/flavor_5.svg';
import peanutsIcon from '../../../images/taste_notes/flavors_white/flavor_6.svg';
import hazelnutIcon from '../../../images/taste_notes/flavors_white/flavor_7.svg';
import cocoaIcon from '../../../images/taste_notes/flavors_white/flavor_8.svg';
import chocolateIcon from '../../../images/taste_notes/flavors_white/flavor_9.svg';
import herbsIcon from '../../../images/taste_notes/flavors_white/agro-icon-brown-7.svg';
import cloveIcon from '../../../images/taste_notes/flavors_white/agro-icon-brown-8.svg';
import lemonGrassIcon from '../../../images/taste_notes/flavors_white/agro-icon-brown-9.svg';
import vanillaIcon from '../../../images/taste_notes/flavors_white/agro-icon-brown-25.svg';
import brownSugarIcon from '../../../images/taste_notes/flavors_white/agro-icon-brown-14.svg';
import hibiscusIcon from '../../../images/taste_notes/flavors_white/agro-icon-brown-17.svg';
import honeyIcon from '../../../images/taste_notes/flavors_white/agro-icon-brown-1.svg';
import appleIcon from '../../../images/taste_notes/flavors_white/agro-icon-brown-24.svg';
import mangoIcon from '../../../images/taste_notes/flavors_white/agro-icon-brown-26.svg';
import orangeIcon from '../../../images/taste_notes/flavors_white/agro-icon-brown-10.svg';
import brownButterIcon from '../../../images/taste_notes/flavors_white/agro-icon-brown-21.svg';
import milkChocolateIcon from '../../../images/taste_notes/flavors_white/agro-icon-brown-4.svg';
import whiteChocolateIcon from '../../../images/taste_notes/flavors_white/agro-icon-brown-19.svg';

import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import chevronDownIcon from "../../../images/chevron-down-white.svg";

export default function BuyProductModal(props){
  const [grindingDegree, setGrindingDegree] = useState('פולים');
  const [weight, setWeight] = useState();
  const [quantity, setQuantity] = useState(1);
  const [roastingLevel, setRoastingLevel] = useState(1);
  const [roastingLevelAgtron, setRoastingLevelAgtron] = useState('#70-#80');
  const [coffeePrice, setCoffeePrice] = useState();
  // console.log(props);
  let coffeeName = '';
  let coffeeAcidity = '';
  let coffeeBitterness = '';
  let coffeeBody = '';
  let coffeeSweetness = '';
  let coffeeImage = '';
  let weightPrices = {};
  let grinding = [];
  let description = '';
  let coffeeNotes = [];
  let roastingLevelValue = '';
  let coffeeDetails = {};
  let variations = {};
  if (Object.keys(props.coffeeSelection).length > 0) {
    let blend = props.coffeeSelection;
    // console.log(blend);
    coffeeName = blend.cofeeName;
    coffeeAcidity = blend.preferences.חומציות;
    coffeeBitterness = blend.preferences.מרירות;
    coffeeBody = blend.preferences.גוף;
    coffeeSweetness = blend.preferences.מתיקות;
    coffeeImage = blend.image;
    weightPrices = blend.weightPrice;
    grinding = blend.grinding;
    description = blend.description;
    coffeeNotes = blend.subNotes;
    roastingLevelValue = blend.roastingLevel;
    coffeeDetails = blend.coffeeDetails;
    variations = blend.variations;
  }

  function closeModal(e){
    e.target.closest('.modal').classList.remove('open');
  }

  let translations = props.translations;

  // let grindingDegreeValues = translations.product.props.grindingDegree.values;
  // let weightValues = translations.product.props.weight.values;

  let flavors1 = [
    {icon: almondIcon},
    {icon: almondIcon},
    {icon: almondIcon}
  ];

  useEffect(() => {
    let roastingLevelDefault = 1;
    let roastingLevelAgtronDefault = '#70-#80';
    if ('Medium' === roastingLevelValue || 'medium' === roastingLevelValue) {
      roastingLevelDefault = 2;
      roastingLevelAgtronDefault = '#60-#70';
    } else if ('Dark' === roastingLevelValue || 'dark' === roastingLevelValue) {
      roastingLevelDefault = 3;
      roastingLevelAgtronDefault = '#50-#60';
    }
    setRoastingLevel(roastingLevelDefault);
    setRoastingLevelAgtron(roastingLevelAgtronDefault);

    setWeight(Object.keys(weightPrices)[0]);
    setCoffeePrice(Object.values(weightPrices)[0]);

    //set buy link
  }, [props.coffeeSelection]);

  let roastingLevels = translations.product.roastingLevels;

  function changeGrindingDegree(){
    let choices = document.querySelectorAll('input[name=grinding_degree]');
    choices.forEach((el) => {
      if (el.checked) {
        setGrindingDegree(el.value);
      }
    })
  }
  function changeWeight(){
    let choices = document.querySelectorAll('input[name=weight]');
    choices.forEach((el) => {
      if (el.checked) {
        setWeight(el.value);
        setCoffeePrice(parseInt(el.getAttribute('data-price')));
      }
    })
  }

  function openCustomDropdown(e){
    e.preventDefault();
    e.target.closest('.custom-dropdown_wrapper').classList.toggle('open');
  }

  function changeQuantity(e){
    e.preventDefault();
    e.target.closest('.custom-dropdown_wrapper').classList.remove('open');
    setQuantity(parseInt(e.target.innerHTML));
  }

  // function changeRoastingLevel(e){
  //   setRoastingLevel(e[1]);
  // }

  function openCoffeeInfo(e){
    e.preventDefault();
    e.currentTarget.closest('.product-info_bottom').classList.toggle('open');
  }

  let tastesIconGlossary = [
    {en: "brown butter", he: "חמאה חומה", icon: brownButterIcon},
    {en: "peanuts", he: "בוטנים", icon: peanutsIcon},
    {en: "hazelnut", he: "אגוזי לוז", icon: hazelnutIcon},
    {en: "dark choclate", he: "שוקולד מריר", icon: chocolateIcon},
    {en: "almond", he: "שקד", icon: almondIcon},
    {en: "milk choclate", he: "שוקולד חלב", icon: milkChocolateIcon},
    {en: "cocoa", he: "קקאו", icon: cocoaIcon},
    {en: "white chocolate", he: "שוקולד לבן", icon: whiteChocolateIcon},
    {en: "apple", he: "תפוח", icon: appleIcon},
    {en: "mango", he: "מנגו", icon: mangoIcon},
    {en: "lemon", he: "לימון", icon: lemonGrassIcon},
    {en: "blueberry", he: "אוכמניות", icon: brownSugarIcon},
    {en: "orange", he: "תפוז", icon: orangeIcon},
    {en: "grapefruit", he: "אשכולית", icon: orangeIcon},
    {en: "baked pear", he: "אגס אפוי", icon: mangoIcon},
    {en: "roasted almond", he: "שקדים קלויים", icon: roastedAlmondIcon},
    {en: "sugar cane", he: "קנה סוכר", icon: sugarCaneIcon},
    {en: "herbs", he: "עשבי תיבול", icon: herbsIcon},
    {en: "clove", he: "צפורן", icon: cloveIcon},
    {en: "spices", he: "תבלינים", icon: spicesIcon},
    {en: "cinnamon", he: "קינמון", icon: cinnamonIcon},
    {en: "lemon grass", he: "למון גראס", icon: lemonGrassIcon},
    {en: "tea", he: "תה", icon: herbsIcon},
    {en: "vanilla", he: "וניל", icon: vanillaIcon},
    {en: "brown sugar", he: "סוכר חום", icon: brownSugarIcon},
    {en: "hibiscus", he: "הִיבִּיסקוּס", icon: hibiscusIcon},
    {en: "carmel", he: "כרמל", icon: brownSugarIcon},
    {en: "honey", he: "דבש", icon: honeyIcon}
  ];

  // console.log(coffeeDetails);

  function buyProduct(e){
    e.preventDefault();
    let productLink = '';
    //get base link
    let choices = document.querySelectorAll('input[name=weight]');
    choices.forEach((el) => {
      if (el.checked) {
        let selectedWeight = parseInt(el.value);
        productLink = variations[selectedWeight];
      }
    });
    //get grinding
    let redirectLink = 0;
    let choicesGrinding = document.querySelectorAll('input[name=grinding_degree]');
    choicesGrinding.forEach((el) => {
      if (el.checked) {
        grinding.forEach((grnd, index) => {
          if (grnd[el.value]) {
            productLink += grnd[el.value];
          }
        })
        redirectLink++;
      }
    });
    //get quantity
    productLink += '&quantity='+quantity;
    if (redirectLink > 0) {
      // console.log(productLink);
      window.open(productLink);
    }
  }

  return(
    <>
      <div className="modal" id="buy-product">
        <div className="modal-dialog">
          <div className="modal-content">
            <button className="close-modal" onClick={closeModal}>
              <img src={closeIcon} alt=""/>
            </button>
            <div className="modal-content_inner">
              <div className="product-popup">
                <div className="product-info_top">
                  <div className="product-info_left">
                    <div className="product-image">
                      <img src={coffeeImage} alt=""/>
                    </div>
                    <div className="product-info">
                      <div className="title">{coffeeName}</div>
                      <div className="price"><strong>&#8362;{coffeePrice ? coffeePrice.toFixed(2) : '0.00'}</strong>({weight + " גר'"})</div>
                      <div className="props">
                        <div className="props-item">
                          <div className="props-title">{translations.product.props.grindingDegree.title}: <strong>{grindingDegree}</strong></div>
                          <div className="props-choices">
                            {
                              grinding.map((el, index) => {
                                // console.log(index);
                                // console.log(Object.keys(el)[0]);
                                let grindingName = Object.keys(el)[0];
                                return(
                                  <div className="props-choice" key={index}>
                                    <label className="props-choice_label">
                                      <input type="radio" name="grinding_degree" value={grindingName} onChange={changeGrindingDegree} checked={grindingDegree === grindingName} />
                                      <span className="name">{grindingName}</span>
                                    </label>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                        <div className="props-item">
                          <div className="props-title">{translations.product.props.weight.title}: <strong>{weight} גר' </strong></div>
                          <div className="props-choices">
                            {
                              Object.values(weightPrices).map((el, index) => {
                                return(
                                  <div className="props-choice" key={index}>
                                    <label className="props-choice_label">
                                      <input type="radio" name="weight" data-price={el} value={Object.keys(weightPrices)[index]} onChange={changeWeight} checked={weight === Object.keys(weightPrices)[index]} />
                                      <span className="name">{Object.keys(weightPrices)[index]} גר' </span>
                                    </label>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                      <div className="about">
                        <p>{description}</p>
                      </div>
                    </div>
                  </div>
                  <div className="product-info_right">
                    <div className="buy-block">
                      <div className="buy-info">
                        <div className="quantity">
                          <div className="custom-dropdown">
                            <div className="custom-dropdown_title">{translations.product.quantity}</div>
                            <div className="custom-dropdown_wrapper">
                              <button className="current-item" onClick={openCustomDropdown}>{quantity}</button>
                              <div className="dropdown-list">
                                {
                                  [1,2,3,4,5].map((el, index) => {
                                    return(
                                      <div key={index} className="dropdown-item">
                                        <button className={el === quantity ? "dropdown-item_btn selected" : "dropdown-item_btn"} onClick={changeQuantity}>{el}</button>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="delivery">
                          <div className="stock">{translations.product.inStock}</div>
                          <div className="delivery-info"><strong>{translations.product.delivery[0]}</strong> {translations.product.delivery[1]}</div>
                        </div>
                      </div>
                      <div className="buttons">
                        <button className={grindingDegree ? "thm-btn small" : "thm-btn small disabled"} onClick={buyProduct}>{translations.product.addToCartBtn}</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="product-info_bottom open">
                  <button className="product-bottom_title" onClick={openCoffeeInfo}>
                    {translations.product.bottomTitle}:
                    <span className="icon">
                        <img src={chevronDownIcon} alt=""/>
                      </span>
                  </button>
                  <div className="product-bottom_wrapper">
                    <div className="product-props">
                      {
                        Object.values(coffeeDetails).map((el, index) => {
                          return(
                            <div className="product-props_item" key={index}>
                              <strong>{Object.keys(coffeeDetails)[index]}</strong> {el}
                            </div>
                          )
                        })
                      }
                    </div>
                    <div className="product-tastes_wrapper">
                      <div className="product-tastes">
                        <div className="flavours">
                          {
                            coffeeNotes.map((el, index) => {
                              let tasteDefaultIcon = flavors1[0].icon;
                              var newArray = tastesIconGlossary.filter(function (item) {
                                let arrayLangLabel = item.he;
                                return arrayLangLabel === el.toLowerCase();
                              });
                              if (newArray.length > 0) {
                                tasteDefaultIcon = newArray[0].icon;
                              }
                              return (
                                <div className="label-flavor_item" key={index}>
                                  <div className="icon">
                                    <img src={tasteDefaultIcon} alt=""/>
                                  </div>
                                  <div className="title">{el}</div>
                                </div>
                              )
                            })
                          }
                        </div>
                        <div className="flavors-values">
                          <div className="flavor-value_item">
                            <div className="value">{coffeeAcidity}/5</div>
                            <div className="label">{translations.secondStep.preferences[0].title}</div>
                          </div>
                          <div className="flavor-value_item">
                            <div className="value">{coffeeSweetness}/5</div>
                            <div className="label">{translations.secondStep.preferences[1].title}</div>
                          </div>
                          <div className="flavor-value_item">
                            <div className="value">{coffeeBitterness}/5</div>
                            <div className="label">{translations.secondStep.preferences[2].title}</div>
                          </div>
                          <div className="flavor-value_item">
                            <div className="value">{coffeeBody}/5</div>
                            <div className="label">{translations.secondStep.preferences[3].title}</div>
                          </div>
                        </div>
                      </div>
                      <div className="roasting-level">
                        <div className="roasting-level_title">{translations.product.roastingLevelTitle}</div>
                        <RangeSlider
                          className="roasting_level"
                          min={1}
                          max={3}
                          step={1}
                          value={[0, roastingLevel]}
                          thumbsDisabled={[true, false]}
                          rangeSlideDisabled={true}
                          // onInput={changeRoastingLevel}
                          disabled={true}
                        />
                        <div className="roasting-level_label">
                          {roastingLevels[roastingLevel-1]} <span>(Agtron {roastingLevelAgtron})</span>
                        </div>
                      </div>
                    </div>
                    <div className="product-video">
                      <img src={videoPlaceholder} alt=""/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}