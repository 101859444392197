import React from "react";

export default function Preloader(){
  return (
    <>
      <div className="ajax-preloader">
        <div className="loader">
          <div className="loader__ball moveUp"></div>
          <div className="loader__ball moveUp delay-1"></div>
          <div className="loader__ball moveUp delay-2"></div>
          <div className="loader__ball moveUp delay-3"></div>
          <div className="loader__ball moveUp delay-4"></div>
        </div>
      </div>
    </>
  )
}