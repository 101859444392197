import {Route, Routes} from "react-router-dom";
// import './App.css';
import "./css/style.min.css";
import CoffeeBlender from "./CoffeeBlender/CoffeeBlender";
import EditorNew from "./Editor/EditorNew";
import React from "react";
import UserPreferences from "./UserPreferences";
import AfterAddToCartPage from "./afterAddToCartPage";
import CoffeeSticker from "./coffeeSticker";


function App() {
  return (
    <>
      <Routes>
        <Route path="/coffee-blender" element={<CoffeeBlender/>}/>
        <Route path="/" element={<UserPreferences/>}/>
        <Route path="/editor" element={<EditorNew/>}/>
        <Route path="/after-add-to-cart" element={<AfterAddToCartPage/>}/>
        <Route path="/after-add-to-cart/:blendID" element={<AfterAddToCartPage/>}/>
        <Route path="/:coffeeID" element={<CoffeeSticker/>}/>
      </Routes>
    </>
  );
}

export default App;
