import React from "react";

export const InputGroup = ({name, label, value, onChange}) => (
    <div>
        <label
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{label}</label>
        <input
            type="number"
            name={name}
            value={value}
            onChange={onChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        />
    </div>
);