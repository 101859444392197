import React from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip
);

export default function SpiderChartPrint(props) {
  let labelsArr = ['Bitterness', 'Acidity', 'Sweetness', 'Body'];
  if ('he' === props.lang) {
    labelsArr = ['מרירות', 'חומציות', 'מתיקות', 'גוּף'];
  }
  let propsValues = props.chartData;
  let values = [propsValues.bitterness, propsValues.acidity, propsValues.sweetness, propsValues.body];
  let options = {
    scales: {
      r: {
        min: 0, // MIN
        max: 5, // MAX
        beginAtZero: true,
        angleLines: {
          display: false,
          // color: 'red',
        },
        grid: {
          // circular: true,
          color: '#E7D0B1',
        },
        ticks: {
          backdropColor: 'transparent',
          color: '#E7D0B1',
          font: {
            size: 10
          },
          stepSize: 1, // the number of step
        },
        pointLabels: {
          color: '#fff',
          font: {
            size: 14,
            weight: 700
          }
        },
      }
    }
  }
  let datasets = [
    {
      label: '',
      data: values,
      // backgroundColor: 'rgba(255, 255, 255, 0.64)',
      backgroundColor: 'rgba(217, 217, 217, 0.55)',
      // borderColor: '#720D0F',
      borderColor: '#fff',
      pointBackgroundColor: '#fff',
      pointBorderColor: '#fff',
      pointRadius: 0,
      borderWidth: 1,
    }
  ];
  if (props.multiple){
    let dataMultiple = props.multiple.blendProfile;
    let dataMultipleArr = [dataMultiple.bitterness, dataMultiple.acidity, dataMultiple.sweetness, dataMultiple.body];
    // console.log(dataMultipleArr);
    options.scales.r.pointLabels.color = '#e7d0b1';
    datasets = [
      {
        label: '',
        data: values,
        backgroundColor: 'rgba(217, 217, 217, 0.55)',
        borderColor: '#fff',
        pointBackgroundColor: '#fff',
        pointBorderColor: '#fff',
        pointRadius: 0,
        borderWidth: 1,
      },
      {
        label: '',
        data: dataMultipleArr,
        backgroundColor: 'rgba(0, 149, 77, .55)',
        borderColor: '#00954D',
        pointBackgroundColor: '#00954D',
        pointBorderColor: '#00954D',
        pointRadius: 0,
        borderWidth: 1,
      }
    ]
  }
  let data = {
    labels: labelsArr,
    datasets: datasets,
  };
  return <Radar data={data} options={options} />;
}
