export const coffeeTypes = [
  {
    roastLevel: 1,
    ethiopia: {
      acidity: 3, sweetness: 5, bitterness: 1, body: 5,
      tasteNotes: {
        "fruity and citrus": ["פירות טרופיים", "מנגו"],
        "spices and roasted": ["תבלינים"],
        "sweet and floral": ["דבש", "תה"]
      }
    },
    brazil: {
      acidity: 1, sweetness: 3, bitterness: 3, body: 3,
      tasteNotes: {
        "chocolaty and nuts": ["שוקולד חלב", "בוטנים"],
        "sweet and floral": ["קרמל"],
        "spices and roasted": ["שקדים קלויים"]
      }
    },
    colombia: {
      acidity: 2, sweetness: 3, bitterness: 2, body: 1,
      tasteNotes: {
        "fruity and citrus": ["תפוז", "אשכולית"],
        "chocolaty and nuts": ["אגוזי לוז"],
        "spices and roasted": ["תבלינים", "קנה סוכר"]
      }
    },
    honduras: {
      acidity: 2, sweetness: 4, bitterness: 2, body: 3,
      tasteNotes: {
        "chocolaty and nuts": ["בוטנים"],
        "spices and roasted": ["עשבי תיבול"],
        "sweet and floral": ["היביסקוס", "דבש", "מייפל"]
      }
    },
    papua: {
      acidity: 2, sweetness: 4, bitterness: 3, body: 1,
      tasteNotes: {
        "fruity and citrus": ["תפוח", "אשכולית"],
        "spices and roasted": ["קינמון"],
        "sweet and floral": ["דבש", "יסמין"]
      }
    },
    robusta: {
      acidity: 1, sweetness: 1, bitterness: 4, body: 4,
      tasteNotes: {
        "spices and roasted": ["שקדים קלויים", "עשבי תיבול"]
      }
    }
  },
  {
    roastLevel: 2,
    ethiopia: {
      acidity: 4, sweetness: 5, bitterness: 3, body: 4,
      tasteNotes: {
        "chocolaty and nuts": ["שוקולד חלב", "קקאו"],
        "fruity and citrus": ["מנגו"],
        "spices and roasted": ["קינמון", "תבלינים"]
      }
    },
    brazil: {
      acidity: 2, sweetness: 2, bitterness: 3, body: 3,
      tasteNotes: {
        "chocolaty and nuts": ["אגוזי לוז", "בוטנים", "שוקולד חלב"],
        "sweet and floral": ["דבש"]
      }
    },
    colombia: {
      acidity: 3, sweetness: 3, bitterness: 3, body: 4,
      tasteNotes: {
        "fruity and citrus": ["אשכולית", "אגס אפוי"],
        "chocolaty and nuts": ["שוקולד חלב", "שוקולד מריר"],
        "sweet and floral": ["דבש", "סילאן", "קרמל", "סוכר חום"]
      }
    },
    honduras: {
      acidity: 3, sweetness: 3, bitterness: 2, body: 3,
      tasteNotes: {
        "chocolaty and nuts": ["אגוזי לוז"],
        "spices and roasted": ["עשבי תיבול"],
        "sweet and floral": ["סוכר חום", "היביסקוס"]
      }
    },
    papua: {
      acidity: 3, sweetness: 4, bitterness: 2, body: 3,
      tasteNotes: {
        "fruity and citrus": ["תפוח", "אשכולית"],
        "spices and roasted": ["קינמון"],
        "sweet and floral": ["דבש", "יסמין"]
      }
    },
    robusta: {
      acidity: 1, sweetness: 2, bitterness: 5, body: 5,
      tasteNotes: {
        "spices and roasted": ["צפורן", "קנה סוכר"]
      }
    }
  },
  {
    roastLevel: 3,
    ethiopia: {
      acidity: 1, sweetness: 2, bitterness: 4, body: 2,
      tasteNotes: {
        "spices and roasted": ["תבלינים", "צפורן"],
        "chocolaty and nuts": ["קקאו", "שוקולד מריר"],
        "sweet and floral": ["דבש"]
      }
    },
    brazil: {
      acidity: 2, sweetness: 3, bitterness: 4, body: 3,
      tasteNotes: {
        "chocolaty and nuts": ["שוקולד מריר"],
        "spices and roasted": ["שקדים קלויים", "תבלינים"]
      }
    },
    colombia: {
      acidity: 2, sweetness: 2, bitterness: 3, body: 3,
      tasteNotes: {
        "fruity and citrus": ["אגס אפוי"],
        "spices and roasted": ["תבלינים"],
        "chocolaty and nuts": ["שוקולד מריר"],
        "sweet and floral": ["סוכר חום"]
      }
    },
    honduras: {
      acidity: 1, sweetness: 3, bitterness: 5, body: 2,
      tasteNotes: {
        "chocolaty and nuts": ["קקאו", "אגוזי לוז"],
        "spices and roasted": ["עשבי תיבול"],
        "sweet and floral": ["סוכר חום"]
      }
    },
    papua: {
      acidity: 2, sweetness: 3, bitterness: 3, body: 5,
      tasteNotes: {
        "fruity and citrus": ["תפוח", "אשכולית"],
        "spices and roasted": ["קינמון"],
        "sweet and floral": ["דבש", "יסמין"]
      }
    },
    robusta: {
      acidity: 1, sweetness: 1, bitterness: 5, body: 5,
      tasteNotes: {
        "spices and roasted": ["צפורן"]
      }
    }
  }
]; 

  const EUROPRESS = 0;
  const ESPPRESSO = 1;
  const BLACKCOFFEE = 2;
  const MAKINETA = 3;
  const FILTER = 4;
  const FRENCH = 5;


export const calculateBlendProfile = (blend, coffeeTypes) => {
  let profile = {sweetness: 0, acidity: 0, bitterness: 0, body: 0};

  for (const coffee in blend) {
    let percentage = blend[coffee] / 100;
    profile.sweetness += coffeeTypes[coffee].sweetness * percentage;
    profile.acidity += coffeeTypes[coffee].acidity * percentage;
    profile.bitterness += coffeeTypes[coffee].bitterness * percentage;
    profile.body += coffeeTypes[coffee].body * percentage;
  }

  return profile;
};


export const calculateMatchPercentage = (blendProfile, targetProfile) => {
  let matchPercentages = [];

  for (const key in blendProfile) {
    let blendValue = blendProfile[key] * 20; // Convert to percentage
    let targetValue = targetProfile[key] * 20; // Convert to percentage

    let difference = Math.abs(blendValue - targetValue);
    let matchPercentage = 100 - difference;
    matchPercentages.push(matchPercentage);
  }

  let totalMatchPercentage = matchPercentages.reduce((a, b) => a + b, 0);
  return totalMatchPercentage / matchPercentages.length;
};

export const isBlendOver90Percent = (matchPercentage) => matchPercentage >= 90;

export const isBlend2To3Types = (blend) => {
  const typesCount = Object.values(blend).filter(value => value > 0).length;
  return typesCount >= 2 && typesCount <= 3;
};

export const isWithoutRobusta = (blend) => blend.robusta === 0;

export const isFitToClientDrinking = (roastingLevel, profile) => {
  switch (roastingLevel) {
    case 1:
      return profile.drinkingType === FILTER;
    case 2:
      return profile.drinkingType === ESPPRESSO || profile.drinkingType === FRENCH || profile.drinkingType === MAKINETA || profile.drinkingType === EUROPRESS;
    case 3:
      return profile.drinkingType === BLACKCOFFEE;
    default:

      return false;
  }
};

export const addingTasteNotesToBlend = (blend, coffeeType) => {
  let tasteNotesArray = [];

  for (let blendKey in blend) {
    if (blend[blendKey] > 0) tasteNotesArray.push(...Object.keys(coffeeType[blendKey].tasteNotes))
  }

  return [...new Set(tasteNotesArray)];
};

export const removeRobustaByCoffeeType = (allBlends, profile) => {
  // Use a single variable to hold the filtered results
  let filteredBlends = allBlends;

  if (profile.drinkingType === BLACKCOFFEE) {
    filteredBlends = allBlends.filter(blend => blend.blendArray.robusta <= 50);
  } else if (profile.drinkingType === ESPPRESSO) {
    filteredBlends = allBlends.filter(blend => blend.blendArray.robusta <= 30);
  } else if (profile.drinkingType === FILTER ||
    profile.drinkingType === FRENCH ||
    profile.drinkingType === MAKINETA ||
    profile.drinkingType === EUROPRESS) {
    filteredBlends = allBlends.filter(blend => blend.blendArray.robusta === 0);
  }

  // Return the filtered results
  return filteredBlends;
}


export const calculateBlendsByProfile = (profile,coffeeType,preset) => {
  let allBlends = [];
  for (let colombia = 0; colombia <= 100; colombia += 10) {
    for (let ethiopia = 0; ethiopia <= 100 - colombia; ethiopia += 10) {
      for (let brazil = 0; brazil <= 100 - colombia - ethiopia; brazil += 10) {
        for (let honduras = 0; honduras <= 100 - colombia - ethiopia - brazil; honduras += 10) {
          for (let papua = 0; papua <= 100 - colombia - ethiopia - brazil - honduras; papua += 10) {
            let robusta = 100 - colombia - ethiopia - brazil - honduras - papua;

            if (profile.drinkingType === BLACKCOFFEE && robusta > 50) continue;
            if (profile.drinkingType === ESPPRESSO && robusta > 30) continue;
            if (profile.drinkingType === FILTER && robusta > 0) continue;

            if ((profile.drinkingType === FRENCH ||
              profile.drinkingType === MAKINETA ||
              profile.drinkingType === EUROPRESS) &&
              robusta > 0) continue;


            if(preset &&
              preset.roastLevel === coffeeType.roastLevel &&
              preset.blendArray.colombia === colombia &&
              preset.blendArray.ethiopia === ethiopia &&
              preset.blendArray.brazil === brazil &&
              preset.blendArray.papua === papua &&
              preset.blendArray.honduras === honduras &&
              preset.blendArray.robusta === robusta
            ){
              continue;
            }


            let blend = {
              colombia,
              ethiopia,
              brazil,
              honduras,
              papua,
              robusta
            };

            // calculate the blend preferences
            let blendProfile = calculateBlendProfile(blend, coffeeType);

            //calculate the blend matching percentage
            let matchPercentage = calculateMatchPercentage(blendProfile, profile);

            // adding the blend all the taste notes of all his coffee types
            let tasteNotesArray = addingTasteNotesToBlend(blend, coffeeType)

            let currentRulesMet = [];
            let currentScore = 0;
            if (isBlendOver90Percent(matchPercentage)) {
              currentRulesMet.push("Matching percentages over 90%");
              currentScore += 7;
            }
            if (isBlend2To3Types(blend)) {
              currentRulesMet.push("Mix of 2-3 types of coffee");
              currentScore += 3;
            }
            if (isWithoutRobusta(blend)) {
              currentRulesMet.push("Without 'Robusta' type in the mix");
              currentScore += 2;
            }
            if (isFitToClientDrinking(coffeeType.roastLevel, profile)) {
              currentRulesMet.push("Fits To Client Drinking");
              currentScore += 1;
            }

            allBlends.push({
              roastLevel: coffeeType.roastLevel,
              blendArray: blend,
              tasteNotes: tasteNotesArray,
              matchPercentage,
              rulesMet: currentRulesMet,
              score: currentScore
            });
          }
        }
      }
    }
  }
  return allBlends;
};