import React, {useState, useEffect} from "react";
import closeIcon from "../../../images/assistant/close_icon.svg";
import productImage from "../../../images/product_popup_new.png";
// import videoPlaceholder from "../../../images/IMG_0170-min.JPG";
// import flavor1 from "../../../images/taste_notes/flavors/flavor_1.svg";
// import flavor2 from "../../../images/taste_notes/flavors/flavor_2.svg";
// import flavor3 from "../../../images/taste_notes/flavors/flavor_3.svg";
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import coffeeType1 from "../../../images/coffee_types/coffee_type_1.png";
import coffeeType2 from "../../../images/coffee_types/coffee_type_2.png";
import coffeeType3 from "../../../images/coffee_types/coffee_type_3.png";
import coffeeType4 from "../../../images/coffee_types/coffee_type_4.png";
import coffeeType5 from "../../../images/coffee_types/coffee_type_5.png";
import coffeeType6 from "../../../images/coffee_types/coffee_type_6.png";
import editIcon from "../../../images/edit_icon.svg";
import shareFacebookIcon from "../../../images/share_icons/share_facebook.png";
import shareWhatsappIcon from "../../../images/share_icons/share_whatsapp.png";
import shareTwitterIcon from "../../../images/share_icons/share_twitter.png";
import leftTopNoteBg from "../../../images/taste_notes/left_top.png";
import leftTopNoteBgActive from "../../../images/taste_notes/left_top_active.png";
import leftTopNoteIcon from "../../../images/taste_notes/left_top_icon.svg";
import leftTopNoteIconActive from "../../../images/taste_notes/left_top_icon_active.svg";
import rightTopNoteBg from "../../../images/taste_notes/right_top.png";
import rightTopNoteBgActive from "../../../images/taste_notes/right_top_active.png";
import rightTopNoteIcon from "../../../images/taste_notes/right_top_icon.svg";
import rightTopNoteIconActive from "../../../images/taste_notes/right_top_icon_active.svg";
import leftBottomNoteBg from "../../../images/taste_notes/left_bottom.png";
import leftBottomNoteBgActive from "../../../images/taste_notes/left_bottom_active.png";
import leftBottomNoteIcon from "../../../images/taste_notes/left_bottom_icon.svg";
import leftBottomNoteIconActive from "../../../images/taste_notes/left_bottom_icon_active.svg";
import rightBottomNoteBg from "../../../images/taste_notes/right_bottom.png";
import rightBottomNoteBgActive from "../../../images/taste_notes/right_bottom_active.png";
import rightBottomNoteIcon from "../../../images/taste_notes/right_bottom_icon.svg";
import rightBottomNoteIconActive from "../../../images/taste_notes/right_bottom_icon_active.svg";
import SpiderChart from "../../charts/radar";
//flavors
import almondIcon from '../../../images/taste_notes/flavors/flavor_1.svg';
import roastedAlmondIcon from '../../../images/taste_notes/flavors/agro-icon-brown-16.svg';
import sugarCaneIcon from '../../../images/taste_notes/flavors/flavor_2.svg';
import spicesIcon from '../../../images/taste_notes/flavors/flavor_4.svg';
// import cinnamonIcon from '../../../images/taste_notes/flavors/flavor_5.svg';
import peanutsIcon from '../../../images/taste_notes/flavors/flavor_6.svg';
import hazelnutIcon from '../../../images/taste_notes/flavors/flavor_7.svg';
// import cocoaIcon from '../../../images/taste_notes/flavors/flavor_8.svg';
import chocolateIcon from '../../../images/taste_notes/flavors/flavor_9.svg';
import herbsIcon from '../../../images/taste_notes/flavors/agro-icon-brown-7.svg';
import cloveIcon from '../../../images/taste_notes/flavors/agro-icon-brown-8.svg';
import vanillaIcon from '../../../images/taste_notes/flavors/agro-icon-brown-25.svg';
import brownSugarIcon from '../../../images/taste_notes/flavors/agro-icon-brown-14.svg';
import caramelIcon from '../../../images/taste_notes/flavors/agro-icon-brown-14.svg';
import hibiscusIcon from '../../../images/taste_notes/flavors/agro-icon-brown-17.svg';
import appleIcon from '../../../images/taste_notes/flavors/agro-icon-brown-24.svg';
import mangoIcon from '../../../images/taste_notes/flavors/agro-icon-brown-26.svg';
import orangeIcon from '../../../images/taste_notes/flavors/agro-icon-brown-10.svg';
import brownButterIcon from '../../../images/taste_notes/flavors/agro-icon-brown-21.svg';
import cinnamonIcon from "../../../images/taste_notes/flavors/flavor_5.svg";
import honeyIcon from "../../../images/taste_notes/flavors/agro-icon-brown-1.svg";
import mapleSyrupIcon from "../../../images/taste_notes/flavors/agro-icon-brown-3.svg";
import milkChocolateIcon from "../../../images/taste_notes/flavors/agro-icon-brown-4.svg";
import cocoaIcon from "../../../images/taste_notes/flavors/flavor_8.svg";
import whiteChocolateIcon from "../../../images/taste_notes/flavors/agro-icon-brown-19.svg";
import jasmineFlowerIcon from '../../../images/taste_notes/flavors/agro-icon-brown-17.svg';
import walnutIcon from '../../../images/taste_notes/flavors/walnut-icon.svg';
import lemonGrassIcon from '../../../images/taste_notes/flavors/lemon-grass-icon-new.svg';
import blueberryIcon from '../../../images/taste_notes/flavors/blueberry-icon.svg';
import grapefruitIcon from '../../../images/taste_notes/flavors/grapefruit-icon.svg';
import bakedPearIcon from '../../../images/taste_notes/flavors/agro-icon-brown-12.svg';
import citrusFruitsIcon from '../../../images/taste_notes/flavors/citrus-icon-new.svg';
import hotelIcon from '../../../images/taste_notes/flavors/hotel-icon.svg';
import halvahIcon from '../../../images/taste_notes/flavors/halvah-icon.svg';
import toffeeIcon from '../../../images/taste_notes/flavors/toffee-icon.svg';

import licoriceIcon from '../../../images/taste_notes/flavors/licorice-icon.png';
import biscuitIcon from '../../../images/taste_notes/flavors/biscuit-icon.png';
import dateHoneyIcon from '../../../images/taste_notes/flavors/date_honey_icon.svg';
import tropicalFruitsIcon from '../../../images/taste_notes/flavors/tropical-fruits-icon_new.png';

import agroIcon from "../../../images/flower.png";
import axios from "axios";
import {useNavigate} from "react-router-dom";
// import {coffeeTypes} from "../../../utils/functionsUtils";

export default function RoastMeModal(props){
  // console.log(props);

  let navigate = useNavigate();

  function closeModal(e){
    e.target.closest('.modal').classList.remove('open');
  }

  let coffeeTypesMy = [
    {icon: coffeeType1},
    {icon: coffeeType2},
    {icon: coffeeType3},
    {icon: coffeeType4},
    {icon: coffeeType5},
    {icon: coffeeType6}
  ];

  let translations = props.translations;
  let languageFile = translations;
  let currentSelections = props.currentSelections;
  let currentProfilePreferences = props.currentProfilePreferences;
  let currentTasteNotes = props.currentTasteNotes;
  let currentMatchPercentage = props.currentMatchPercentage;
  let language = props.language;
  let roastLevelRangeValue = props.roastLevelRangeValue;

  let grindingDegreeValues = translations.product.props.grindingDegree.values;
  let weightValues = translations.product.props.weight.values;


  // let flavors1 = [
  //   {icon: flavor1},
  //   {icon: flavor2},
  //   {icon: flavor3}
  // ];

  const [grindingDegree, setGrindingDegree] = useState(grindingDegreeValues[0]);
  const [weight, setWeight] = useState(weightValues[0]);
  const [weightHebrew, setWeightHebrew] = useState("500 גר'");
  const [price, setPrice] = useState(89);
  const [quantity, setQuantity] = useState(1);
  const [blendName, setBlendName] = useState('הקלידו שם לקפה');
  const [selectedRoastLevel, setSelectedRoatedLevel] = useState(currentSelections.roastLevel);
  const [roastingLevelAgtron, setRoastingLevelAgtron] = useState('#70-#80');
  const [productID, setProductID] = useState(27753);

  useEffect(() => {
    let roastingLevelAgtronDefault = '#70-#80';
    if (roastLevelRangeValue >= 1.51 && roastLevelRangeValue < 2.5) {
      roastingLevelAgtronDefault = '#60-#70';
    } else if (roastLevelRangeValue >= 2.5) {
      roastingLevelAgtronDefault = '#50-#60';
    }
    setRoastingLevelAgtron(roastingLevelAgtronDefault);
    setSelectedRoatedLevel(roastLevelRangeValue);
  }, [roastLevelRangeValue])


  let roatingLevels = translations.product.roastingLevels;
  let targetProfile = props.targetProfile;

  function changeGrindingDegree(){
    let choices = document.querySelectorAll('input[name=grinding_degree]');
    choices.forEach((el) => {
      if (el.checked) {
        setGrindingDegree(el.value);
      }
    })
  }
  function changeWeight(){
    let choices = document.querySelectorAll('input[name=weight]');
    choices.forEach((el) => {
      if (el.checked) {
        setWeight(el.value);
        let weightPrice = 89;
        let productIDValue = 27753;
        let weightStrHebrew = "500 גר'";
        if ('1kg' === el.value) {
          weightPrice = 169;
          productIDValue = 27754;
          weightStrHebrew = "1 קילו";
        }
        setWeightHebrew(weightStrHebrew);
        setPrice(weightPrice);
        setProductID(productIDValue);
      }
    })
  }

  function openCustomDropdown(e){
    e.preventDefault();
    e.target.closest('.custom-dropdown_wrapper').classList.toggle('open');
  }

  function changeQuantity(e){
    e.preventDefault();
    e.target.closest('.custom-dropdown_wrapper').classList.remove('open');
    setQuantity(parseInt(e.target.innerHTML));
  }

  let shareArr = [
    {icon: shareTwitterIcon, link: "#"},
    {icon: shareWhatsappIcon, link: "#"},
    {icon: shareFacebookIcon, link: "#"}
  ];

  let flavors0 = [
    {icon: roastedAlmondIcon},
    {icon: sugarCaneIcon},
    {icon: herbsIcon},
    {icon: cloveIcon},
    {icon: spicesIcon},
    {icon: cinnamonIcon},
    {icon: lemonGrassIcon},
    {icon: licoriceIcon},
  ];

  let flavors1 = [
    {icon: herbsIcon},
    {icon: vanillaIcon},
    {icon: brownSugarIcon},
    {icon: hibiscusIcon},
    {icon: caramelIcon},
    {icon: honeyIcon},
    {icon: jasmineFlowerIcon},
    {icon: dateHoneyIcon},
    {icon: mapleSyrupIcon},
    {icon: halvahIcon},
    {icon: toffeeIcon},
    {icon: biscuitIcon},
  ];

  let flavors2 = [
    {icon: appleIcon},
    {icon: mangoIcon},
    {icon: lemonGrassIcon},
    {icon: blueberryIcon},
    {icon: orangeIcon},
    {icon: grapefruitIcon},
    {icon: bakedPearIcon},
    {icon: tropicalFruitsIcon},
    {icon: citrusFruitsIcon},
    {icon: hotelIcon}
  ];

  let flavors3 = [
    {icon: brownButterIcon},
    {icon: peanutsIcon},
    {icon: hazelnutIcon},
    {icon: chocolateIcon},
    {icon: almondIcon},
    {icon: milkChocolateIcon},
    {icon: cocoaIcon},
    {icon: whiteChocolateIcon},
    {icon: walnutIcon},
  ];

  let coffeeBlends = languageFile.editor.coffeeBlends;

  // console.log(currentSelections);

  function editTitle(e){
    e.preventDefault();
    // let blendNameInput = document.querySelector('input[name="blend_name"]');
    // if (blendNameInput.disabled) {
    //   blendNameInput.disabled = false;
    //   blendNameInput.focus();
    // } else {
    //   blendNameInput.disabled = true;
    // }
  }

  function buyProduct(e){
    e.preventDefault();
    let productLink = 'https://agrocafe.co.il/custom_editor.php?product_id='+productID;
    //get quantity
    productLink += '&quantity='+quantity;

    //get blend array
    let blendArrKeys = Object.keys((currentSelections.blendArray));
    Object.values((currentSelections.blendArray)).forEach((el, index) => {
      let blendUrlValue = blendArrKeys[index];
      if ('colombia' === blendArrKeys[index]) {
        blendUrlValue = 'columbia';
      }
      let blendUrl = '&'+blendUrlValue+'='+el;
      productLink += blendUrl;
    });

    //get roasting level
    let roastingLevels = languageFile.product.roastingLevels;
    productLink += '&roasting_level='+roastingLevels[currentSelections.roastLevel - 1];

    //get coffee type
    let coffeeType = languageFile.firstStep.coffeeTypes[targetProfile.drinkingType];
    productLink += '&client_coffee_type='+coffeeType;

    //get client preferences
    let acidity = '&client_preferences[acidity]='+targetProfile.acidity;
    productLink += acidity;
    let sweetness = '&client_preferences[sweetness]='+targetProfile.sweetness;
    productLink += sweetness;
    let bitterness = '&client_preferences[bitterness]='+targetProfile.bitterness;
    productLink += bitterness;
    let body = '&client_preferences[body]='+targetProfile.body;
    productLink += body;

    //get client taste notes
    let tasteNotesArr = [];
    (targetProfile.tasteNotes).forEach((el) => {
      tasteNotesArr.push(languageFile.thirdStep.notes[el].title);
    })
    let tasteNotes = tasteNotesArr.join(',');
    let tasteNotesUrl = '&client_taste_notes='+tasteNotes;
    productLink += tasteNotesUrl;

    //get matching percentage
    let matchingPercent = currentMatchPercentage;
    productLink += '&matching_percentage='+matchingPercent;

    //get blend preferences
    let acidityBlend = '&blend_preferences[acidity]='+currentProfilePreferences.blendProfile.acidity;
    productLink += acidityBlend;
    let sweetnessBlend = '&blend_preferences[sweetness]='+currentProfilePreferences.blendProfile.sweetness;
    productLink += sweetnessBlend;
    let bitternessBlend = '&blend_preferences[bitterness]='+currentProfilePreferences.blendProfile.bitterness;
    productLink += bitternessBlend;
    let bodyBlend = '&blend_preferences[body]='+currentProfilePreferences.blendProfile.body;
    productLink += bodyBlend;

    //get blend notes
    let blendNotesArray = Object.keys(currentTasteNotes);
    Object.values(currentTasteNotes).forEach((el, index) => {
      let blendNotesArrayIndex = 'תבלינים וקלוי';
      if ('chocolaty and nuts' === blendNotesArray[index]) {
        blendNotesArrayIndex = 'שוקולד ואגוזים';
      } else if ('fruity and citrus' === blendNotesArray[index]) {
        blendNotesArrayIndex = 'פירות ופרי הדר';
      } else if ('sweet and floral' === blendNotesArray[index]) {
        blendNotesArrayIndex = 'מתוק ופרחי';
      }
      productLink += '&blend_taste_notes['+blendNotesArrayIndex+']='+el.join(',');
    });
    // if (productLink !== '') {
    //   // console.log(productLink);
    //   window.open(productLink);
    // }

    let blendID = Math.floor(Date.now() / 1000);
    // let blendID = 1721733150;
    productLink += '&sticker_id='+blendID;

    let productData = {
      "clientDetails": {
        "type": targetProfile.drinkingType,
        "tasteNotes": targetProfile.tasteNotes,
        "preferences": {
          "acidity": targetProfile.acidity,
          "sweetness": targetProfile.sweetness,
          "bitterness": targetProfile.bitterness,
          "body": targetProfile.body
        },
        "drinkerType": "Once a day"
      },
      "blendId": blendID,
      "blendName": blendName,
      "match": currentMatchPercentage,
      "blendTypes": {
        "colombia": currentSelections.blendArray.colombia,
        "ethiopia": currentSelections.blendArray.ethiopia,
        "brazil": currentSelections.blendArray.brazil,
        "honduras": currentSelections.blendArray.honduras,
        "papua": currentSelections.blendArray.papua,
        "robusta": currentSelections.blendArray.robusta
      },
      "blendPreferences": {
        "acidity": currentProfilePreferences.blendProfile.acidity,
        "sweetness": currentProfilePreferences.blendProfile.sweetness,
        "bitterness": currentProfilePreferences.blendProfile.bitterness,
        "body": currentProfilePreferences.blendProfile.body
      },
      "roastingLevel": currentSelections.roastLevel,
      "blendTasteNotes": currentTasteNotes
    };
    // console.log(productData);
    // console.log(productLink);
    let data = JSON.stringify(productData);
    let tokenValue = localStorage.getItem('nekot');
    let bearerValue = 'Bearer '+tokenValue;
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://agro-caffe.vercel.app/api/save-blend-data',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': bearerValue
      },
      data : data
    };

    axios.request(config)
      .then((response) => {
        let result = response.data;
        if (result.blendId) {
          // window.location.href = '/after-add-to-cart/'+blendID;
          navigate('/after-add-to-cart/'+blendID, {state: {productLink}});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // console.log(currentSelections.blendArray);

  let notesDefaultArr = ['fruity and citrus', 'spices and roasted', 'sweet and floral', 'chocolaty and nuts'];

  const handleTasteNoteChange = () => {
    return false;
  };

  return(
    <>
      <div className="modal" id="roast-me-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <button className="close-modal" onClick={closeModal}>
              <img src={closeIcon} alt=""/>
            </button>
            <div className="modal-content_inner">
              <div className="product-popup roast-me-popup">
                <div className="product-info_top">
                  <div className="product-info_left">
                    <div className="product-image">
                      <img src={productImage} alt=""/>
                    </div>
                    <div className="product-info">
                      <div className="product-info_wrapper">
                        <button className="edit-icon" onClick={editTitle}>
                          <img src={editIcon} alt=""/>
                          <span className="label">{languageFile.editor.editTitle}</span>
                        </button>
                        <div className="coffee-type_icon">
                          <div className="icon">
                            <img src={targetProfile.drinkingType !== "" ? coffeeTypesMy[targetProfile.drinkingType].icon : ''} alt=""/>
                          </div>
                          <div className="name">{targetProfile.drinkingType !== "" ? languageFile.firstStep.coffeeTypes[targetProfile.drinkingType] : ''}</div>
                        </div>
                        <div className="product-info_desc">
                          <input className="title" value={blendName} name="blend_name" onChange={(e) => setBlendName(e.target.value)}/>
                          <div className="price"><strong>&#8362;{price}</strong>({weightHebrew})</div>
                        </div>
                      </div>
                      <div className="props">
                        <div className="props-item">
                          <div className="props-title">{translations.product.props.grindingDegree.title}: <strong>{grindingDegree}</strong></div>
                          <div className="props-choices">
                            {
                              grindingDegreeValues.map((el, index) => {
                                return(
                                  <div className="props-choice" key={index}>
                                    <label className="props-choice_label">
                                      <input type="radio" name="grinding_degree" value={el} onChange={changeGrindingDegree} checked={grindingDegree === el} />
                                      <span className="name">{el}</span>
                                    </label>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                        <div className="props-item">
                          <div className="props-title">{translations.product.props.weight.title}: <strong>{weightHebrew}</strong></div>
                          <div className="props-choices">
                            {
                              weightValues.map((el, index) => {
                                let weightValueHebrew = "500 גר'";
                                if ('1kg' === el) {
                                  weightValueHebrew = "1 קילו";
                                }
                                return(
                                  <div className="props-choice" key={index}>
                                    <label className="props-choice_label">
                                      <input type="radio" name="weight" value={el} onChange={changeWeight} checked={weight === el} />
                                      <span className="name">{weightValueHebrew}</span>
                                    </label>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="product-info_right">
                    <div className="buy-block">
                      <div className="buy-info">
                        <div className="quantity">
                          <div className="custom-dropdown">
                            <div className="custom-dropdown_title">{translations.product.quantity}</div>
                            <div className="custom-dropdown_wrapper">
                              <button className="current-item" onClick={openCustomDropdown}>{quantity}</button>
                              <div className="dropdown-list">
                                {
                                  [1,2,3,4,5].map((el, index) => {
                                    return(
                                      <div key={index} className="dropdown-item">
                                        <button className={el === quantity ? "dropdown-item_btn selected" : "dropdown-item_btn"} onClick={changeQuantity}>{el}</button>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="delivery">
                          <div className="stock">{translations.product.inStock}</div>
                          <div className="delivery-info"><strong>{translations.product.delivery[0]}</strong> {translations.product.delivery[1]}</div>
                        </div>
                      </div>
                      <div className="buttons">
                        <button className="thm-btn small" onClick={buyProduct}>{translations.product.addToCartBtn}</button>
                      </div>
                    </div>
                    <div className="share-block">
                      <div className="share-title">{languageFile.editor.shareTitle}</div>
                      <div className="share-items">
                        {
                          shareArr.map((item, index) => {
                            return(
                              <div key={index} className="share-item">
                                <a href={item.link} className="share-item_link">
                                  <img src={item.icon} alt=""/>
                                </a>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="product-info_bottom">
                  <div className="product-bottom_wrapper">
                    <div className="product-bottom_item">
                      <div className="roasting-level">
                        <div className="product-item_title">
                          <span>{translations.product.roastingLevelTitle}</span>
                        </div>
                        <div className="roasting-level_wrapper">
                          <RangeSlider
                            className={"roasting_level level-"+selectedRoastLevel}
                            min={1}
                            max={3}
                            step={0.1}
                            value={[1, selectedRoastLevel]}
                            thumbsDisabled={[true, false]}
                            rangeSlideDisabled={true}
                            // disabled={true}
                          />
                          <div className="roasting-level_label">
                            {roatingLevels[(selectedRoastLevel)-1]} <span>(Agtron {roastingLevelAgtron})</span>
                          </div>
                        </div>
                      </div>
                      <div className="tastes">
                        <div className="product-item_title">
                          <span>{languageFile.sixthStep.tasteNoteTitle}</span>
                        </div>
                        <div className="notes-wrapper small active">
                          <div className="notes-wrapper_inner">
                            <div className="notes-items">
                              <div className="notes-item">
                                <label className="notes-item_label">
                                  <input type="checkbox" name="taste_notes[]" checked={currentTasteNotes ? (currentTasteNotes['spices and roasted'] ? true : false) : false} onChange={() => handleTasteNoteChange(2)} value="spices_roasted"/>
                                  <span className="label-value">
                                        <span className="label-bg">
                                          <img src={leftTopNoteBg} alt=""/>
                                          <img src={leftTopNoteBgActive} alt="" className="active"/>
                                        </span>
                                        <span className="label-icon">
                                          <img src={leftTopNoteIcon} alt=""/>
                                          <img src={leftTopNoteIconActive} alt="" className="active"/>
                                        </span>
                                        <span className="label-title">{languageFile.thirdStep.notes[0].title}</span>
                                        <span className="label-flavors op-0">
                                          {
                                            flavors0.map((el, index) => {
                                              return (
                                                <span className="label-flavor_item" key={index}>
                                                  <span className="icon">
                                                    <img src={el.icon} alt=""/>
                                                  </span>
                                                  <span className="title">{languageFile.thirdStep.notes[0].tastes[index]}</span>
                                                </span>
                                              )
                                            })
                                          }
                                        </span>
                                      </span>
                                </label>
                              </div>
                              <div className="notes-item">
                                <label className="notes-item_label">
                                  <input type="checkbox" name="taste_notes[]" checked={currentTasteNotes ? (currentTasteNotes['sweet and floral'] ? true : false) : false} onChange={() => handleTasteNoteChange(3)} value="sweet_floral"/>
                                  <span className="label-value top-right">
                                        <span className="label-bg">
                                          <img src={rightTopNoteBg} alt=""/>
                                          <img src={rightTopNoteBgActive} alt="" className="active"/>
                                        </span>
                                        <span className="label-icon">
                                          <img src={rightTopNoteIcon} alt=""/>
                                          <img src={rightTopNoteIconActive} alt="" className="active"/>
                                        </span>
                                        <span className="label-title">{languageFile.thirdStep.notes[1].title}</span>
                                        <span className="label-flavors op-0">
                                          {
                                            flavors1.map((el, index) => {
                                              return (
                                                <span className="label-flavor_item" key={index}>
                                                  <span className="icon">
                                                    <img src={el.icon} alt=""/>
                                                  </span>
                                                  <span className="title">{languageFile.thirdStep.notes[1].tastes[index]}</span>
                                                </span>
                                              )
                                            })
                                          }
                                        </span>
                                      </span>
                                </label>
                              </div>
                              <div className="notes-item">
                                <label className="notes-item_label">
                                  <input type="checkbox" name="taste_notes[]" checked={currentTasteNotes ? (currentTasteNotes['fruity and citrus'] ? true : false) : false} onChange={() => handleTasteNoteChange(0)} value="fruity_citrus"/>
                                  <span className="label-value bottom-left">
                                        <span className="label-bg">
                                          <img src={leftBottomNoteBg} alt=""/>
                                          <img src={leftBottomNoteBgActive} alt="" className="active"/>
                                        </span>
                                        <span className="label-icon">
                                          <img src={leftBottomNoteIcon} alt=""/>
                                          <img src={leftBottomNoteIconActive} alt="" className="active"/>
                                        </span>
                                        <span className="label-title">{languageFile.thirdStep.notes[2].title}</span>
                                        <span className="label-flavors op-0">
                                          {
                                            flavors2.map((el, index) => {
                                              return (
                                                <span className="label-flavor_item" key={index}>
                                                  <span className="icon">
                                                    <img src={el.icon} alt=""/>
                                                  </span>
                                                  <span className="title">{languageFile.thirdStep.notes[2].tastes[index]}</span>
                                                </span>
                                              )
                                            })
                                          }
                                        </span>
                                      </span>
                                </label>
                              </div>
                              <div className="notes-item">
                                <label className="notes-item_label">
                                  <input type="checkbox" name="taste_notes[]" checked={currentTasteNotes ? (currentTasteNotes['chocolaty and nuts'] ? true : false) : false} onChange={() => handleTasteNoteChange(1)} value="chocolaty_nuts"/>
                                  <span className="label-value bottom-right">
                                        <span className="label-bg">
                                          <img src={rightBottomNoteBg} alt=""/>
                                          <img src={rightBottomNoteBgActive} alt="" className="active"/>
                                        </span>
                                        <span className="label-icon">
                                          <img src={rightBottomNoteIcon} alt=""/>
                                          <img src={rightBottomNoteIconActive} alt="" className="active"/>
                                        </span>
                                        <span className="label-title chocolate">{languageFile.thirdStep.notes[3].title}</span>
                                        <span className="label-flavors op-0">
                                          {
                                            flavors3.map((el, index) => {
                                              return (
                                                <span className="label-flavor_item" key={index}>
                                                  <span className="icon">
                                                    <img src={el.icon} alt=""/>
                                                  </span>
                                                  <span className="title">{languageFile.thirdStep.notes[3].tastes[index]}</span>
                                                </span>
                                              )
                                            })
                                          }
                                        </span>
                                      </span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="float-flavors dynamic">
                            {
                              notesDefaultArr.map((item, index) => {
                                let itemIndex = 0;
                                let familyIcons = flavors0;
                                let labelClass = "label-flavors "+item.replaceAll(' ', '_');
                                if (!Object.keys(currentTasteNotes ? currentTasteNotes : {}).includes(item)) {
                                  labelClass += ' empty';
                                }
                                if ('sweet and floral' === item) {
                                  itemIndex = 1;
                                  familyIcons = flavors1;
                                } else if ('fruity and citrus' === item) {
                                  itemIndex = 2;
                                  familyIcons = flavors2;
                                } else if ('chocolaty and nuts' === item) {
                                  itemIndex = 3;
                                  familyIcons = flavors3;
                                }
                                let familyTitle = languageFile.thirdStep.notes[itemIndex].title;
                                let familyNotes = languageFile.thirdStep.notes[itemIndex].tastes;
                                if (Object.keys(currentTasteNotes ? currentTasteNotes : {}).includes(item)) {
                                  return(
                                    <div className={labelClass} key={index}>
                                      <div className="flavours-title">{familyTitle}</div>
                                      {
                                        currentTasteNotes[item].map((elI, indexI) => {
                                          let iconUrl = familyIcons[0];
                                          let iconIndexOf = familyNotes.indexOf(elI);
                                          if (iconIndexOf !== -1) {
                                            iconUrl = familyIcons[iconIndexOf];
                                          }
                                          return (
                                            <span className="label-flavor_item" key={indexI}>
                                                <span className="icon">
                                                  <img src={iconUrl.icon} alt=""/>
                                                </span>
                                                <span className="title">{elI}</span>
                                              </span>
                                          )
                                        })
                                      }
                                    </div>
                                  )
                                } else {
                                  return(
                                    <div className={labelClass} key={index}>
                                      <img src={agroIcon} alt=""/>
                                    </div>
                                  )
                                }
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="product-bottom_item">
                      <div className="coffee-mix">
                        <div className="product-item_title">
                          <span>{translations.editor.coffeeMixTitle}</span>
                        </div>
                        <div className="coffee-items">
                          {
                            Object.keys(currentSelections.blendArray).map((item, index) => {
                              let percentValue = currentSelections.blendArray[item];
                              if (percentValue !== 0) {
                                return(
                                  <div key={index} className="coffee-item">
                                    <div className="label">{coffeeBlends[item]}</div>
                                    <div className="value">{percentValue}%</div>
                                  </div>
                                )
                              } else {
                                return false;
                              }
                            })
                          }
                        </div>
                      </div>
                      <div className="blend-wrapper">
                        <div className="flavors">
                          <div className="product-item_title">
                            <span>{languageFile.sixthStep.flavorsTitle}</span>
                          </div>
                          <div className="flavors-wrapper">
                            <div className="flavors-values block">
                              <div className="flavor-value_item">
                                <div className="value">{targetProfile.acidity}/5</div>
                                <div className="label">{languageFile.secondStep.preferences[0].title}</div>
                              </div>
                              <div className="flavor-value_item">
                                <div className="value">{targetProfile.sweetness}/5</div>
                                <div className="label">{languageFile.secondStep.preferences[1].title}</div>
                              </div>
                              <div className="flavor-value_item">
                                <div className="value">{targetProfile.bitterness}/5</div>
                                <div className="label">{languageFile.secondStep.preferences[2].title}</div>
                              </div>
                              <div className="flavor-value_item">
                                <div className="value">{targetProfile.body}/5</div>
                                <div className="label">{languageFile.secondStep.preferences[3].title}</div>
                              </div>
                            </div>
                            <div className="flavors-chart">
                              <SpiderChart chartData={targetProfile} lang={language} multiple={props.currentProfilePreferences}/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}