import React from 'react';
import pageBG from '../images/page_bg-min.png';
import logo from '../images/logo_latest.png';
import copyIcon from '../images/copy_icon.svg';
import shareTwitterIcon from "../images/share_icons/share_twitter.png";
import shareWhatsappIcon from "../images/share_icons/share_whatsapp.png";
import shareFacebookIcon from "../images/share_icons/share_facebook.png";
import { useParams } from 'react-router-dom';
import {useLocation} from "react-router-dom";

const AfterAddToCartPage = () => {

  const { blendID } = useParams();
  let location = useLocation();
  // const [productLink, setProductLink] = useState('#');
  let shareLink = window.location.origin+"/"+blendID;
  let productLink = location.state ? location.state.productLink : '#';

  let shareFacebookLink = 'https://www.facebook.com/sharer/sharer.php?u='+shareLink+'&quote=תראה%20את%20הקפה%20שיצרתי:';
  let shareTwitterLink = 'https://twitter.com/intent/tweet?text=תראה%20את%20הקפה%20שיצרתי:%20'+shareLink;
  let shareWhatsappLink = 'https://wa.me/?text=תראה%20את%20הקפה%20שיצרתי:%20'+shareLink;
  let shareArr = [
    {icon: shareTwitterIcon, link: shareTwitterLink},
    {icon: shareWhatsappIcon, link: shareWhatsappLink},
    {icon: shareFacebookIcon, link: shareFacebookLink}
  ];


  //copy function
  function copyFunction() {
    var copyText = document.querySelector(".pib-value");
    navigator.clipboard.writeText(copyText.innerText);
    // document.querySelector(".copy-btn span")[0].innerText('copied!');
    document.querySelector(".copy-btn span").innerText = 'מוּעֲתָק!';
  }
  return(
    <>
      <div className="page-content lang-he add-to-cart-page" style={{backgroundImage: `url(${pageBG})`}}>
        <div className="page-header">
          <a className="logo" href="/">
            <img src={logo} alt=""/>
          </a>
        </div>
        <div className="steps-progress">
          <div className="steps-progress_body" style={{width: 0}}>Step progress</div>
        </div>
        <div className="steps-wrapper">
          <div className="step-item active">
            <div className="step-item_wrapper">
              <div className="step-title">רק רגע, לפני שאתם עוברים לתשלום<br></br>
              תרצו לשתף את הקפה שיצרתם?</div>
              <div className="product-id-block">
                <div className="pib-value">{window.location.origin+"/"+blendID}</div>
                <div className="pib-copy">
                  <button className="copy-btn" onClick={copyFunction}>
                    <img src={copyIcon} alt=""/>
                    <span>העתק קישור</span>
                  </button>
                </div>
              </div>
              <div className="share-block">
                <div className="share-title">שתף:</div>
                <div className="share-items">
                  {
                    shareArr.map((item, index) => {
                      return(
                        <div key={index} className="share-item">
                          <a href={item.link} className="share-item_link" target="_blank" rel="noreferrer">
                            <img src={item.icon} alt=""/>
                          </a>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div className="buttons">
                <a href={productLink} className="thm-btn" target="_blank" rel="noreferrer">המשך לתשלום</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AfterAddToCartPage;